const type = "sub_categories";

export const setSubCategoryList = payload => ({
  execute: state => {
    return {
      ...state,
      subCategoryList: payload
    };
  },
  type
});

export const removeSubCategory = payload => ({
  execute: state => {
    return {
      ...state,
      subCategoryList: state.subCategoryList.filter(({ id }) => id !== payload)
    };
  },
  type
});

export const unArchiveSubCategory = payload => ({
  execute: state => {
    const index = state.subCategoryList.findIndex(
      item => item.id === payload.id
    );
    const myState = [...state.subCategoryList];
    myState.splice(index, 1, payload);
    return {
      ...state,
      subCategoryList: [...myState]
    };
  },
  type
});
