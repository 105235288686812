import { Formik } from "formik";
import React from "react";
import { MappedNakedInput } from "../NakedInput";
import { makeStyles } from "@material-ui/core";
import {
  ArrowDownward as ArrowDownwardIcon,
  ArrowUpward as ArrowUpwardIcon
} from "@material-ui/icons";
import { useFetch } from "../../../hooks/fetch.hook";
import { updatePricing } from "../../../app/modules/DataExports/_api";

const useStyles = makeStyles({
  root: {
    fontSize: "2.8rem",
    margin: 0,
    color: "#6bc200",
    minWidth: "50px",
    maxWidth: "140px",
    height: "35px"
  },
  underline: {
    "&&&:before": {
      borderBottom: "none !important"
    },
    "&&:after": {
      borderBottom: "none !important"
    },
    "&&:focus": {
      borderBottom: "none !important"
    },
    "&&&:hover": {
      borderBottom: "none !important"
    }
  }
});

export const ExportPriceEdit = ({
  name,
  sortOrder,
  initialValues,
  pricingDetails,
  disabled
}) => {
  const { request } = useFetch();

  const sortDirection =
    sortOrder && sortOrder !== ""
      ? sortOrder === "de"
        ? "desc"
        : "asc"
      : undefined;

  const handleSubmit = values => {
    request(
      updatePricing,
      values,
      pricingDetails.submission_id,
      pricingDetails.pricing_id
    );
  };

  const getSortIcon = () => {
    return sortDirection ? (
      sortDirection === "desc" ? (
        <ArrowDownwardIcon
          style={{
            color: "#EB5757",
            fontSize: "1.4rem"
          }}
        />
      ) : (
        <ArrowUpwardIcon
          style={{
            color: "#6BC200",
            fontSize: "1.4rem"
          }}
        />
      )
    ) : (
      <></>
    );
  };

  return (
    <Formik initialValues={initialValues}>
      {({ values }) => (
        <div className="d-flex align-items-center justify-content-end">
          {getSortIcon()}
          {disabled ? (
            values[name] !== "" ? (
              `$${values[name]}`
            ) : (
              "-"
            )
          ) : (
            <MappedNakedInput
              id={name}
              name={name}
              handleSubmit={handleSubmit}
              disabled={disabled}
              values={values}
              startAdornment={values[name] === "" ? "" : "$"}
              useStyles={useStyles}
              customInputProps={{
                style: {
                  width: `${values[name].length ? values[name].length : 2}ch`
                }
              }}
            />
          )}
        </div>
      )}
    </Formik>
  );
};
