import React from "react";
import { ReactComponent as DashboardIcon } from "../../../../../ui/static/Dashboard.svg";
import { ReactComponent as TestsIcon } from "../../../../../ui/static/Tests.svg";
import { ReactComponent as SubmissionsIcon } from "../../../../../ui/static/Submission.svg";
import { ReactComponent as ProductsIcon } from "../../../../../ui/static/Products.svg";
import { ReactComponent as OrganisationsIcon } from "../../../../../ui/static/Organisations.svg";
import { ReactComponent as SettingsIcon } from "../../../../../ui/static/Settings.svg";
import { ReactComponent as UsersIcon } from "../../../../../ui/static/Users.svg";
import { ReactComponent as SubscriptionIcon } from "../../../../../ui/static/Subscription.svg";

export const ITEM_TYPES = {
  link: "link",
  heading: "heading",
  menu: "menu"
};

export const MENU = [
  {
    type: ITEM_TYPES.link,
    icon: <DashboardIcon />,
    title: "Dashboard",
    to: "/dashboard"
  },
  {
    type: ITEM_TYPES.link,
    title: "Data Export",
    icon: <TestsIcon />,
    to: "/exports"
  },
  {
    type: ITEM_TYPES.link,
    title: "Submissions",
    icon: <SubmissionsIcon />,
    to: "/submissions"
  },
  {
    type: ITEM_TYPES.link,
    title: "Crops",
    icon: <ProductsIcon />,
    to: "/crops/management"
  },
  {
    type: ITEM_TYPES.link,
    title: "Organisations",
    icon: <OrganisationsIcon />,
    to: "/organisations/organisations-list"
  },
  {
    type: ITEM_TYPES.link,
    icon: <UsersIcon />,
    title: "Contacts",
    to: "/contacts/contacts-list"
  },
  {
    type: ITEM_TYPES.link,
    icon: <SubscriptionIcon />,
    title: "Subscribers",
    to: "/subscriptions/subscriptions-list"
  },
  {
    type: ITEM_TYPES.menu,
    title: "Settings",
    icon: <SettingsIcon />,
    to: "/settings",
    items: [
      {
        type: "link",
        title: "Tags",
        to: "/settings/tags"
      },
      {
        type: "link",
        title: "Package Sizes",
        to: "/settings/package_sizes"
      },
      {
        type: "link",
        title: "Sub Categories",
        to: "/settings/sub_categories"
      }
    ]
  },
  {
    type: ITEM_TYPES.link,
    title: "Users",
    icon: <UsersIcon />,
    to: "/users-list"
  }
];
