import React from "react";
import { CheckCircle as CheckCircleIcon } from "@material-ui/icons";

export const Summary = () => {
  return (
    <section className="row justify-content-around align-items-start">
      <div className="my-5 p-5">
        <h1 className="stepper-title text-capitalize mb-5 text-success d-flex justify-content-start align-items-center">
          Your payment was Successful
          <CheckCircleIcon style={{ fontSize: "3rem" }} className="mx-3" />
        </h1>
        <h3 className="my-5">Thank you for choosing our service!</h3>
        <p className="stepper-description text-start">
          You are now subscribed to our Datafresh reporting service and will
          receive your first report based on your chosen plan details soon. We
          are excited to have you on board and look forward to helping you make
          the most of your business.
          <br />
          <br /> If you have any questions or concerns, please feel free to
          reach out to our support team.
        </p>
      </div>
    </section>
  );
};
