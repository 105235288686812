import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Tabs from "@bit/the-glue.frontendcomponents.tabs";
import { getUser } from "../_redux/selectors";
import { getUserById, getUserGroups } from "../_api";
import { useFetch } from "../../../../hooks/fetch.hook";
import { UserDetails } from "./UserDetails";
import { modifyUserData } from "../helpers";
import { UserPermissions } from "./UserPermissions";
import { getGroups } from "../../../../helpers/permissionsAPI";

export const Card = ({
  match: {
    params: { id }
  }
}) => {
  const getUserDetails = () => {
    if (!data) {
      request(getUserById, id).then(
        data => data && setCardData(modifyUserData(data))
      );
    } else {
      return data;
    }
  };

  const { request } = useFetch();
  const data = useSelector(getUser(id));
  const [cardData, setCardData] = useState(getUserDetails());
  const [userGroups, setUserGroups] = useState([]);
  const [groups, setGroups] = useState([]);
  const name = [cardData.first_name, cardData.last_name].join(" ");

  useEffect(() => {
    Promise.all([request(getUserGroups, id), request(getGroups)]).then(
      ([userGroups, groups]) => {
        userGroups && setUserGroups(userGroups);
        groups && setGroups(groups);
      }
    );
    // eslint-disable-next-line
  }, []);

  const tabs = [
    {
      label: "Profile",
      tabPanel: <UserDetails data={cardData} name={name} id={id} />
    },
    {
      label: "Permissions",
      tabPanel: (
        <UserPermissions
          name={name}
          id={id}
          userGroups={userGroups}
          groupsList={groups}
          setUserGroups={setUserGroups}
        />
      )
    }
  ];

  return (
    <div className="mt-10">
      <Tabs
        defaultActive="0"
        tabs={tabs}
        align="right"
        background="#6BC200"
        text="white"
      />
    </div>
  );
};
