import React, { forwardRef, useCallback } from "react";
import { useField } from "formik";
import { Input as MuiInput, Icon } from "@material-ui/core";
import { InputView } from "./Input.styles";

const Input = forwardRef(function(
  {
    size = "large",
    label,
    icon,
    iconPosition = "start",
    tip,
    multiline,
    inputClasses,
    rows,
    background,
    color = "#6BC200",
    variant,
    inputWrapperStyle,
    handleChange,
    setPackageFilter,
    ...other
  },
  ref
) {
  return (
    <>
      <InputView
        background={background}
        color={color}
        size={size}
        variant={variant}
        style={{ ...inputWrapperStyle }}
        onChange={event => {
          if (handleChange) {
            handleChange(event.target.value);
          }
          if (setPackageFilter) {
            setPackageFilter(event.target.value);
          }
        }}
      >
        {label && <div className="input-label">{label}</div>}
        <div className="input-group">
          {icon && (
            <div className={`icon--${iconPosition}`}>
              <Icon>{icon}</Icon>
            </div>
          )}
          <MuiInput
            disableUnderline
            inputRef={ref}
            multiline={multiline}
            rows={rows}
            className="input"
            data-testid="input"
            {...other}
          />
        </div>
      </InputView>
      {tip && <div>{tip}</div>}
    </>
  );
});

export function MappedInput(props) {
  const errorStyle = { color: "red", marginBottom: "10px" };

  function getTip(meta = {}, tip) {
    if (meta.touched && meta.error && typeof meta.error !== "object") {
      return <span style={errorStyle}>{meta.error}</span>;
    }
    if (tip) {
      return <span>{tip}</span>;
    }
  }

  const [field, meta] = useField(props.name);

  const getInputClasses = useCallback(() => {
    if (meta.touched && meta.error) {
      return "is-invalid";
    }

    if (meta.touched && !meta.error) {
      return "is-valid";
    }

    return "";
  }, [meta]);
  return (
    <Input
      {...field}
      {...props}
      inputClasses={getInputClasses()}
      tip={getTip(meta, props.tip)}
    />
  );
}

export default Input;
