import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import SearchBar from "@bit/the-glue.frontendcomponents.search-bar";
import { HEADINGS, searchMap } from "../constants";
import { renderTagsRow } from "../helpers";
import { ListButton } from "../../../../../ui/components/ListButton";
import { ListHeader } from "../../../../../ui/structures/ListHeader";
import { useFetch } from "../../../../../hooks/fetch.hook";
import { Modal } from "../../../../../ui/components/Modal";
import { ConfirmDelete } from "../../../../../ui/components/ConfirmDelete";
import { ArchiveCheckbox } from "../../../../../ui/components/ArchiveCheckbox";
import { getSelectedId, handleSearch } from "../../../../../ui/helpers";
import { UnarchiveButton } from "../../../../../ui/components/UnarchiveButton";
import { sortHeaderStyle } from "../../../../constants";
import { TableSortLabel } from "@material-ui/core";
import {
  removePackage,
  setPackageList,
  unArchivePackage
} from "../_redux/actions";
import {
  getPackageById,
  getPackages,
  modifyPackage,
  deletePackage
} from "../_api/index";

export const List = () => {
  const dispatch = useDispatch();
  const { request } = useFetch();

  const [loading, setLoading] = useState(false);
  const [focused, setFocused] = useState(false);
  const [initialData, setInitialData] = useState([]);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [archivedLoading, setArchivedLoading] = useState(false);
  const [archived, setArchived] = useState(false);
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState({});
  const [unArchiving, setUnarchiving] = useState(false);
  const [field, setField] = useState("");
  const [direction, setDirection] = useState("");

  const data = useSelector(({ packages: { packageList } }) => packageList);

  const fetchPackages = () => {
    setLoading(true);
    request(getPackages, search, archived, field, field && direction)
      .then(data => {
        if (!data) return;
        dispatch(setPackageList(data));
        setInitialData(data);
      })
      .finally(() => {
        setArchivedLoading(false);
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchPackages();
    // eslint-disable-next-line
  }, [search, archived, field, direction]);

  const handleModalClose = () => setConfirmDeleteOpen(false);

  const handleOpenModal = id => {
    setConfirmDeleteOpen(true);
    setSelectedItem(id);
  };

  const showArchived = () => {
    setArchivedLoading(true);
    setArchived(!archived);
  };

  const unArchiveDisabled = useMemo(
    () => Object.values(selected).filter(Boolean).length !== 1,
    [selected]
  );

  const handleUnarchive = () => {
    const PackageID = getSelectedId(selected);
    const status = (data.find(({ id }) => id === PackageID) || {}).status;
    if (status !== "archived") return;
    setUnarchiving(true);
    request(modifyPackage, { status: "active" }, PackageID)
      .then(data => {
        if (!data) return;
        dispatch(unArchivePackage(data));
        setSelected({});
      })
      .finally(() => setUnarchiving(false));
  };

  const handleArchive = id => {
    setLoading(true);
    setConfirmDeleteOpen(false);
    request(deletePackage, id)
      .then(data => {
        if (!data) return;
        if (archived) {
          request(getPackageById, id).then(data => {
            data && dispatch(unArchivePackage(data));
          });
        } else dispatch(removePackage(id));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const KEY_MAP = {
    "Package ID": data.map(item => item.code),
    "Package Name": data.map(item => item.name),
    "Package Quantity": data.map(item => item.quantity),
    "Package Measure": data.map(item => item.measure)
  };

  const resetSearch = () => {
    dispatch(setPackageList(initialData));
    setSearch("");
  };

  const renderButtons = () => (
    <>
      <div className="mr-10">
        <Link
          to={{
            pathname: `/add-package`
          }}
          data-testid="new"
        >
          <ListButton
            label="New"
            size="small"
            boxShadow={false}
            background="rgba(107, 194, 0, 0.3)"
            text="#6BC200"
          />
        </Link>
      </div>
      {archived && (
        <UnarchiveButton
          handleClick={handleUnarchive}
          disabled={unArchiveDisabled}
          loading={unArchiving}
        />
      )}
    </>
  );

  const handleFocus = () => setFocused(true);

  function renderHeaderWithSorting(headings) {
    return headings.map(([key, header]) => (
      <th
        key={key}
        style={sortHeaderStyle}
        className="px-5 text-nowrap text-center"
        onClick={() => {
          setDirection(
            key !== field ? "desc" : direction === "desc" ? "asc" : "desc"
          );
          setField(key);
        }}
      >
        <span style={{ cursor: "pointer" }}>{header}</span>
        {key === field && <TableSortLabel active direction={direction} />}
      </th>
    ));
  }

  return (
    <div className="row justify-content-center mt-10">
      <div className="col-12">
        {confirmDeleteOpen && (
          <Modal
            isOpen={confirmDeleteOpen}
            submitable
            onClose={handleModalClose}
            maxWidth="sm"
            modalContent={
              <ConfirmDelete
                handleClose={handleModalClose}
                handleSubmit={handleArchive}
                id={selectedItem}
                name={(data.find(({ id }) => id === selectedItem) || {}).name}
                isPackageDelete={true}
              />
            }
          />
        )}
        <div
          className="bg-white rounded py-7 px-10"
          style={{ border: "1px solid #E5E5E5", borderRadius: "4px" }}
        >
          <ListHeader
            title="Package Management"
            renderButtons={renderButtons}
            handleFocus={handleFocus}
          />
          <div>
            <SearchBar
              onSearch={data => handleSearch(data, searchMap, setSearch)}
              clearSearch={resetSearch}
              keyMap={KEY_MAP}
              placeholder="Filter Packages..."
              elevation={2}
              chipBackgroundColor="rgba(107, 194, 0, 0.3)"
              chipColor="#6BC200"
              focused={focused}
              setFocused={setFocused}
            />
          </div>
          <ArchiveCheckbox
            archivedLoading={archivedLoading}
            archived={archived}
            showArchived={showArchived}
          />
          <Datagrid
            data={data}
            headings={HEADINGS}
            renderRow={renderTagsRow}
            renderHeaderWithSorting={renderHeaderWithSorting}
            selectable
            selected={selected}
            setSelected={setSelected}
            editable
            deletable
            handleDelete={handleOpenModal}
            loading={loading}
            link="package_sizes"
            paginationOptions={[25, 50, 100]}
            paginationDefaultValue={50}
          />
        </div>
      </div>
    </div>
  );
};
