import React from "react";
import { Link } from "react-router-dom";
import { isLink, isStatus, getStatusRowStyle } from "../../../../ui/helpers";

export function renderSubCategoryRow(headings, item) {
  return headings.map(([key]) => {
    return (
      <td
        key={key}
        className={`text-center no-line border-0 px-5 py-5 ${isStatus(key) &&
          getStatusRowStyle(item)}`}
      >
        {isLink(key) ? (
          <Link className="text-dark" to={`/sub_categories/${item.id}`}>
            {item[key]}
          </Link>
        ) : (
          item[key]
        )}
      </td>
    );
  });
}
export const modifyCategoryList = data => {
  return data.map(category => {
    return { value: category.id, label: category.name };
  });
};

export const modifySubCategoryList = data => {
  return data.map(category => {
    return { value: category.id, label: category.name };
  });
};

export const modifyInitialData = data => {
  return data.map(subCategory => {
    return {
      id: subCategory.id,
      name: subCategory.name,
      parent_category: subCategory.categories.name,
      parent_id: subCategory.categories.id,
      status: subCategory.status
    };
  });
};
