import config from "../../../../config";

export const getSubmissionDetails = (url_id = "") =>
  fetch(
    `${config.ApiUrl.Rest}/public/submissions?${
      url_id ? "url=https://mktpric.es/" + url_id : ""
    }`,
    {
      method: "GET",
      mode: "cors",
      credentials: "include"
    }
  );

export const getCountryStates = country_id =>
  fetch(`${config.ApiUrl.Rest}/states?country_id=${country_id}`, {
    method: "GET",
    mode: "cors",
    credentials: "include"
  });

export const getStateRegions = state_id =>
  fetch(`${config.ApiUrl.Rest}/regions?state_id=${state_id}`, {
    method: "GET",
    mode: "cors",
    credentials: "include"
  });

export const getCountries = () =>
  fetch(`${config.ApiUrl.Rest}/countries`, {
    method: "GET",
    mode: "cors",
    credentials: "include"
  });

export const getRegions = () =>
  fetch(`${config.ApiUrl.Rest}/regions`, {
    method: "GET",
    mode: "cors",
    credentials: "include"
  });

export const changeSubmissionStatus = (submission_id, data) =>
  fetch(`${config.ApiUrl.Rest}/public/submissions/${submission_id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include"
  });

export const createSubmissionPrice = (submission_id, data) =>
  fetch(`${config.ApiUrl.Rest}/public/submissions/${submission_id}/prices`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include"
  });
