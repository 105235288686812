export const validatePlanStep = data =>
  data.fruit_report_days.length > 0 ||
  data.veg_report_days.length > 0 ||
  data.monthly_report
    ? true
    : false;

export const getPayload = payload => {
  return {
    name: getCustomerName(payload),
    phone: payload.phone,
    email: payload.email,
    company_abn: payload.company_abn,
    monthly_report: payload.monthly_report,
    report_format: payload.report_format,
    veg_report_days: payload.veg_report_days,
    fruit_report_days: payload.fruit_report_days,
    address: {
      city: payload.city,
      line1: payload.address,
      postal_code: payload.postcode,
      state: payload.state
    }
  };
};

const getCustomerName = payload => {
  if (payload.is_company) {
    return payload.company_name;
  } else {
    return payload.first_name + " " + payload.last_name;
  }
};
