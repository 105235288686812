/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Modal } from "../../../../ui/components/Modal";
import { MappedNakedInput } from "../../../../ui/components/NakedInput";
import { RegionEdit } from "./RegionEdit";
import { Formik } from "formik";
import { Selector } from "../../../../ui/components/ButtonSelector";
import { makeStyles, Tooltip } from "@material-ui/core";
import { priceSubmissionSchema } from "../../../../helpers/schemas";
import {
  checkPriceDifferences,
  checkValidation,
  deleteCrop,
  duplicateCrop
} from "../helpers";
import {
  carryOverOptions,
  demandOptions,
  placeHolderImageUrl,
  qualityOptions,
  supplyOptions
} from "../constants";
import {
  Error as ErrorIcon,
  Done as DoneIcon,
  PriorityHigh as PriorityHighIcon
} from "@material-ui/icons";
import config from "../../../../config";

const useStyles = makeStyles({
  root: {
    fontSize: "2.8rem",
    margin: 0,
    color: "#6bc200",
    minWidth: "50px",
    maxWidth: "140px",
    height: "35px"
  },
  underline: {
    "&&&:before": {
      borderBottom: "none !important"
    },
    "&&:after": {
      borderBottom: "none !important"
    },
    "&&:focus": {
      borderBottom: "none !important"
    },
    "&&&:hover": {
      borderBottom: "none !important"
    }
  }
});

export const DesktopCard = ({ cropData, setCrops, crops }) => {
  const [openModal, setOpenModal] = useState(false);
  const [isValid, setValid] = useState(cropData.isValidated);

  const initialValues = {
    low_price: cropData.prices.low || "",
    high_price: cropData.prices.high || "",
    best_price: cropData.prices.best || "",
    region_name: cropData.prices.region_name || "",
    region_id: cropData.prices.region_id || "",
    state_name: cropData.prices.state_name || "",
    state_id: cropData.prices.state_id || "",
    country: cropData.prices.country_id || "",
    supply: supplyOptions.indexOf(cropData.prices.supply),
    demand: demandOptions.indexOf(cropData.prices.demand),
    carryover: carryOverOptions.indexOf(cropData.prices.carryover),
    quality: qualityOptions.indexOf(cropData.prices.quality)
  };

  const handleModalOpen = () => setOpenModal(true);
  const handleModalClose = () => setOpenModal(false);

  const handleSubmit = values => {
    setCrops(
      crops.map(crop => {
        if (crop.generated_id === cropData.generated_id) {
          crop.prices.low = values.low_price;
          crop.prices.high = values.high_price;
          crop.prices.demand =
            demandOptions[values.demand] === undefined
              ? ""
              : demandOptions[values.demand];
          crop.prices.supply =
            supplyOptions[values.supply] === undefined
              ? ""
              : supplyOptions[values.supply];
          crop.prices.carryover =
            carryOverOptions[values.carryover] === undefined
              ? ""
              : carryOverOptions[values.carryover];
          crop.prices.quality =
            qualityOptions[values.quality] === undefined
              ? ""
              : qualityOptions[values.quality];
          crop.prices.crop_id = cropData.id;
          crop.prices.state_name = values.state_name;
          crop.prices.state_id = values.state_id;
          crop.prices.country_id = values.country;
          crop.prices.best = values.best_price;
          crop.prices.package_size = cropData.package_size.id;
          crop.prices.region_name = values.region_name;
          crop.prices.region_id = values.region_id;
          if (checkValidation(crop, "card")) {
            crop.isValidated = true;
            setValid(true);
          } else {
            crop.isValidated = false;
            setValid(false);
          }
        }
        return crop;
      })
    );
  };

  return (
    <div className={`d-none d-lg-block crop-card-wrapper`}>
      <Formik
        initialValues={initialValues}
        validationSchema={priceSubmissionSchema}
      >
        {({ values, errors, touched, setFieldTouched }) => (
          <>
            {openModal && (
              <Modal
                maxWidth="sm"
                isOpen={openModal}
                submitable
                onClose={handleModalClose}
                modalContent={
                  <RegionEdit
                    setOpenModal={setOpenModal}
                    crops={crops}
                    cropData={cropData}
                    setCrops={setCrops}
                    setValid={setValid}
                  />
                }
              />
            )}
            <div className="d-flex justify-content-between align-items-center py-3">
              <div
                className="px-5 py-3 mx-2 crop-card-image"
                style={{
                  backgroundImage: `url(${
                    cropData.image_url
                      ? config.ApiUrl.Rest + "/" + cropData.image_url
                      : placeHolderImageUrl
                  })`
                }}
              ></div>
              <section style={{ width: "130px", minWidth: "90px" }}>
                <p className="crop-card-main-title">
                  {cropData.name}
                  {isValid ? (
                    <DoneIcon style={{ color: "green", fontSize: "1.4rem" }} />
                  ) : (
                    <PriorityHighIcon
                      style={{ color: "red", fontSize: "1.4rem" }}
                    />
                  )}
                </p>
                <p className="crop-card-secondary-title">{cropData.variety}</p>
                <p className="crop-card-secondary-title">{cropData.grade}</p>
                <p className="crop-card-low-title">
                  {cropData.package_size.quantity +
                    " " +
                    cropData.package_size.measure}
                </p>
              </section>
              <section className="d-flex justify-content-between align-items-center mx-1">
                <span className="mr-2">{checkPriceDifferences(cropData)}</span>
                {errors.low_price && (
                  <Tooltip
                    title={
                      <p
                        style={{
                          fontSize: "10px",
                          margin: 0
                        }}
                      >
                        {errors.low_price}
                      </p>
                    }
                    placement="top"
                  >
                    <ErrorIcon
                      style={{
                        color: "red",
                        fontSize: "20px",
                        marginLeft: "5px"
                      }}
                    />
                  </Tooltip>
                )}
                <MappedNakedInput
                  id="low_price"
                  name="low_price"
                  handleSubmit={handleSubmit}
                  values={values}
                  startAdornment={"$"}
                  useStyles={useStyles}
                  customInputProps={{
                    style: {
                      width: `${
                        values.low_price.length ? values.low_price.length : 2
                      }ch`
                    }
                  }}
                />
                <div style={{ fontSize: "1.4rem" }} className="mx-3">
                  -
                </div>
                {errors.high_price && (
                  <Tooltip
                    title={
                      <p
                        style={{
                          fontSize: "10px",
                          margin: 0
                        }}
                      >
                        {errors.high_price}
                      </p>
                    }
                    placement="top"
                  >
                    <ErrorIcon
                      style={{
                        color: "red",
                        fontSize: "20px",
                        marginLeft: "5px"
                      }}
                    />
                  </Tooltip>
                )}
                <MappedNakedInput
                  id="high_price"
                  name="high_price"
                  handleSubmit={handleSubmit}
                  values={values}
                  startAdornment={"$"}
                  useStyles={useStyles}
                  customInputProps={{
                    style: {
                      width: `${
                        values.high_price.length ? values.high_price.length : 2
                      }ch`
                    }
                  }}
                />
              </section>
              <section className="mx-5 d-flex-column justify-content-center align-items-center">
                <Tooltip
                  title={
                    <p
                      style={{
                        fontSize: "11px",
                        margin: 0,
                        maxWidth: "200px"
                      }}
                    >
                      Clicking this button will remove the crop from the current
                      pricing report.
                    </p>
                  }
                  placement="top-start"
                  className="mb-2 px-5 py-3"
                >
                  <button
                    className="crop-na-btn crop-na-remove"
                    onClick={() => {
                      deleteCrop(cropData.generated_id, setCrops, crops);
                    }}
                  >
                    N/A
                  </button>
                </Tooltip>
                <Tooltip
                  title={
                    <p
                      style={{
                        fontSize: "11px",
                        margin: 0,
                        maxWidth: "200px"
                      }}
                    >
                      Clicking this button will create a duplicate of this crop.
                    </p>
                  }
                  placement="bottom-start"
                  className="px-5 py-3"
                >
                  <button
                    className="crop-na-btn crop-duplicate-btn"
                    onClick={() => {
                      duplicateCrop(cropData, setCrops);
                    }}
                  >
                    Copy
                  </button>
                </Tooltip>
              </section>
              <section className="d-flex justify-content-center align-items-center mx-5">
                <div>
                  <MappedNakedInput
                    id="best_price"
                    name="best_price"
                    handleSubmit={handleSubmit}
                    placeholder="Best Price"
                    customClasses={`crop-desktop-best-input`}
                    customInputProps={{ style: { textAlign: "center" } }}
                    useStyles={useStyles}
                    startAdornment={
                      values.best_price ? (
                        <span className="pl-2 text-center">$</span>
                      ) : (
                        ""
                      )
                    }
                  />
                  <button
                    className="crop-desktop-box d-flex justify-content-center align-items-center py-5"
                    onClick={() => {
                      setFieldTouched("state_name", true);
                      handleModalOpen();
                    }}
                    tabIndex="-1"
                  >
                    {values.state_name !== "" && values.region_name !== ""
                      ? values.state_name + " " + values.region_name
                      : "Select State"}
                  </button>
                </div>
                <div className="d-flex-column justify-content-center align-items-center">
                  {errors.best_price && touched.best_price && (
                    <Tooltip
                      title={
                        <p
                          style={{
                            fontSize: "10px",
                            margin: 0
                          }}
                        >
                          {errors.best_price}
                        </p>
                      }
                      placement="top"
                    >
                      <ErrorIcon
                        style={{
                          color: "red",
                          fontSize: "20px",
                          marginLeft: "5px"
                        }}
                      />
                    </Tooltip>
                  )}
                  {errors.state_name && (
                    <Tooltip
                      title={
                        <p
                          style={{
                            fontSize: "10px",
                            margin: 0
                          }}
                        >
                          Please select a state
                        </p>
                      }
                      placement="top"
                    >
                      <ErrorIcon
                        style={{
                          color: "red",
                          fontSize: "20px",
                          marginLeft: "5px"
                        }}
                      />
                    </Tooltip>
                  )}
                  {errors.region_name && (
                    <Tooltip
                      title={
                        <p
                          style={{
                            fontSize: "10px",
                            margin: 0
                          }}
                        >
                          Please select a region
                        </p>
                      }
                      placement="top"
                    >
                      <ErrorIcon
                        style={{
                          color: "red",
                          fontSize: "20px",
                          marginLeft: "5px"
                        }}
                      />
                    </Tooltip>
                  )}
                </div>
              </section>
              <div className="mx-5 w-100">
                <Selector
                  labels={supplyOptions}
                  value={values.supply}
                  errors={errors.supply}
                  title="Supply"
                  name="supply"
                  handleSubmit={handleSubmit}
                  classes="selector-item"
                />
              </div>
              <div className="mx-5 w-100">
                <Selector
                  labels={demandOptions}
                  value={values.demand}
                  name="demand"
                  title="Demand"
                  errors={errors.demand}
                  handleSubmit={handleSubmit}
                  classes="selector-item"
                />
              </div>
              <div className="mx-5 w-100">
                <Selector
                  labels={carryOverOptions}
                  title="Carryover"
                  errors={errors.carryover}
                  value={values.carryover}
                  name="carryover"
                  handleSubmit={handleSubmit}
                  classes="selector-item"
                />
              </div>
              <div className="mx-5 w-100">
                <Selector
                  labels={qualityOptions}
                  title="Quality"
                  errors={errors.quality}
                  value={values.quality}
                  name="quality"
                  handleSubmit={handleSubmit}
                  classes="selector-item"
                />
              </div>
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};
