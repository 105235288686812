import React from "react";
import { checkPriceDifferences, getRegionData } from "../helpers";
import {
  Done as DoneIcon,
  PriorityHigh as PriorityHighIcon
} from "@material-ui/icons";
import { placeHolderImageUrl } from "../constants";
import config from "../../../../config";

export const Card = ({ cropData, setCurrentCrop, setEdit }) => {
  return (
    <span
      className="d-block d-lg-none mobile-crop-card w-100"
      onClick={() => {
        setCurrentCrop(cropData);
        setEdit(true);
      }}
    >
      <div
        className={`d-flex justify-content-center align-items-center p-2 crop-card-wrapper`}
      >
        <div
          className="mr-2 crop-card-image"
          style={{
            backgroundImage: `url(${
              cropData.image_url
                ? config.ApiUrl.Rest + "/" + cropData.image_url
                : placeHolderImageUrl
            })`
          }}
        ></div>
        <div className="container">
          <div className="row justify-content-between align-items-start mb-3">
            <div className="d-flex-column justify-content-start align-items-center">
              <div className="crop-card-main-title">
                {cropData.name}{" "}
                {cropData.isValidated ? (
                  <DoneIcon style={{ color: "green", fontSize: "1.3rem" }} />
                ) : (
                  <PriorityHighIcon
                    style={{ color: "red", fontSize: "1.3rem" }}
                  />
                )}
              </div>
              <div className="crop-card-secondary-title">
                {cropData.variety}
              </div>
              <div className="crop-card-secondary-title">{cropData.grade}</div>
              <div className="crop-card-low-title">
                {cropData.package_size.quantity +
                  " " +
                  cropData.package_size.measure}
              </div>
            </div>
            <section className="d-flex mb-2">
              <div className="crop-card-pricing d-flex-column">
                <div className="d-flex justify-content-end align-items-center">
                  <span className="mr-2">
                    {checkPriceDifferences(cropData)}
                  </span>
                  <div className="text-right">
                    {cropData.prices.low && cropData.prices.high
                      ? `$${cropData.prices.low} - $${cropData.prices.high}`
                      : "No Pricing"}
                  </div>
                </div>
                <div className="text-right">
                  {cropData.prices.best === 0 ? (
                    <></>
                  ) : (
                    <>
                      <span className="crop-card-best">
                        Best: ${cropData.prices.best}
                      </span>
                    </>
                  )}
                </div>
                <div className="text-right">{getRegionData(cropData)}</div>
              </div>
            </section>
          </div>
          <section className="row justify-content-between align-items-center">
            <div className="d-flex-column justify-content-center align-items-center text-center">
              <div className="crop-card-pricing-title">
                <b>Supply</b>
              </div>
              <div className="crop-card-pricing-text">
                {cropData.prices.supply}
              </div>
            </div>
            <div className="d-flex-column justify-content-center align-items-center text-center">
              <div className="crop-card-pricing-title">
                <b>Demand</b>
              </div>
              <div className="crop-card-pricing-text">
                {cropData.prices.demand}
              </div>
            </div>
            <div className="d-flex-column justify-content-center align-items-center text-center">
              <div className="crop-card-pricing-title">
                <b>Carryover</b>
              </div>
              <div className="crop-card-pricing-text">
                {cropData.prices.carryover}
              </div>
            </div>
            <div className="d-flex-column justify-content-center align-items-center text-center">
              <div className="crop-card-pricing-title">
                <b>Quality</b>
              </div>
              <div className="crop-card-pricing-text">
                {cropData.prices.quality}
              </div>
            </div>
          </section>
        </div>
      </div>
    </span>
  );
};
