import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import { ListButton } from "../../../../ui/components/ListButton";
import { Auth } from "aws-amplify";
import { MappedInput } from "../../../../ui/components/Input/index";
import { UserPasswordSchema } from "../../../../helpers/schemas";
import {
  Check as CheckIcon,
  CheckCircle as CheckCircleIcon
} from "@material-ui/icons/";
import { error } from "../../../../helpers/toasts";
import { Modal } from "../../../../ui/components/Modal";
import { ResetPassword } from "./ResetPassword";
import { ConfirmPassword } from "./ConfirmPassword";
import { info } from "../../../../helpers/toasts";
import { CircularProgress } from "@material-ui/core/";
import { accountRegex } from "../constants";

export const PasswordInfo = () => {
  const [openModal, setOpenModal] = useState(false);
  const [passwordValidity, setPasswordValidity] = useState({
    oneUpper: false,
    oneLower: false,
    oneSpecial: false,
    minChars: false,
    oneNum: false
  });
  const [step, setStep] = useState("reset");

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleChange = password => {
    setPasswordValidity({
      oneUpper: accountRegex.oneUpperCase.test(password) ? true : false,
      oneLower: accountRegex.oneLowerCase.test(password) ? true : false,
      minChars: password.length >= 8 ? true : false,
      oneNum: accountRegex.isNumberRegx.test(password) ? true : false,
      oneSpecial: accountRegex.oneSpecialRegx.test(password) ? true : false
    });
  };

  const username = useSelector(
    ({
      auth: {
        user: { username }
      }
    }) => username
  );

  const initialValues = {
    old_password: "",
    new_password: "",
    confirm_password: ""
  };

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        return Auth.changePassword(
          user,
          values.old_password,
          values.new_password
        );
      })
      .then(data => {
        if (data) {
          info("User information has been updated!");
          resetForm();
        }
      })
      .catch(err => error(err.message))
      .finally(() => {
        setSubmitting(false);
        setPasswordValidity({
          oneUpper: false,
          oneLower: false,
          minChars: false,
          oneNum: false,
          oneSpecial: false
        });
      });
  };

  const steps = {
    reset: (
      <ResetPassword
        handleClose={handleCloseModal}
        setStep={setStep}
        username={username}
      />
    ),
    confirm: (
      <ConfirmPassword
        handleClose={handleCloseModal}
        setStep={setStep}
        username={username}
      />
    )
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={UserPasswordSchema}
    >
      {({ handleSubmit, isSubmitting, values }) => (
        <>
          <Modal
            isOpen={openModal}
            modalContent={steps[step]}
            submitable
            maxWidth="sm"
          />
          <div
            className="bg-white p-8 rounded"
            style={{ borderTop: "1px solid #E5E5E5" }}
          >
            <div className="row">
              <div className="col col-lg-12">
                <form>
                  <h3 className="mb-10">
                    <strong>Password</strong>
                  </h3>
                  <div className="row mb-5">
                    <div className="col-6">
                      <span
                        style={{
                          fontSize: "1.1rem",
                          padding: "16px",
                          display: "block"
                        }}
                      >
                        New Password must contains:
                      </span>
                      <div className="pl-12">
                        {passwordValidity.minChars ? (
                          <CheckCircleIcon style={{ color: "#6BC200" }} />
                        ) : (
                          <CheckIcon style={{ color: "rgba(0,0,0,.2)" }} />
                        )}{" "}
                        8 Characters
                      </div>
                      <div className="pl-12">
                        {passwordValidity.oneLower ? (
                          <CheckCircleIcon style={{ color: "#6BC200" }} />
                        ) : (
                          <CheckIcon style={{ color: "rgba(0,0,0,.2)" }} />
                        )}{" "}
                        1 Lowercase Character
                      </div>
                      <div className="pl-12">
                        {passwordValidity.oneUpper ? (
                          <CheckCircleIcon style={{ color: "#6BC200" }} />
                        ) : (
                          <CheckIcon style={{ color: "rgba(0,0,0,.2)" }} />
                        )}{" "}
                        1 Uppercase Character
                      </div>
                      <div className="pl-12">
                        {passwordValidity.oneNum ? (
                          <CheckCircleIcon style={{ color: "#6BC200" }} />
                        ) : (
                          <CheckIcon style={{ color: "rgba(0,0,0,.2)" }} />
                        )}{" "}
                        1 Number
                      </div>
                      <div className="pl-12">
                        {passwordValidity.oneSpecial ? (
                          <CheckCircleIcon style={{ color: "#6BC200" }} />
                        ) : (
                          <CheckIcon style={{ color: "rgba(0,0,0,.2)" }} />
                        )}{" "}
                        1 Special Character for example !@#$%^&*_
                      </div>
                      <div className="pl-12">
                        {values.new_password !== values.confirm_password ||
                        (values.new_password === "" &&
                          values.confirm_password === "") ? (
                          <CheckIcon style={{ color: "rgba(0,0,0,.2)" }} />
                        ) : (
                          <CheckCircleIcon style={{ color: "#6BC200" }} />
                        )}{" "}
                        New Passwords Match
                      </div>
                    </div>
                    <div className="col-6">
                      <div>
                        <MappedInput
                          name="old_password"
                          label="Previous Password"
                          type="password"
                          variant="filled"
                        />
                      </div>
                      <div style={{ margin: "24px 0 24px 0" }}>
                        <MappedInput
                          name="new_password"
                          label="New Password"
                          type="password"
                          variant="filled"
                          handleChange={handleChange}
                        />
                      </div>
                      <div>
                        <MappedInput
                          name="confirm_password"
                          label="Confirm New Password"
                          type="password"
                          variant="filled"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between align-items-center mt-5">
                    <div
                      className="text-uppercase text-muted cursor-pointer user-page-text"
                      onClick={handleOpenModal}
                    >
                      Restore Password
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      {isSubmitting && (
                        <div className="mx-2">
                          <CircularProgress color="primary" size={20} />
                        </div>
                      )}
                      <ListButton
                        label={isSubmitting ? "Updating..." : "Submit"}
                        size="large"
                        onClick={handleSubmit}
                        disabled={isSubmitting}
                        data-testid="cancel"
                        boxShadow={false}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};
