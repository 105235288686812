import React from "react";
import {
  ArrowDownward as ArrowDownwardIcon,
  ArrowUpward as ArrowUpwardIcon
} from "@material-ui/icons";

export const generateCropsData = data => {
  return data.map(crop => {
    if ("region" in crop.latest_price) {
      return {
        ...crop,
        prices: {
          ...crop.latest_price,
          region_name: crop.latest_price.region.name,
          region_id: crop.latest_price.region.id,
          state_id: crop.latest_price.region.states.id,
          state_name: crop.latest_price.region.states.name,
          country_id: crop.latest_price.region.states.country_id,
          country_name: crop.latest_price.region.states.countries.name
        },
        generated_id: generateUUID(),
        isValidated: checkValidation(crop.latest_price, "default")
      };
    } else {
      return {
        ...crop,
        prices: {
          ...crop.latest_price,
          region_name: "",
          region_id: "",
          state_id: "",
          state_name: "",
          country_id: "",
          country_name: ""
        },
        generated_id: generateUUID(),
        isValidated: false
      };
    }
  });
};

export const generateSubmissionPayload = crops =>
  crops.map(crop => {
    if (crop.prices.best === "") {
      return {
        region_id: crop.prices.region_id,
        low: crop.prices.low,
        high: crop.prices.high,
        demand: crop.prices.demand,
        supply: crop.prices.supply,
        carryover: crop.prices.carryover,
        quality: crop.prices.quality,
        crop_id: crop.prices.crop_id,
        package_size: crop.prices.package_size
      };
    } else {
      return {
        region_id: crop.prices.region_id,
        low: crop.prices.low,
        high: crop.prices.high,
        demand: crop.prices.demand,
        supply: crop.prices.supply,
        carryover: crop.prices.carryover,
        quality: crop.prices.quality,
        crop_id: crop.prices.crop_id,
        best: crop.prices.best,
        package_size: crop.prices.package_size
      };
    }
  });

export const duplicateCrop = (cropData, setCrops) => {
  setCrops(prev => [
    ...prev,
    {
      ...cropData,
      generated_id: generateUUID(),
      prices: {
        low: 0,
        region_id: "",
        submission_id: cropData.latest_price.submission_id,
        status: "active",
        quality: "",
        demand: "",
        package_size: cropData.latest_price.package_size,
        high: 0,
        code: cropData.code,
        crop_id: cropData.id,
        carryover: "",
        supply: "",
        best: null,
        region_name: "",
        state_id: "",
        state_name: "",
        country_id: ""
      }
    }
  ]);
};

export const generateUUID = () => {
  // Public Domain/MIT
  var d = new Date().getTime(); //Timestamp
  var d2 =
    (typeof performance !== "undefined" &&
      performance.now &&
      performance.now() * 1000) ||
    0; //Time in microseconds since page-load or 0 if unsupported
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    var r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
};

export const deleteCrop = (cropId, setCrops, crops) => {
  setCrops(crops.filter(crop => crop.generated_id !== cropId));
};

export const checkPriceDifferences = cropData => {
  if (
    cropData.prices.low > cropData.latest_price.low ||
    cropData.prices.high > cropData.latest_price.high
  ) {
    return <ArrowUpwardIcon style={{ color: "#6BC200", fontSize: "1.9rem" }} />;
  } else if (
    cropData.prices.low < cropData.latest_price.low ||
    cropData.prices.high < cropData.latest_price.high
  ) {
    return (
      <ArrowDownwardIcon style={{ color: "#EB5757", fontSize: "1.9rem" }} />
    );
  } else if (
    cropData.prices.low === cropData.latest_price.low ||
    cropData.prices.high === cropData.latest_price.high
  ) {
    return;
  }
};

export const checkValidation = (crop, type) => {
  let isValidated = false;
  if (type === "default") {
    if (crop.best !== null) {
      if (crop.best < crop.high) {
        return (isValidated = false);
      }
    }
    const deepClone = JSON.parse(JSON.stringify(crop));
    let values = Object.values(deepClone);
    for (let index = 0; index < values.length; index++) {
      if (values[index] === "" || values[index] === 0) {
        return isValidated;
      }
    }

    return (isValidated = true);
  } else if (type === "card") {
    if (crop.prices.best !== "") {
      if (parseInt(crop.prices.best) < parseInt(crop.prices.high)) {
        return (isValidated = false);
      }
    }
    if (parseFloat(crop.prices.low) > parseFloat(crop.prices.high)) {
      return (isValidated = false);
    }
    const deepClone = JSON.parse(JSON.stringify(crop));
    delete deepClone.prices.best;
    delete deepClone.prices.region;

    let values = Object.values(deepClone.prices);
    for (let index = 0; index < values.length; index++) {
      if (
        values[index] === "" ||
        values[index] === undefined ||
        values[index] === "-1"
      ) {
        return isValidated;
      }
    }
    return (isValidated = true);
  }
};

export const checkPriceCompletition = crops => {
  for (let index = 0; index < crops.length; index++) {
    if (crops[index].isValidated === false) {
      return false;
    }
  }
  return true;
};

export const getRegionData = cropData => {
  if (cropData.prices.region_name === "" && cropData.prices.state_name === "") {
    return "No region";
  } else if (
    cropData.prices.region_name === "No Region" &&
    cropData.prices.state_name === "No State"
  ) {
    return cropData.prices.country_name;
  } else {
    return cropData.prices.state_name + " " + cropData.prices.region_name;
  }
};

export const getCountry = (id, countries) => {
  return countries.find(country => country.value === id).label;
};

export const validateRegionSelect = (state, region) => {
  let isValid = false;
  if (state === "" || region === "") {
    return isValid;
  }
  return (isValid = true);
};
