import config from "../../../../config";

export const getAllSubmissions = (search = "", field, direction) =>
  fetch(
    `${config.ApiUrl.Rest}/submissions?${search && "&" + search}${(field &&
      "&order_by=" + field) ||
      ""}${(direction && "&order_dir=" + direction) || ""}`,
    {
      mode: "cors",
      credentials: "include"
    }
  );

export const getSubmissionById = submission_id =>
  fetch(`${config.ApiUrl.Rest}/submissions/${submission_id}`, {
    mode: "cors",
    credentials: "include"
  });

export const getMessageRecipient = id =>
  fetch(`${config.ApiUrl.Rest}/contacts/${id}`, {
    mode: "cors",
    credentials: "include"
  });

export const getSubmissionMessages = submission_id =>
  fetch(`${config.ApiUrl.Rest}/submissions/${submission_id}/messages`, {
    mode: "cors",
    credentials: "include"
  });

export const modifySubmission = (data, submission_id) =>
  fetch(`${config.ApiUrl.Rest}/submissions/${submission_id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include"
  });

export const deleteSubmission = submission_id =>
  fetch(`${config.ApiUrl.Rest}/submissions/${submission_id}`, {
    method: "DELETE",
    mode: "cors",
    credentials: "include"
  });

export const sendReminders = data =>
  fetch(`${config.ApiUrl.Rest}/reminder/send`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include"
  });
