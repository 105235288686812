import React from "react";
import { Formik } from "formik";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { Auth } from "aws-amplify";
import { error } from "../../../../helpers/toasts";
import { confirmPasswordSchema } from "../../../../helpers/schemas";
import { info } from "../../../../helpers/toasts";
import { ListButton } from "../../../../ui/components/ListButton";

const initialValues = {
  code: "",
  new_password: ""
};

export const ConfirmPassword = ({ handleClose, username, setStep }) => {
  const handleSubmit = (values, { setSubmitting }) => {
    Auth.forgotPasswordSubmit(username, values.code, values.new_password)
      .then(data => {
        setStep("reset");
        handleClose();
        info("Password has been reset!");
      })
      .catch(err => error(err.message))
      .finally(() => setSubmitting(false));
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={confirmPasswordSchema}
    >
      {({ handleSubmit, isSubmitting }) => (
        <div className="px-5 py-10">
          <h3 className="mb-5">Reset password</h3>
          <div>
            <MappedInput
              name="code"
              label="Code"
              color="#6BC200"
              variant="filled"
            />
          </div>

          <div>
            <MappedInput
              name="new_password"
              label="New Password"
              color="#6BC200"
              variant="filled"
            />
          </div>
          <div className="mt-10">
            <ListButton
              label="Cancel"
              size="small"
              onClick={handleClose}
              boxShadow={false}
              className="mx-2"
            />
            <ListButton
              label="Confirm"
              size="small"
              onClick={handleSubmit}
              boxShadow={false}
              disabled={isSubmitting}
            />
          </div>
        </div>
      )}
    </Formik>
  );
};
