const type = "subscribers";

export const setSubscribersList = payload => ({
  execute: state => {
    return {
      ...state,
      subscribersList: payload
    };
  },
  type
});

export const removeSubscriber = payload => ({
  execute: state => {
    return {
      ...state,
      subscriberList: state.subscribersList.filter(({ id }) => id !== payload)
    };
  },
  type
});

export const updateSubscribersList = payload => ({
  execute: state => {
    const index = state.subscribersList.findIndex(
      item => item.id === payload.id
    );
    const myState = [...state.subscribersList];
    myState.splice(index, 1, payload);
    return {
      ...state,
      subscribersList: [...myState]
    };
  },
  type
});
