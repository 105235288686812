export const HEADINGS = [
  ["code", "Crop ID"],
  ["name", "Name"],
  ["variety", "Variety"],
  ["grade", "Grade"],
  ["status", "Status"],
  ["edit", "Edit"]
];

export const searchMap = {
  "Crop ID": "code",
  Name: "name",
  Variety: "variety",
  Grade: "grade"
};
