import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./pages/DashboardPage";
import { OrganisationListPage } from "./pages/OrganisationListPage";
import { OrganisationCardPage } from "./pages/OrganisationCardPage";
import { OrganisationDetails } from "./modules/Organisations/components/OrganisationDetails";
import { UserSettingsPage } from "./pages/UserSettingsPage";
import { UsersListPage } from "./pages/UsersListPage";
import { UserCardPage } from "./pages/UserCardPage";
import { UserDetails } from "./modules/Users/components/UserDetails";
import { TagsListPage } from "./pages/TagsListPage";
import { TagCardPage } from "./pages/TagCardPage";
import { PackageListPage } from "./pages/PackageListPage";
import { PackageCardPage } from "./pages/PackageCardPage";
import { CropsListPage } from "./pages/CropsListPage";
import { CropCardPage } from "./pages/CropCardPage";
import { ContactsListPage } from "./pages/ContactsListPage";
import { ContactsCardPage } from "./pages/ContactsCardPage";
import { SubscribersCardPage } from "./pages/SubscribersCardPage";
import { SubscribersListPage } from "./pages/SubscribersListPage";
import { BackupPage } from "./pages/BackupPage";
import GuestPage from "./pages/GuestPage";
import { IsGuest } from "../helpers/IsEndUser";
import { SubmissionsPage } from "./pages/SubmissionsPage";
import { ExportsPage } from "./pages/ExportsPage";
import { SubCategoryCardPage } from "./pages/SubCategoryCardPage";
import { SubCategoryListPage } from "./pages/SubCategoryListPage";

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect
            exact
            from="/"
            to={`${IsGuest() ? "/guest" : "/dashboard"}`}
          />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />

        <ContentRoute path="/user/edit" component={UserSettingsPage} />

        <ContentRoute path="/submissions" component={SubmissionsPage} />

        <ContentRoute path="/exports" component={ExportsPage} />

        <ContentRoute
          path="/organisations/organisations-list"
          component={OrganisationListPage}
        />
        <Route
          path="/organisations-list/:id"
          component={OrganisationCardPage}
        />
        <ContentRoute
          path="/add-organisation"
          component={OrganisationDetails}
        />

        <ContentRoute
          path="/contacts/contacts-list"
          component={ContactsListPage}
        />
        <Route path="/contacts/:id" component={ContactsCardPage} />
        <ContentRoute path="/add-contact" component={ContactsCardPage} />

        <ContentRoute
          path="/subscriptions/subscriptions-list"
          component={SubscribersListPage}
        />
        <Route path="/subscriptions/:id" component={SubscribersCardPage} />

        <ContentRoute path="/users-list" component={UsersListPage} />
        <Route path="/users/:id" component={UserCardPage} />
        <ContentRoute path="/add-user" component={UserDetails} />

        <ContentRoute path="/settings/tags" component={TagsListPage} />
        <Route path="/tags/:id" component={TagCardPage} />
        <ContentRoute path="/add-tag" component={TagCardPage} />

        <ContentRoute
          path="/settings/sub_categories"
          component={SubCategoryListPage}
        />
        <Route path="/sub_categories/:id" component={SubCategoryCardPage} />
        <ContentRoute
          path="/add-sub_category"
          component={SubCategoryCardPage}
        />

        <ContentRoute
          path="/settings/package_sizes"
          component={PackageListPage}
        />
        <Route path="/package_sizes/:id" component={PackageCardPage} />
        <ContentRoute path="/add-package" component={PackageCardPage} />

        <ContentRoute path="/crops/management" component={CropsListPage} />
        <ContentRoute path="/crops/:id" component={CropCardPage} />
        <ContentRoute path="/add-crop" component={CropCardPage} />

        <ContentRoute path="/error-page" component={BackupPage} />
        <ContentRoute path="/guest" component={GuestPage} />

        <Redirect to="/error-page" />
      </Switch>
    </Suspense>
  );
}
