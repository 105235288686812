import React from "react";
import { Headers } from "../../../ui/components/Headers";
import { Logo } from "../../../ui/helpers/constants";

export const ErrorPagePricing = () => {
  return (
    <>
      <div className="d-flex-column justify-content-center align-items-center">
        <Headers />
        <div className="d-flex-column justify-content-center align-items-center">
          <div className="d-flex justify-content-center align-items-center my-20">
            <div
              className="error-submitted"
              style={{
                backgroundImage: Logo,
                height: "60px",
                width: "310px",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "50% 50%"
              }}
            ></div>
          </div>
          <p className="text-center my-20 price-request-error mx-5">
            The price request link you visited is no longer active.
          </p>
        </div>
      </div>
    </>
  );
};
