import { Skeleton } from "@material-ui/lab";
import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import * as auth from "../../app/modules/Auth/_redux/authRedux";
import { useFetch } from "../../hooks/fetch.hook";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { getUser } from "../modules/Auth/_api";

const GuestPage = (props) => {
  const [loading, setLoading] = useState(false);
  const user = useSelector(({ auth: { user } }) => user) || {};

  const { request } = useFetch();
  const history = useHistory();

  const handleURLChange = (isEndUser) => {
    history.push(isEndUser ? "/tests-list" : "/dashboard");
  };

  useEffect(() => {
    setLoading(true);
    request(getUser)
      .then((userData) => {
        if (userData) {
          const { id, roles, policies, permissions } = userData;

          const isEndUser = get(user, "permissions.is_end_user");

          if (!permissions.is_national_admin && !isEndUser) return;

          const userDetails = {
            ...user,
            id: id,
            roles: roles,
            permissions,
            policies,
            sessionStart: new Date(),
          };

          props.fulfillUser(userDetails);
          handleURLChange(isEndUser);
        }
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <Skeleton variant="rect" width={"100%"} height={520} />;
  }

  return (
    <div className="bg-white rounded px-10 py-40 d-flex flex-column align-items-center justify-content-center">
      <h4 className="mb-3">{`Hello ${user.fullname},`}</h4>
      <h4>
        Your user account permissions are currently being reviewed and
        established by the Datafresh National Administration Team. <br />
        If you are still seeing this message after 2 business days please
        contact the Datafresh National Office via email{" "}
        <span className="text-primary">email@email.com.au</span> or phone{" "}
        <span className="text-primary">(07) 3915 4240</span>.
      </h4>
      <div className="containter h-100 mt-10">
        <div className="p-5 bg-white">
          <span>
            <img
              className="img-fluid"
              src={toAbsoluteUrl("/assets/icons/Logo.svg")}
              alt="Logo"
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export default connect(null, auth.actions)(GuestPage);
