import React from "react";
import { Formik } from "formik";
import { SignUpPlanDetailsSchema } from "../../../helpers/schemas";
import { MappedSelect } from "@bit/the-glue.frontendcomponents.select";
import { dayOfWeeks, reportFormats } from "./constants";
import { MappedCheckbox } from "../Checkbox";
import {
  generateMobileReportsOptions,
  generateReportSettings,
  generateReportsRows,
  getReportSettings
} from "./helpers";

export const ReportSettings = ({
  payload,
  setPayload,
  wrapperClasses = "col-12 col-lg-6"
}) => {
  const initialValues = {
    monthly_report: payload.monthly_report,
    report_format: payload.report_format,
    monday_fruit: getReportSettings(payload.fruit_report_days, "mon"),
    monday_veg: getReportSettings(payload.veg_report_days, "mon"),
    tuesday_fruit: getReportSettings(payload.fruit_report_days, "tue"),
    tuesday_veg: getReportSettings(payload.veg_report_days, "tue"),
    wednesday_fruit: getReportSettings(payload.fruit_report_days, "wed"),
    wednesday_veg: getReportSettings(payload.veg_report_days, "wed"),
    thursday_fruit: getReportSettings(payload.fruit_report_days, "thu"),
    thursday_veg: getReportSettings(payload.veg_report_days, "thu"),
    friday_fruit: getReportSettings(payload.fruit_report_days, "fri"),
    friday_veg: getReportSettings(payload.veg_report_days, "fri")
  };

  const handleSubmit = values => {
    setPayload(prev => ({
      ...prev,
      monthly_report: values.monthly_report,
      report_format: values.report_format,
      fruit_report_days: generateReportSettings(values, "fruit"),
      veg_report_days: generateReportSettings(values, "veg")
    }));
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={SignUpPlanDetailsSchema}
    >
      {({ handleSubmit, values }) => (
        <>
          <div className={`${wrapperClasses}`}>
            <div className="">
              <div className="row">
                <div className="col-12 my-2 p-0">
                  <MappedSelect
                    label="Report Format"
                    name="report_format"
                    background="#FBFCFD"
                    variant="filled"
                    options={reportFormats}
                    onBlur={handleSubmit}
                  />
                </div>
              </div>
              <div className="row">
                <table className="d-none d-sm-table table border">
                  <thead>
                    <tr>
                      <th className="p-0 stepper-table-row-header"></th>
                      {dayOfWeeks.map(day => (
                        <th
                          key={day}
                          className="py-2 px-0 text-center border stepper-table-header"
                        >
                          {day}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {generateReportsRows(values).map((row, index) => (
                      <tr>
                        <td
                          key={row.name}
                          className="py-2 pl-3 text-center border stepper-table-row-header"
                        >
                          {row.name}
                        </td>
                        {row.elements.map(element => (
                          <td
                            key={element.name}
                            className="p-0 text-center border"
                          >
                            <MappedCheckbox
                              name={element.name}
                              checked={element.value}
                              onClick={() => handleSubmit()}
                            />
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <table className="d-table d-sm-none table border">
                  <thead>
                    <tr>
                      <th className="stepper-table-row-header"></th>
                      {["Fruit", "Vegetable"].map(title => (
                        <th
                          key={title}
                          className="py-2 px-0 text-center border stepper-table-header"
                        >
                          {title}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {generateMobileReportsOptions(values).map(option => (
                      <tr key={option.day}>
                        <td className="py-2 px-0 text-center stepper-table-header">
                          {option.day}
                        </td>
                        <td className="p-0 text-center border">
                          <MappedCheckbox
                            name={option.fruit.name}
                            checked={option.fruit.value}
                            onClick={() => handleSubmit()}
                          />
                        </td>
                        <td className="p-0 text-center border">
                          <MappedCheckbox
                            name={option.veg.name}
                            checked={option.veg.value}
                            onClick={() => handleSubmit()}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="row">
                <div className="col-12 my-2 d-flex align-items-center p-0">
                  <MappedCheckbox
                    name="monthly_report"
                    checked={values.monthly_report}
                    onClick={() => handleSubmit()}
                  />
                  <p className="m-0">I would like to receive monthly reports</p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};
