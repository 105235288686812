import React from "react";
import { makeStyles } from "@material-ui/core";
import { TextField } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  }
}));

export default function DatePicker(props) {
  const classes = useStyles();

  return (
    <form className={classes.container} noValidate>
      <TextField
        id="date"
        label="Select a Date"
        type="date"
        onChange={event => {
          props.setDate(event.target.value);
        }}
        className={classes.textField}
        defaultValue={props.defaultValue}
        InputLabelProps={{
          shrink: true
        }}
        data-test="exports-datepicker"
      />
    </form>
  );
}
