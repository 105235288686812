import { countryToAlpha3 } from "country-to-iso";

export const generateDailyReports = submissions => {
  const result = [];
  submissions.forEach(crop => {
    result.push({
      submission_id: crop.submission_id,
      id: crop.price.id,
      name: crop.name,
      grade: crop.grade,
      variety: crop.variety,
      contact: crop.contact.first_name + " " + crop.contact.last_name,
      measure: crop.price.measure,
      quantity: crop.price.quantity,
      package_size:
        crop.package_size.quantity + " " + crop.package_size.measure,
      low: crop.price.low,
      high: crop.price.high,
      best:
        crop.price.best !== null &&
        crop.price.best !== "" &&
        crop.price.best !== 0
          ? crop.price.best
          : "",
      carryover: crop.price.carryover,
      supply: crop.price.supply,
      demand: crop.price.demand,
      quality: crop.price.quality,
      location:
        crop.price.region.name +
        " " +
        crop.price.region.states.name +
        `\n ${countryToAlpha3(crop.price.region.states.countries.name)}`,
      price_changes: crop.price_changes
    });
  });
  return result;
};
