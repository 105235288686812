export const HEADINGS = [
  ["code", "Package ID"],
  ["name", "Package Name"],
  ["quantity", "Quantity"],
  ["measure", "Measure"],
  ["status", "Status"],
];

export const searchMap = {
  "Package ID": "code",
  "Package Name": "name",
  "Package Quantity": "quantity",
  "Package Measure": "measure",
};
