/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { Loader } from "../../../../../ui/components/Loader";
import { ListButton } from "../../../../../ui/components/ListButton";
import { MappedInput } from "../../../../../ui/components/Input/index";
import { useFetch } from "../../../../../hooks/fetch.hook";
import { TagsField } from "../../../../../ui/structures/TagsForm/TagsField";
import { Modal } from "../../../../../ui/components/Modal";
import { TagsAutocomplete } from "../../../../../ui/structures/TagsForm/TagsAutocomplete";
import Skeleton from "@material-ui/lab/Skeleton";
import { error, info, warning } from "../../../../../helpers/toasts";
import { sortPackageSizes } from "../helpers";
import {
  getCropById,
  getPackageSizes,
  modifyCrop,
  savePackageSizes
} from "../_api";

export const CropPackages = ({ id }) => {
  const { request } = useFetch();
  const history = useHistory();

  const [cardData, setCardData] = useState({});
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [packages, setPackages] = useState([]);
  const [cropLoading, setCropLoading] = useState(false);

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  useEffect(() => {
    setCropLoading(true);
    Promise.all([request(getCropById, id), request(getPackageSizes)])
      .then(([crop, package_sizes]) => {
        if (!crop) return;
        setCardData(crop);
        if (id) {
          setPackages(
            package_sizes
              .filter(size => size.name === crop.name)
              .sort(sortPackageSizes)
          );
        } else {
          setPackages(package_sizes.sort(sortPackageSizes));
        }
      })
      .finally(() => setCropLoading(false));
  }, []);

  const initialValues = {
    name: cardData.name || "",
    package_sizes: id ? (cardData.package_sizes || []).map(({ id }) => id) : []
  };

  const handleSubmit = values => {
    const payload = values.package_sizes.map(size => ({
      id: size
    }));
    const cropPayload = {
      status: cardData.contacts.length > 0 ? "active" : "unassigned"
    };
    if (payload.length === 0) {
      warning("You must have at least 1 package size assigned!");
    } else {
      setLoading(true);
      Promise.all([
        request(savePackageSizes, cardData.id, payload),
        request(modifyCrop, cropPayload, cardData.id)
      ]).then(([packageResponse, cropResponse]) => {
        if (!packageResponse || !cropResponse) {
          return error("Oops, something has gone wrong!");
        }
        setLoading(false);
        info("Package sizes have been updated!");
        history.goBack();
      });
    }
  };

  const handleClose = () => history.goBack();

  if ((id && !Object.keys(cardData).length) || cropLoading) {
    return <Skeleton variant="rect" width={"100%"} height={387.88} />;
  }

  return (
    <>
      {loading && <Loader isOpen={loading} maxWidth="xs" title="Loading..." />}
      <div
        className="bg-white p-10"
        style={{ border: "1px solid #E5E5E5", borderRadius: "4px" }}
      >
        <Formik onSubmit={handleSubmit} initialValues={initialValues}>
          {({ handleSubmit, values, setFieldValue }) => (
            <>
              {modalOpen && (
                <Modal
                  maxWidth="sm"
                  isOpen={modalOpen}
                  submitable
                  onClose={handleModalClose}
                  modalContent={
                    <TagsAutocomplete
                      name="package_sizes"
                      placeholder="Package Sizes"
                      setValue={setFieldValue}
                      options={packages.filter(
                        item => !values.package_sizes.includes(item.id)
                      )}
                      loading={!packages.length}
                      onClose={handleModalClose}
                      currentValue={values.package_sizes}
                      multiple
                    />
                  }
                />
              )}
              <h3 className="mb-10">
                <strong>{id ? cardData.name : "Add Crop"}</strong>
              </h3>
              <div className="d-flex-column justify-content-start align-items-center">
                <div className="mb-5">
                  <MappedInput
                    label="Crop Name"
                    name="name"
                    color="#6BC200"
                    disabled
                    data-test="crop-name"
                  />
                </div>
                <div className="">
                  <TagsField
                    label="Package Sizes"
                    name="package_sizes"
                    tags={values.package_sizes}
                    predefinedTags={packages}
                    handleAdd={handleModalOpen}
                    setValue={setFieldValue}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-end align-items-center mt-10 text-right">
                <ListButton
                  label="Cancel"
                  size="large"
                  onClick={handleClose}
                  boxShadow={false}
                  className="mx-2"
                />
                <ListButton
                  label="Save"
                  size="large"
                  onClick={handleSubmit}
                  boxShadow={false}
                />
              </div>
            </>
          )}
        </Formik>
      </div>
    </>
  );
};
