export const HEADINGS = [
  ["id", "User ID"],
  ["first_name", "First Name"],
  ["last_name", "Last Name"],
  ["email", "Email Address"],
  ["status", "Status"]
];

export const searchMap = {
  Name: "first_name"
};
