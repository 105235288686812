/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import { HEADINGS } from "../constants";
import { generateDailyReports, renderExportsRow } from "../helpers";
import { ListButton } from "../../../../ui/components/ListButton";
import { ListHeader } from "../../../../ui/structures/ListHeader";
import { useFetch } from "../../../../hooks/fetch.hook";
import { sortHeaderStyle } from "../../../constants";
import { TableSortLabel } from "@material-ui/core";
import DatePicker from "../../../../ui/components/Datepicker";
import moment from "moment";
import { getDailySubmissions, getDownloadLink } from "../_api";
import { CircularProgress } from "@material-ui/core";
import {
  getStatusRowStyle,
  isExportSortable,
  isLink,
  isStatus
} from "../../../../ui/helpers";
import { warning } from "../../../../helpers/toasts";
import { ArchiveCheckbox } from "../../../../ui/components/ArchiveCheckbox";
import { Modal } from "../../../../ui/components/Modal";
import { EditPricing } from "./EditPricing";
import { Link } from "react-router-dom";
import { ExportPriceEdit } from "../../../../ui/components/ExportPriceEdit";
import {
  ArrowDownward as ArrowDownwardIcon,
  ArrowUpward as ArrowUpwardIcon
} from "@material-ui/icons";

export const List = () => {
  const { request } = useFetch();

  const [initialData, setInitialData] = useState([]);
  const [field, setField] = useState("");
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [direction, setDirection] = useState("");
  const [loading, setLoading] = useState(false);
  const [priceLoading, setPriceLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [displayChanges, setDisplayChanges] = useState(false);

  const [editModalOpen, setEditModalOpen] = useState(false);
  const [modalData, setModalData] = useState();

  useEffect(() => {
    setLoading(true);
    request(
      getDailySubmissions,
      moment(date).format("YYYY-MM-DD"),
      field,
      direction,
      displayChanges ? "changed_prices" : "all"
    )
      .then(submissions => {
        if (!submissions) {
          warning(
            "Please make sure that all your crops have at least one packge size assigned!"
          );
          return;
        } else {
          setInitialData(generateDailyReports(submissions));
        }
      })
      .finally(() => {
        setLoading(false);
        setPriceLoading(false);
      });
  }, [date, direction, field, displayChanges]);

  const handlePricingChange = () => {
    setPriceLoading(!priceLoading);
    setDisplayChanges(!displayChanges);
  };

  const renderButtons = () => (
    <>
      <div className="mr-10 d-flex justify-content-between align-items-center">
        <DatePicker
          setDate={setDate}
          className="mr-5"
          defaultValue={moment(date).format("yyyy-MM-DD")}
        />
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ width: "119.86px" }}
        >
          {downloading ? (
            <CircularProgress size="1.2rem" style={{ margin: 11 }} />
          ) : (
            <ListButton
              label="Download"
              size="small"
              boxShadow={false}
              background="rgba(107, 194, 0, 0.3)"
              text="#6BC200"
              onClick={() => downloadExport()}
              data-test="download"
            />
          )}
        </div>
      </div>
    </>
  );

  const downloadExport = () => {
    setDownloading(true);
    request(getDownloadLink, { date: moment(date).format("YYYY-MM-DD") })
      .then(response => {
        window.open(response.url, "_blank");
        if (!response) return;
      })
      .finally(() => setDownloading(false));
  };

  function renderHeaderWithSorting(headings) {
    return headings.map(([key, header]) => {
      if (isExportSortable(header)) {
        return (
          <th
            key={key}
            style={sortHeaderStyle}
            className="px-5 text-nowrap text-center"
            onClick={() => {
              setDirection(
                key !== field ? "desc" : direction === "desc" ? "asc" : "desc"
              );
              setField(key);
            }}
          >
            <span style={{ cursor: "pointer" }}>{header}</span>
            {key === field && <TableSortLabel active direction={direction} />}
          </th>
        );
      } else {
        return (
          <th
            key={key}
            style={sortHeaderStyle}
            className="px-5 text-nowrap text-center"
          >
            <span>{header}</span>
          </th>
        );
      }
    });
  }

  const getSortIcon = sortDirection => {
    return sortDirection && sortDirection !== "" ? (
      sortDirection === "de" ? (
        <ArrowDownwardIcon
          style={{
            color: "#EB5757",
            fontSize: "1.4rem"
          }}
        />
      ) : (
        <ArrowUpwardIcon
          style={{
            color: "#6BC200",
            fontSize: "1.4rem"
          }}
        />
      )
    ) : (
      <></>
    );
  };

  const renderExportsRow = (headings, item) => {
    return headings.map(([key]) => {
      if (key === "low") {
        return (
          <td
            key={key}
            className={`text-center no-line border-0 px-5 py-5 ${isStatus(
              key
            ) && getStatusRowStyle(item)}`}
          >
            <div className="d-flex align-items-center justify-content-end">
              {getSortIcon(item.price_changes?.low)}
              {item[key] !== "" ? `$${item[key]}` : "-"}
            </div>
          </td>
        );
      } else if (key === "high") {
        return (
          <td
            key={key}
            className={`text-center no-line border-0 px-5 py-5 ${isStatus(
              key
            ) && getStatusRowStyle(item)}`}
          >
            <div className="d-flex align-items-center justify-content-end">
              {getSortIcon(item.price_changes?.high)}
              {item[key] !== "" ? `$${item[key]}` : "-"}
            </div>
          </td>
        );
      } else if (key === "best") {
        return (
          <td
            key={key}
            className={`text-center no-line border-0 px-5 py-5 ${isStatus(
              key
            ) && getStatusRowStyle(item)}`}
          >
            <div className="d-flex align-items-center justify-content-end">
              {getSortIcon(item.price_changes?.best)}
              {item[key] !== "" ? `$${item[key]}` : "-"}
            </div>
          </td>
        );
      } else if (key === "edit") {
        return (
          <td
            key={key}
            className={`text-center no-line border-0 px-5 py-5 ${isStatus(
              key
            ) && getStatusRowStyle(item)}`}
          >
            <ListButton
              label="Edit"
              size="medium"
              boxShadow={false}
              onClick={() => {
                setEditModalOpen(true);
                setModalData(item);
              }}
            />
          </td>
        );
      }
      return (
        <td
          key={key}
          className={`text-center no-line border-0 px-5 py-5 ${isStatus(key) &&
            getStatusRowStyle(item)}`}
        >
          {isLink(key) ? (
            <Link className="text-dark" to={`/tags/${item.id}`}>
              {item[key]}
            </Link>
          ) : (
            <span>{item[key]}</span>
          )}
        </td>
      );
    });
  };

  return (
    <div className="row justify-content-center mt-10">
      <div className="col-12">
        <div
          className="bg-white rounded py-7 px-10"
          style={{ border: "1px solid #E5E5E5", borderRadius: "4px" }}
        >
          <Modal
            open={editModalOpen}
            onClose={() => setEditModalOpen(false)}
            submitable
            modalContent={
              <EditPricing
                pricingDetails={modalData}
                setModalOpen={setEditModalOpen}
                setData={setInitialData}
                data={initialData}
              />
            }
          />
          <ListHeader title="Daily Export" renderButtons={renderButtons} />
          <ArchiveCheckbox
            archivedLoading={priceLoading}
            archived={displayChanges}
            showArchived={handlePricingChange}
            label="Display changed prices"
          />
          <Datagrid
            data={initialData}
            headings={HEADINGS}
            renderRow={renderExportsRow}
            renderHeaderWithSorting={renderHeaderWithSorting}
            loading={loading}
            paginationOptions={[50, 100, 200]}
            paginationDefaultValue={50}
          />
        </div>
      </div>
    </div>
  );
};
