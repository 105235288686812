import React, { useState, useEffect } from "react";
import { ListButton } from "./ListButton";
import { useFetch } from "../../hooks/fetch.hook";
import { getPackageRelatedCrops } from "../../app/modules/Settings/PackageSizes/_api";
import { modifyRelatedCrops } from "../../app/modules/Settings/PackageSizes/helpers";
import { CircularProgress } from "@material-ui/core";
import { Link } from "react-router-dom";

export const ConfirmDelete = ({
  name = "this item",
  handleClose,
  handleSubmit,
  id,
  isDelete = false,
  isPackageDelete = false
}) => {
  const { request } = useFetch();
  const [relatedCrops, setRelatedCrops] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isPackageDelete) {
      setLoading(true);
      request(getPackageRelatedCrops, id)
        .then(response => {
          if (!response) return;
          setRelatedCrops(modifyRelatedCrops(response));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id, request, isPackageDelete]);

  return (
    <div className="pb-5">
      <div className="text-center">
        {loading ? (
          <CircularProgress size="3rem" className="my-5" />
        ) : (
          <>
            <h2 style={{ color: "rgba(0, 0, 0, 0.54)" }}>
              Are you sure you want to {isDelete ? "delete" : "archive"} {name}{" "}
              ?
            </h2>
            {relatedCrops.length !== 0 && isPackageDelete && (
              <div>
                <h6 className="my-3 text-warning">
                  This will remove the package size from the following crops
                </h6>
                <div class="d-flex-column justify-content-center align-items-center my-3">
                  {relatedCrops.length !== 0 &&
                    relatedCrops.map(crop => (
                      <Link
                        to={{
                          pathname: `/crops/${crop.id}`
                        }}
                        className="text-reset d-block my-1"
                      >
                        <u>{crop.label}</u>
                      </Link>
                    ))}
                </div>
              </div>
            )}
            {relatedCrops.length === 0 && isPackageDelete && (
              <h6>Package size has not been assigned to any crop.</h6>
            )}
            <div className="mt-10 d-flex justify-content-end mr-4">
              <div className="mr-3">
                <ListButton
                  label="Cancel"
                  size="small"
                  onClick={handleClose}
                  boxShadow={false}
                  className="mx-2"
                />
              </div>
              <div>
                <ListButton
                  label={isDelete ? "Delete" : "Archive"}
                  size="small"
                  onClick={() => handleSubmit(id)}
                  boxShadow={false}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
