const type = "submissions";

export const setSubmissionList = (payload) => ({
  execute: (state) => {
    return {
      ...state,
      submissionList: payload,
    };
  },
  type,
});

export const removeSubmission = (payload) => ({
  execute: (state) => {
    return {
      ...state,
      submissionList: state.submissionList.filter(({ id }) => id !== payload),
    };
  },
  type,
});

export const unArchiveSubmission = (payload) => ({
  execute: (state) => {
    const index = state.submissionList.findIndex(
      (item) => item.id === payload.id
    );
    const myState = [...state.submissionList];
    myState.splice(index, 1, payload);
    return {
      ...state,
      submissionList: [...myState],
    };
  },
  type,
});
