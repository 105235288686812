import React from "react";
import {
  AccessAlarm as AccessAlarmIcon,
  NotificationImportant as NotificationImportantIcon,
  Done as DoneIcon,
  Archive as ArchiveIcon,
  EventBusy as EventBusyIcon
} from "@material-ui/icons";

export function isLink(key) {
  return ["random_line"].includes(key);
}

export function isExportKey(key) {
  return ["id"].includes(key);
}

export function isExportSortable(key) {
  return ["Name", "Grade", "Variety"].includes(key);
}

export function isStatusField(key, status) {
  if (key === "status") {
    if (status === "active") {
      return (
        <NotificationImportantIcon style={{ color: "red" }} className="mr-1" />
      );
    } else if (status === "overdue") {
      return <AccessAlarmIcon style={{ color: "EE9856" }} className="mr-1" />;
    } else if (status === "submitted") {
      return <DoneIcon style={{ color: "#6BC200" }} className="mr-1" />;
    } else if (status === "archived") {
      return <ArchiveIcon className="mr-1" />;
    } else if (status === "expired") {
      return <EventBusyIcon className="mr-1" />;
    }
  }
}

export function isArray(key) {
  return ["states"].includes(key);
}

export function isStatus(key) {
  return ["status", "payment_status"].includes(key);
}

export function isDonwloadKey(key) {
  return ["download_pdf"].includes(key);
}

export function getTip(meta = {}, tip) {
  if (meta.touched && meta.error) {
    return <span className="text-danger">{meta.error}</span>;
  }
  if (tip) {
    return <span className="text-muted">{tip}</span>;
  }
}

export function renderRow(headings, item) {
  return headings.map(([key]) => (
    <td key={key} className="align-middle no-line border-0 px-5 py-5">
      {item[key]}
    </td>
  ));
}

function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export function formatDate(date) {
  const d = new Date(date);
  if (isValidDate(d)) {
    let day = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
    let month = d.getMonth() + 1;
    let monthWithNull = month < 10 ? "0" + month : month;
    return day + "/" + monthWithNull + "/" + d.getFullYear();
  }
  console.error("Not a valid Date");
}

export function formatDateWithHours(date) {
  const d = new Date(date);
  if (isValidDate(d)) {
    let day = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
    let month = d.getMonth() + 1;
    let monthWithNull = month < 10 ? "0" + month : month;
    let hours = d.getHours();
    let minutes = d.getMinutes();

    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    return (
      day +
      "/" +
      monthWithNull +
      "/" +
      d.getFullYear() +
      " " +
      hours +
      ":" +
      minutes
    );
  }
  console.error("Not a valid Date");
}

export function formatFileName(name) {
  return name
    .split("-")
    .slice(1)
    .join("-");
}

export function sortByDate(a, b) {
  return new Date(b.last_modified) - new Date(a.last_modified);
}

export const onSearch = (search, data, dispatch, action, headings) => {
  dispatch(
    action(
      data.filter(item => {
        const _search = Object.entries(search).map(item => [
          (headings.find(i => i[1] === item[0]) || [])[0],
          item[1]
        ]);
        return (
          _search.length === 0 ||
          _search.every(([k, v]) => {
            if (Array.isArray(item[k])) {
              return item[k].includes(v);
            }
            return item[k] === v;
          })
        );
      })
    )
  );
};

export const getSelectedId = selected => {
  for (let key in selected) {
    if (selected[key]) {
      return key;
    }
  }
};

export const getSelectedIDs = selected => {
  let selectedItems = [];
  for (let key in selected) {
    if (selected[key]) {
      selectedItems = [...selectedItems, key];
    }
  }
  return selectedItems;
};

export const insert = (arr, index, newItem) => [
  ...arr.slice(0, index),
  newItem,
  ...arr.slice(index + 1)
];

export function formatPrice(price) {
  if (typeof price !== "number") return;
  if (price >= 0) {
    return `$ ${price.toFixed(2)}`;
  } else return `$ (${price.toFixed(2).slice(1)})`;
}

export function formatLimit(limit) {
  if (typeof limit !== "number") return;
  if (limit >= 0) {
    return `${limit.toFixed(2)} %`;
  } else return `(${limit.toFixed(2).slice(1)} %)`;
}

export const removeEmpty = obj => {
  Object.keys(obj).forEach(key => {
    if (obj[key] === "") delete obj[key];
  });
};

export const getUserRole = role => {
  return role;
};

export const getStatusRowStyle = (item = {}) => {
  if (item.status === "archived") {
    return "text-uppercase text-danger";
  } else return "text-uppercase";
};

const setSearchKey = (data = {}, searchMap, key) => {
  if (key === "Tag") {
    return `tags.name=${data.Tag}`;
  } else if (key === "Contact") {
    return `contact.first_name=${data[key].split(" ")[0]}&contact.last_name=${
      data[key].split(" ")[1]
    }`;
  } else if (searchMap[key]) {
    return `${searchMap[key]}=_like(${encodeURIComponent(data[key])})_`;
  } else return "";
};

export const handleSearch = (data = {}, searchMap = {}, setSearch, setPage) => {
  setSearch(
    Object.keys(data)
      .map(key => {
        return setSearchKey(data, searchMap, key);
      })
      .join("&")
  );
  setPage && setPage(0);
};

export const startDownload = url => {
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("target", "_blank");
  link.setAttribute("rel", "noopener noreferrer");
  link.click();
};

export const sortHelper = (a, b) => {
  if (a.label > b.label) return 1;
  if (a.label < b.label) return -1;
  return 0;
};
