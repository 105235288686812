import config from "../../../../config";

export const getDailySubmissions = (date, field, direction, display) =>
  fetch(
    `${config.ApiUrl.Rest}/submissions/exporter?date=${date}${(field &&
      "&order_by=" + field) ||
      ""}${(direction && "&order_dir=" + direction) || ""}${(display &&
      "&display=" + display) ||
      ""}`,
    {
      mode: "cors",
      credentials: "include"
    }
  );

export const getDownloadLink = date =>
  fetch(`${config.ApiUrl.Rest}/submissions/exporter`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(date),
    mode: "cors",
    credentials: "include"
  });

export const updatePricing = (payload, id, price_id) =>
  fetch(`${config.ApiUrl.Rest}/submissions/${id}/prices/${price_id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(payload),
    mode: "cors",
    credentials: "include"
  });

export const getPricing = (submissionId, pricingId) =>
  fetch(
    `${config.ApiUrl.Rest}/submissions/${submissionId}/prices/${pricingId}`,
    {
      headers: { "Content-Type": "application/json" },
      mode: "cors",
      credentials: "include"
    }
  );
