import React from "react";
import { Dialog, DialogContent } from "@material-ui/core";
import { ListButton } from "./ListButton";

export function Modal({
  onClose,
  isOpen,
  modalContent,
  buttonText = "OK",
  submitable = false,
  maxWidth = "lg",
  ...other
}) {
  return (
    <Dialog
      onClose={onClose}
      open={isOpen}
      fullWidth={true}
      maxWidth={maxWidth}
      {...other}
    >
      <DialogContent className="p-0 pt-5 mx-5">
        {modalContent}{" "}
        {!submitable && (
          <div className="text-right mb-5 mr-10">
            <ListButton
              label={buttonText}
              size="small"
              onClick={onClose}
              boxShadow={false}
            />
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}
