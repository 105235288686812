import React, { useState, useEffect } from "react";
import Tabs from "@bit/the-glue.frontendcomponents.tabs";
import { getSubscriberById } from "../_api";
import { useFetch } from "../../../../../hooks/fetch.hook";
import { SubscriberDetails } from "./SubscriberDetails";
import { SubscriberSettings } from "./SubscriberSettings";
import { useSelector } from "react-redux";
import { getSubscriber } from "../_redux/selectors";

export const Card = ({
  match: {
    params: { id }
  }
}) => {
  const { request } = useFetch();

  const data = useSelector(getSubscriber(id));
  const [cardData, setCardData] = useState();

  const getSubscriberDetails = () => {
    if (!data) {
      request(getSubscriberById, id).then(response => {
        if (!response) return;
        setCardData(response);
      });
    } else {
      setCardData(data);
    }
  };

  useEffect(() => {
    getSubscriberDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tabs = [
    {
      label: "Profile",
      tabPanel: <SubscriberDetails cardData={cardData} id={id} />
    },
    {
      label: "Plan Management",
      tabPanel: <SubscriberSettings cardData={cardData} id={id} />
    }
  ];

  return (
    <div className="mt-10">
      <Tabs
        defaultActive="0"
        tabs={tabs}
        align="right"
        background="#6BC200"
        text="white"
      />
    </div>
  );
};
