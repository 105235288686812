import React, { useState } from "react";
import { Formik } from "formik";
import { CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { MappedInput } from "../../../../ui/components/Input/index";
import {
  addUser,
  modifyUser,
  archiveUser,
  reactivateUser,
  resetAccount
} from "../_api";
import { useFetch } from "../../../../hooks/fetch.hook";
import { userProfileSchema } from "../../../../helpers/schemas";
import { info } from "../../../../helpers/toasts";
import { Loader } from "../../../../ui/components/Loader";
import { ListButton } from "../../../../ui/components/ListButton";

export const UserDetails = ({ data, id, name }) => {
  const { request } = useFetch();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [inviteIsLoading, setInviteIsLoading] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const [revokeLoading, setRevokeLoading] = useState(false);

  const DetailsInitialValues = {
    first_name: data?.first_name || "",
    last_name: data?.last_name || "",
    email: data?.email || ""
  };

  const handleSubmit = ({ first_name, last_name, email }) => {
    setLoading(true);
    request(
      id ? modifyUser : addUser,
      { email, name: `${first_name} ${last_name}` },
      id
    )
      .then(data => data && history.push("/users-list"))
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClose = () => history.goBack();

  const revokeAccess = () => {
    setRevokeLoading(true);
    request(archiveUser, id)
      .then(data => data && history.push("/users-list"))
      .finally(() => setRevokeLoading(false));
  };

  const inviteUserToPortal = () => {
    setInviteIsLoading(true);
    request(reactivateUser, id)
      .then(data => {
        if (!data) return;
        info(`${name} has been Invited to the portal!`);
      })
      .finally(() => setInviteIsLoading(false));
  };

  const resetAccountData = () => {
    setResetLoading(true);
    request(resetAccount, id)
      .then(data => {
        if (!data) return;
        info("User's account has been reset!");
      })
      .finally(() => setResetLoading(false));
  };

  return (
    <div
      className="bg-white p-10"
      style={{
        border: "1px solid #E5E5E5",
        borderRadius: "4px",
        minWidth: "535px"
      }}
    >
      {loading && <Loader isOpen={loading} maxWidth="xs" title="Loading..." />}
      {id && (
        <div className="mt-2 mb-7 row">
          <div className="col-8 d-flex justify-content-end">
            {resetLoading ? (
              <div className="mr-15 d-flex justify-content-center align-items-center">
                <CircularProgress size="1.2rem" />
              </div>
            ) : (
              <div className="mr-3 ">
                <ListButton
                  label="Reset Password"
                  size="small"
                  background="rgba(107, 194, 0, 0.3)"
                  text="#6BC200"
                  textTransform="uppercase"
                  fontWeight="600"
                  className="mr-5"
                  onClick={resetAccountData}
                />
              </div>
            )}
            {revokeLoading ? (
              <div className="mr-15 d-flex justify-content-center align-items-center">
                <CircularProgress size="1.2rem" />
              </div>
            ) : (
              <div className="mr-3">
                <ListButton
                  label="Revoke Portal Access"
                  size="small"
                  background="rgba(107, 194, 0, 0.3)"
                  text="#6BC200"
                  textTransform="uppercase"
                  fontWeight="600"
                  className="mr-5"
                  onClick={revokeAccess}
                />
              </div>
            )}
            {data.status === "archived" &&
              (inviteIsLoading ? (
                <div className="mr-15 d-flex justify-content-center align-items-center">
                  <CircularProgress size="1.2rem" />
                </div>
              ) : (
                <ListButton
                  label="Invite User to Portal"
                  size="small"
                  background="rgba(107, 194, 0, 0.3)"
                  text="#6BC200"
                  textTransform="uppercase"
                  fontWeight="600"
                  className="mr-5"
                  onClick={inviteUserToPortal}
                />
              ))}
          </div>
        </div>
      )}
      <Formik
        onSubmit={handleSubmit}
        initialValues={DetailsInitialValues}
        validationSchema={userProfileSchema}
      >
        {({ handleSubmit }) => (
          <>
            <h3 className="mb-10">
              <strong>{id ? name : "Add User"}</strong>
            </h3>
            <div className="row justify-content-between">
              <div className="col-8">
                <MappedInput
                  label="First Name"
                  name="first_name"
                  data-testid="first_name"
                  color="#6BC200"
                />
                <MappedInput
                  label="Last Name"
                  name="last_name"
                  data-testid="last_name"
                  color="#6BC200"
                />
                <MappedInput
                  label="Email"
                  name="email"
                  data-testid="email"
                  color="#6BC200"
                />
              </div>
            </div>
            <div className="mt-10 text-right">
              <ListButton
                label="Cancel"
                size="large"
                onClick={handleClose}
                boxShadow={false}
                className="mx-2"
              />
              <ListButton
                label="Save"
                size="large"
                onClick={handleSubmit}
                boxShadow={false}
              />
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};
