import * as Yup from "yup";

export const OrganisationDetailsSchema = Yup.object({
  business_name: Yup.string().required("Organisation Name is a required field"),
  legal_name: Yup.string().required("Legal Name is a required field"),
  physical_address: Yup.string().required(
    "Physical Address is a required field"
  ),
  physical_address2: Yup.string(),
  physical_state: Yup.string().required("Physical State is a required field"),
  physical_postcode: Yup.string()
    .required("Postcode is a required field")
    .length(4, "Postcode must be exactly 4 characters")
    .matches(/(^[0-9]+$)/, "Only numbers are allowed"),
  postal_address: Yup.string().required("Postal Address is a required field"),
  postal_address2: Yup.string(),
  postal_state: Yup.string().required("Postal State is a required field"),
  postal_postcode: Yup.string()
    .required("Postcode is a required field")
    .length(4, "Postcode must be exactly 4 characters")
    .matches(/(^[0-9]+$)/, "Only numbers are allowed"),
  ABN: Yup.string()
    .required()
    .matches(/(^[0-9 ]+$)/, "Must be 11 digits"),
  status: Yup.string().required("Status is required")
});

export const ContactDetailsSchema = Yup.object({
  first_name: Yup.string()
    .required("First Name is a required field")
    .matches(
      /(^[a-zA-Z\u00C0-\u024F -']+$)/,
      "Only alphabet characters are allowed"
    ),
  last_name: Yup.string()
    .required("Last Name is a required field")
    .matches(
      /(^[a-zA-Z\u00C0-\u024F -']+$)/,
      "Only alphabet characters are allowed"
    ),
  mobile: Yup.string()
    .required("Mobile is a required field")
    .matches(/(^[0-9+]+$)/, "Only numbers are allowed"),
  email: Yup.string()
    .required("Email is a required field")
    .email("Must be a valid email")
    .matches(/(^[a-z0-9@.!#$%&'*+-/=?^_`{|}~]+$)/, "Restricted characters"),
  organisation_id: Yup.array().required("Organisation is a required field"),
  status: Yup.string().required("Status is required"),
  price_collection_method: Yup.string().required("Pricing Method is required")
});

export const userProfileSchema = Yup.object({
  first_name: Yup.string()
    .required("First Name is required")
    .matches(
      /(^[a-zA-Z\u00C0-\u024F -']+$)/,
      "Only alphabet characters are allowed"
    ),
  last_name: Yup.string()
    .required("Last Name is required")
    .matches(
      /(^[a-zA-Z\u00C0-\u024F -']+$)/,
      "Only alphabet characters are allowed"
    ),
  email: Yup.string()
    .required("Email is required")
    .email("Must be a valid email")
    .matches(/(^[a-z0-9@.!#$%&'*+-/=?^_`{|}~]+$)/, "Restricted characters")
});

export const UserPasswordSchema = Yup.object({
  old_password: Yup.string().required("Current Password is required"),
  new_password: Yup.string()
    .required("Please enter your password")
    .matches(
      /^.*(?=.{14,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 14 characters, one uppercase, one number and one special case character"
    ),
  confirm_password: Yup.string()
    .required("Please confirm your password")
    .when("new_password", {
      is: new_password =>
        new_password && new_password.length > 0 ? true : false,
      then: Yup.string().oneOf(
        [Yup.ref("new_password")],
        "Password doesn't match"
      )
    })
});

export const confirmPasswordSchema = Yup.object({
  code: Yup.string().required("Code is required"),
  new_password: Yup.string().required("New Password is required")
});

export const productLookupSchema = Yup.object({
  crop_id: Yup.string().required("Please select a product")
});

export const CropDetailsSchema = Yup.object({
  name: Yup.string().required("Crop Name is a required field"),
  variety: Yup.string().required("Variety is a required field"),
  grade: Yup.string().required("Grade is a required field"),
  contact: Yup.string().required("Selecting a contact is required"),
  parent_category: Yup.string().required("Parent Category is a required field"),
  sub_category: Yup.string().required("Sub Category is a required field"),
  package_sizes: Yup.string().when("newCrop", {
    is: true,
    then: Yup.string().required("Please add at least one package size")
  })
});

export const TagsDetailsSchema = Yup.object({
  name: Yup.string().required("Name is a required field"),
  type: Yup.string().required("Type is a required field"),
  category: Yup.string().required("Category is a required field")
});

export const CategoryDetailsSchema = Yup.object({
  name: Yup.string().required("Name is a required field"),
  category_id: Yup.string().required("Category is a required field")
});

export const SubscriberDetailsSchema = Yup.object({
  first_name: Yup.string().when("is_company", {
    is: false,
    then: Yup.string().required("First Name is a required field")
  }),
  last_name: Yup.string().when("is_company", {
    is: false,
    then: Yup.string().required("Last Name is a required field")
  }),
  phone: Yup.number()
    .typeError("Numbers only")
    .required("Phone is a required field")
    .min(10, "Minimum 10 characters"),
  email: Yup.string()
    .required("Email is a required field")
    .email("Must be a valid email")
    .matches(/(^[a-zA-Z0-9@.!#$%&'*+-/=?^_`{|}~]+$)/, "Restricted characters"),
  company_abn: Yup.string().when("is_company", {
    is: true,
    then: Yup.string().required("ABN is a required field")
  }),
  company_name: Yup.string().when("is_company", {
    is: true,
    then: Yup.string().required("Company Name is a required field")
  }),
  address: Yup.string().required("Address is a required field"),
  postcode: Yup.string()
    .min(4, "Postal code must be 4 digits")
    .required("Postal code is a required field"),
  state: Yup.string().required("State is a required field"),
  city: Yup.string().required("City is a required field")
});

export const SubscriberDetailsEditSchema = Yup.object({
  name: Yup.string().required("Name is a required field"),
  phone: Yup.number()
    .typeError("Numbers only")
    .required("Phone is a required field")
    .min(10, "Minimum 10 characters"),
  email: Yup.string()
    .required("Email is a required field")
    .email("Must be a valid email")
    .matches(/(^[a-zA-Z0-9@.!#$%&'*+-/=?^_`{|}~]+$)/, "Restricted characters"),
  state: Yup.string().required("State is a required field"),
  address: Yup.string().required("Address is a required field"),
  city: Yup.string().required("City is a required field"),
  postcode: Yup.string()
    .min(4, "Postal code must be 4 digits")
    .required("Postal code is a required field")
});

export const SignUpPlanDetailsSchema = Yup.object({
  report_format: Yup.string().required("Please select a report format")
});

export const PackageDetailsSchema = Yup.object({
  name: Yup.string().when("custom_name", {
    is: false,
    then: Yup.string().required("Crop Name is a required field")
  }),
  new_crop_name: Yup.string().when("custom_name", {
    is: true,
    then: Yup.string().required("Crop Name is a required field")
  }),
  quantity: Yup.number()
    .required("Quantity is a required field")
    .typeError("Invalid type"),
  measure: Yup.string().required("Measure is a required field")
});

export const inviteUserSchema = Yup.object({
  email_to_invite: Yup.string()
    .required("Email is a required field")
    .email("Must be a valid email")
    .matches(/(^[a-z0-9@.!#$%&'*+-/=?^_`{|}~]+$)/, "Restricted characters"),
  first_name: Yup.string()
    .required("First Name is a required field")
    .matches(
      /(^[a-zA-Z\u00C0-\u024F -']+$)/,
      "Only alphabet characters are allowed"
    ),
  last_name: Yup.string()
    .required("Last Name is a required field")
    .matches(
      /(^[a-zA-Z\u00C0-\u024F -']+$)/,
      "Only alphabet characters are allowed"
    )
});

export const priceSubmissionSchema = Yup.object().shape(
  {
    low_price: Yup.number()
      .max(
        Yup.ref("high_price"),
        "Low price can`t be greater than the high price"
      )
      .required("Low Price is a required field"),
    high_price: Yup.number()
      .min(
        Yup.ref("low_price"),
        "High price must be greater or equal to low price"
      )
      .required("High Price is a required field"),
    state_name: Yup.string().required("Please Select the state"),
    supply: Yup.number()
      .min(0, "Please select an option")
      .required("Please Select the region"),
    demand: Yup.number()
      .min(0, "Please select an option")
      .required("Please Select the region"),
    carryover: Yup.number()
      .min(0, "Please select an option")
      .required("Please Select the region"),
    quality: Yup.number()
      .min(0, "Please select an option")
      .required("Please Select the region"),
    best_price: Yup.number().when("best_price", {
      is: exists => !!exists,
      then: Yup.number().min(
        Yup.ref("high_price", "Best price can not be lower than the high price")
      ),
      otherwise: Yup.number().notRequired()
    })
  },
  [["best_price", "best_price"]]
);

export const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .required("Email is a required field")
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
});

export const newPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .required()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols"),
  code: Yup.number().required(),
  new_password: Yup.string()
    .required("Passwrod is a required field")
    .matches(
      /^.*(?=.{14,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 14 characters, one uppercase, one number and one special case character"
    )
});
