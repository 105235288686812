export const HEADINGS = [
  ["code", "Tag ID"],
  ["name", "Tag Name"],
  ["category", "Tag Category"],
  ["type", "Tag Type"],
  ["status", "Status"],
];

export const searchMap = {
  "Tag ID": "code",
  "Tag Name": "name",
  "Tag Category": "category",
  "Tag Type": "type",
};

export const tagTypeList = [
  {
    value: "Organisation",
    label: "Organisation",
  },
  {
    value: "Contact",
    label: "Contact",
  },
  {
    value: "Crop",
    label: "Crop",
  },
];
