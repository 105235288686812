import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import SearchBar from "@bit/the-glue.frontendcomponents.search-bar";
import { getSelectedId, handleSearch } from "../../../../ui/helpers";
import { HEADINGS } from "../constants";
import { ListButton } from "../../../../ui/components/ListButton";
import { ListHeader } from "../../../../ui/structures/ListHeader";
import { useFetch } from "../../../../hooks/fetch.hook";
import { searchMap } from "../constants";
import { UnarchiveButton } from "../../../../ui/components/UnarchiveButton";
import { Modal } from "../../../../ui/components/Modal";
import { ConfirmDelete } from "../../../../ui/components/ConfirmDelete";
import { ArchiveCheckbox } from "../../../../ui/components/ArchiveCheckbox";
import { sortHeaderStyle } from "../../../constants";
import { TableSortLabel } from "@material-ui/core";
import { getTags } from "../../Settings/Tags/_api";
import { info } from "../../../../helpers/toasts";
import { Loader } from "../../../../ui/components/Loader";
import { getContactCrops, modifyCrop } from "../../Crops/CropsManagement/_api";
import { GetApp as GetAppIcon } from "@material-ui/icons";
import {
  getStatusRowStyle,
  isExportKey,
  isLink,
  isStatus
} from "../../../../ui/helpers";
import {
  removeContact,
  setContactsList,
  updateContactsList
} from "../_redux/actions";
import {
  archiveContact,
  cropDataExport,
  deleteContactCrops,
  getContactById,
  getContacts,
  modifyContact
} from "../_api";

export const List = () => {
  const dispatch = useDispatch();
  const { request } = useFetch();

  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState({});
  const [focused, setFocused] = useState(false);
  const [initialData, setInitialData] = useState([]);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [archivedLoading, setArchivedLoading] = useState(false);
  const [archived, setArchived] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [unArchiving, setUnarchiving] = useState(false);
  const [field, setField] = useState("");
  const [direction, setDirection] = useState("");
  const [tags, setTags] = useState([]);
  const [actionLoading, setActionLoading] = useState(false);

  const handleModalClose = () => setConfirmDeleteOpen(false);

  const data = useSelector(({ contacts: { contactsList } }) => contactsList);

  useEffect(() => {
    setLoading(true);
    request(getContacts, search, archived, field, field && direction)
      .then(data => {
        if (!data) return;
        dispatch(setContactsList(data));
        setInitialData(data);
      })
      .finally(() => {
        setArchivedLoading(false);
        setLoading(false);
      });
    // eslint-disable-next-line
  }, [search, archived, field, direction]);

  useEffect(() => {
    request(getTags, "type=Contact").then(data => data && setTags(data));
    // eslint-disable-next-line
  }, []);

  const unArchiveDisabled = useMemo(
    () => Object.values(selected).filter(Boolean).length !== 1,
    [selected]
  );

  const handleArchive = id => {
    setConfirmDeleteOpen(false);
    setActionLoading(true);
    Promise.all([
      request(archiveContact, id),
      request(getContactCrops, id, search, archived, field, field && direction)
    ])
      .then(([data, crops]) => {
        if (!data || !crops) return;
        const cropPayload = crops.map(crop => ({ id: crop.id }));
        crops
          .filter(crop => crop.contacts.length <= 1)
          .forEach(crop => {
            request(modifyCrop, { status: "unassigned" }, crop.id);
          });
        request(deleteContactCrops, id, cropPayload);
        if (archived) {
          request(getContactById, id).then(
            data => data && dispatch(updateContactsList(data))
          );
        } else dispatch(removeContact(id));
        info("Contact has been archived!");
        setSelected({});
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  const handleUnarchive = () => {
    const contactID = getSelectedId(selected);
    const status = (data.find(({ id }) => id === contactID) || {}).status;
    if (status !== "archived") return;
    setUnarchiving(true);
    request(modifyContact, contactID, { status: "active" })
      .then(data => {
        if (!data) return;
        dispatch(updateContactsList(data));
        setSelected({});
        info("Contact has been unarchived!");
      })
      .finally(() => {
        setUnarchiving(false);
      });
  };

  const renderButtons = () => (
    <>
      {archived && (
        <UnarchiveButton
          handleClick={handleUnarchive}
          disabled={unArchiveDisabled}
          loading={unArchiving}
        />
      )}
      <div className="mr-10">
        <Link
          to={{
            pathname: `/add-contact`
          }}
          data-testid="new"
        >
          <ListButton
            label="New"
            size="small"
            boxShadow={false}
            background="rgba(107, 194, 0, 0.3)"
            text="#6BC200"
          />
        </Link>
      </div>
    </>
  );

  const KEY_MAP = {
    "First Name": data.map(item => item.first_name),
    "Last Name": data.map(item => item.last_name),
    Phone: data.map(item => item.phone),
    Mobile: data.map(item => item.mobile),
    Email: data.map(item => item.email),
    Tag: tags.map(item => (item || {}).name)
  };

  const showArchived = () => {
    setArchivedLoading(true);
    setArchived(!archived);
  };

  const handleOpenModal = id => {
    setConfirmDeleteOpen(true);
    setSelectedItem(id);
  };

  const resetSearch = () => {
    dispatch(setContactsList(initialData));
    setSearch("");
  };

  const handleFocus = () => setFocused(true);

  const handleCropDataExport = contactID => {
    setActionLoading(true);
    request(cropDataExport, { contact_id: contactID })
      .then(response => {
        if (!response) return;
        window.open(response.url, "_blank");
        info("Crop List downloaded!");
      })
      .finally(() => setActionLoading(false));
  };

  function renderHeaderWithSorting(headings) {
    return headings.map(([key, header]) => (
      <th
        key={key}
        style={sortHeaderStyle}
        className="px-5 text-nowrap text-center"
        onClick={() => {
          setDirection(
            key !== field ? "desc" : direction === "desc" ? "asc" : "desc"
          );
          setField(key);
        }}
      >
        <span style={{ cursor: "pointer" }}>{header}</span>
        {key === field && <TableSortLabel active direction={direction} />}
      </th>
    ));
  }

  function renderContactsRow(headings, item) {
    return headings.map(([key]) => (
      <td
        key={key}
        className={`text-center no-line border-0 px-5 py-5 ${isStatus(key) &&
          getStatusRowStyle(item)}`}
      >
        {isExportKey(key) ? (
          <GetAppIcon
            className="cursor-pointer"
            onClick={() => {
              handleCropDataExport(item.id);
            }}
          />
        ) : isLink(key) ? (
          <Link className="text-dark" to={`/contacts/${item.id}`}>
            {item[key]}
          </Link>
        ) : (
          item[key]
        )}
      </td>
    ));
  }

  return (
    <>
      {actionLoading && <Loader title="Loading..." isOpen={actionLoading} />}
      {confirmDeleteOpen && (
        <Modal
          isOpen={confirmDeleteOpen}
          submitable
          onClose={handleModalClose}
          maxWidth="sm"
          modalContent={
            <ConfirmDelete
              handleClose={handleModalClose}
              handleSubmit={handleArchive}
              id={selectedItem}
              name={
                (data.find(({ id }) => id === selectedItem) || {}).first_name
              }
            />
          }
        />
      )}
      <div className="row justify-content-center mt-10">
        <div className="col-12">
          <div
            className="bg-white rounded py-7 px-10"
            style={{ border: "1px solid #E5E5E5", borderRadius: "4px" }}
          >
            <ListHeader
              title="Contacts"
              renderButtons={renderButtons}
              handleFocus={handleFocus}
            />
            <div>
              <SearchBar
                onSearch={data => handleSearch(data, searchMap, setSearch)}
                clearSearch={resetSearch}
                keyMap={KEY_MAP}
                placeholder="Filter contacts..."
                elevation={2}
                chipbackgroundcolor="rgba(107, 194, 0, 0.3)"
                chipcolor="#6BC200"
                focused={focused}
                setFocused={setFocused}
              />
            </div>
            <ArchiveCheckbox
              archivedLoading={archivedLoading}
              archived={archived}
              showArchived={showArchived}
            />
            <Datagrid
              data={data}
              headings={HEADINGS}
              renderRow={renderContactsRow}
              selected={selected}
              setSelected={setSelected}
              editable
              loading={loading}
              selectable
              deletable
              handleDelete={handleOpenModal}
              renderHeaderWithSorting={renderHeaderWithSorting}
              link="contacts"
            />
          </div>
        </div>
      </div>
    </>
  );
};
