export const stepperInputStyle = {
  borderRadius: "10px",
  backgroundColor: "#fbfcfd",
  border: "none"
};

export const stepLabels = [
  "Starter",
  "Personal Details",
  "Plan Details",
  "Payment information",
  "Summary"
];

export const mobileStepValues = [20, 40, 60, 80, 100];

export const reportFormats = [
  { value: "csv", label: "CSV" },
  { value: "pdf", label: "PDF" }
];

export const states = [
  { value: "NSW", label: "NSW" },
  { value: "NT", label: "NT" },
  { value: "QLD", label: "QLD" },
  { value: "SA", label: "SA" },
  { value: "TAS", label: "TAS" },
  { value: "VIC", label: "VIC" },
  { value: "WA", label: "WA" },
  { value: "ACT", label: "ACT" }
];
