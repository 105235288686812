import React, { useState } from "react";
import { Formik, Form } from "formik";
import { makeStyles, Tooltip } from "@material-ui/core";
import { Error as ErrorIcon } from "@material-ui/icons";
import { RegionEdit } from "./RegionEdit";
import { MappedNakedInput } from "../../../../ui/components/NakedInput";
import { Selector } from "../../../../ui/components/ButtonSelector";
import { priceSubmissionSchema } from "../../../../helpers/schemas";
import { checkValidation } from "../helpers";
import {
  carryOverOptions,
  demandOptions,
  qualityOptions,
  supplyOptions
} from "../constants";
import { ListButton } from "../../../../ui/components/ListButton";

const useStyles = makeStyles({
  root: {
    fontSize: "3.5rem",
    padding: 0,
    margin: 0,
    marginBottom: 10,
    height: "60px",
    textAlign: "center",
    maxWidth: "200px"
  },
  underline: {
    "&&&:before": {
      borderBottom: "none !important"
    },
    "&&:after": {
      borderBottom: "none !important"
    },
    "&&:focus": {
      borderBottom: "none !important"
    },
    "&&&:hover": {
      borderBottom: "none !important"
    }
  }
});

export const Edit = ({ cropData, setEdit, setCrops, crops }) => {
  const [regionSelect, setRegionSelect] = useState(false);

  const initialValues = {
    low_price: cropData.prices.low || "",
    high_price: cropData.prices.high || "",
    best_price: cropData.prices.best || "",
    region_name: cropData.prices.region_name || "",
    region_id: cropData.prices.region_id || "",
    state_name: cropData.prices.state_name || "",
    state_id: cropData.prices.state_id || "",
    country: cropData.prices.country_id || "",
    country_name: cropData.prices.country_name || "",
    supply: supplyOptions.indexOf(cropData.prices.supply),
    demand: demandOptions.indexOf(cropData.prices.demand),
    carryover: carryOverOptions.indexOf(cropData.prices.carryover),
    quality: qualityOptions.indexOf(cropData.prices.quality)
  };

  const handleSubmit = values => {
    setCrops(
      crops.map(crop => {
        if (crop.generated_id === cropData.generated_id) {
          crop.prices.low = values.low_price;
          crop.prices.high = values.high_price;
          crop.prices.demand =
            demandOptions[values.demand] === undefined
              ? ""
              : demandOptions[values.demand];
          crop.prices.supply =
            supplyOptions[values.supply] === undefined
              ? ""
              : supplyOptions[values.supply];
          crop.prices.carryover =
            carryOverOptions[values.carryover] === undefined
              ? ""
              : carryOverOptions[values.carryover];
          crop.prices.quality =
            qualityOptions[values.quality] === undefined
              ? ""
              : qualityOptions[values.quality];
          crop.prices.crop_id = cropData.id;
          crop.prices.state_name = values.state_name;
          crop.prices.state_id = values.state_id;
          crop.prices.country_id = values.country;
          crop.prices.best = values.best_price;
          crop.prices.package_size = cropData.package_size.id;
          crop.prices.region_name = values.region_name;
          crop.prices.region_id = values.region_id;
          crop.prices.country_name = values.country_name;
          if (checkValidation(crop, "card")) {
            crop.isValidated = true;
          } else {
            crop.isValidated = false;
          }
        }
        return crop;
      })
    );
    setEdit(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={priceSubmissionSchema}
    >
      {({ values, errors, touched, setFieldTouched }) => (
        <div className="d-flex-column justify-content-center align-items-center crop-edit-wrapper pb-5">
          {regionSelect ? (
            <RegionEdit
              is_mobile_edit={true}
              setRegionSelect={setRegionSelect}
              crops={crops}
              setCrops={setCrops}
              cropData={cropData}
            />
          ) : (
            <Form>
              <div className="d-flex justify-content-between align-items-start">
                <div className="d-flex-column justify-content-start align-items-center mx-5 my-5">
                  <div className="crop-edit-main-title">{cropData.name}</div>
                  <div className="crop-edit-secondary-title">
                    {cropData.variety}
                  </div>
                  <div className="crop-edit-secondary-title">
                    {cropData.grade}
                  </div>
                  <div className="crop-edit-low-title">
                    {cropData.package_size.quantity +
                      " " +
                      cropData.package_size.measure}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-around align-items-center">
                <div className="d-flex-column justify-content-center align-items-center text-center">
                  <MappedNakedInput
                    id="low_price"
                    name="low_price"
                    values={values}
                    customInputProps={{
                      style: {
                        width: `${
                          values.low_price.length
                            ? values.low_price.length
                            : 3.8
                        }ch`
                      }
                    }}
                    startAdornment={<span>$</span>}
                    useStyles={useStyles}
                  />
                  <div className="crop-edit-price-title">
                    Low
                    {errors.low_price && touched.low_price && (
                      <Tooltip
                        title={
                          <p
                            style={{
                              fontSize: "10px",
                              margin: 0
                            }}
                          >
                            {errors.low_price}
                          </p>
                        }
                        placement="top"
                      >
                        <ErrorIcon
                          style={{
                            color: "red",
                            fontSize: "20px",
                            marginLeft: "5px"
                          }}
                        />
                      </Tooltip>
                    )}
                  </div>
                </div>
                <div className="d-flex-column justify-content-center align-items-center text-center">
                  <MappedNakedInput
                    id="high_price"
                    name="high_price"
                    values={values}
                    customInputProps={{
                      style: {
                        width: `${
                          values.high_price.length
                            ? values.high_price.length
                            : 3.8
                        }ch`
                      }
                    }}
                    startAdornment={<span>$</span>}
                    useStyles={useStyles}
                  />
                  <div className="crop-edit-price-title text-center">
                    High
                    {errors.high_price && touched.high_price && (
                      <Tooltip
                        title={
                          <p
                            style={{
                              fontSize: "10px",
                              margin: 0
                            }}
                          >
                            {errors.high_price}
                          </p>
                        }
                        placement="top"
                      >
                        <ErrorIcon
                          style={{
                            color: "red",
                            fontSize: "20px",
                            marginLeft: "5px"
                          }}
                        />
                      </Tooltip>
                    )}
                  </div>
                </div>
              </div>
              <div className="container mt-5">
                <section className="d-flex justify-content-between align-items-center mb-5">
                  <div>
                    <span className="crop-edit-selector-text">
                      State
                      {values.state_name === "" ||
                        (values.region_name === "" && (
                          <Tooltip
                            title={
                              <p
                                style={{
                                  fontSize: "10px",
                                  margin: 0
                                }}
                              >
                                {errors.state_name}
                              </p>
                            }
                            placement="right"
                          >
                            <ErrorIcon
                              style={{
                                color: "red",
                                fontSize: "20px",
                                marginLeft: "5px"
                              }}
                            />
                          </Tooltip>
                        ))}
                    </span>
                    <div className="d-flex justify-content-center align-items-center">
                      <button
                        className="crop-edit-selector d-flex justify-content-center align-items-center"
                        onClick={() => {
                          setRegionSelect(true);
                          setFieldTouched("state_name", true);
                        }}
                        tabIndex="-1"
                      >
                        {values.state_name !== "" &&
                        values.region_name !== "" ? (
                          <>
                            {values.state_name}
                            <br />
                            {values.region_name}
                          </>
                        ) : (
                          "Select State"
                        )}
                      </button>
                    </div>
                  </div>
                  <div>
                    <div className="crop-edit-selector-text">
                      Best Price{" "}
                      {errors.best_price && touched.best_price && (
                        <Tooltip
                          title={
                            <p
                              style={{
                                fontSize: "10px",
                                margin: 0
                              }}
                            >
                              {errors.best_price}
                            </p>
                          }
                          placement="top"
                        >
                          <ErrorIcon
                            style={{
                              color: "red",
                              fontSize: "20px",
                              marginLeft: "5px"
                            }}
                          />
                        </Tooltip>
                      )}
                    </div>
                    <MappedNakedInput
                      id="best_price"
                      name="best_price"
                      placeholder="Optional"
                      customClasses="crop-edit-best-input"
                      customInputProps={{
                        style: { textAlign: "center", fontSize: "1.1rem" }
                      }}
                      useStyles={makeStyles({})}
                      startAdornment={
                        values.best_price ? (
                          <span className="pl-2 text-center">$</span>
                        ) : (
                          ""
                        )
                      }
                    />
                  </div>
                </section>
                <div className="d-flex-column justify-content-center align-items-center mb-5">
                  <Selector
                    labels={supplyOptions}
                    errors={errors.supply}
                    value={values.supply}
                    name="supply"
                    title="Supply"
                    disabled={false}
                    classes={"selector-item-edit"}
                  />
                </div>
                <div className="mb-5">
                  <Selector
                    labels={demandOptions}
                    errors={errors.demand}
                    value={values.demand}
                    name="demand"
                    title="Demand"
                    disabled={false}
                    classes={"selector-item-edit"}
                  />
                </div>
                <div className="mb-5">
                  <Selector
                    labels={carryOverOptions}
                    value={values.carryover}
                    disabled={false}
                    title="Carryover"
                    errors={errors.carryover}
                    name="carryover"
                    classes={"selector-item-edit"}
                  />
                </div>
                <div className="mb-5">
                  <Selector
                    labels={qualityOptions}
                    value={values.quality}
                    title="Quality"
                    errors={errors.quality}
                    name="quality"
                    disabled={false}
                    classes={"selector-item-edit"}
                  />
                </div>
              </div>
              <div className="mt-10 d-flex justify-content-around align-items-center">
                <ListButton
                  label="Back"
                  size="large"
                  text="#6BC200"
                  background="transparent"
                  textTransform="capitalize"
                  fontWeight="400"
                  className="py-4 crop-edit-button"
                  onClick={() => setEdit(false)}
                />
                <ListButton
                  label="Save"
                  size="large"
                  text="white"
                  type="submit"
                  background="#6BC200"
                  textTransform="capitalize"
                  className="py-4 crop-edit-button"
                  fontWeight="400"
                />
              </div>
            </Form>
          )}
        </div>
      )}
    </Formik>
  );
};
