/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Formik } from "formik";
import { ListButton } from "../../../../../ui/components/ListButton";
import { SubscriberDetailsSchema } from "../../../../../helpers/schemas";
import { states, stepperInputStyle } from "../constants";
import { MappedInput } from "../../../../../ui/components/Input/index";
import { MappedCheckbox } from "../../../../../ui/components/Checkbox";
import { useFetch } from "../../../../../hooks/fetch.hook";
import { MappedMaskedInput } from "@bit/the-glue.frontendcomponents.masked-input";
import { abnLookup } from "../../../Organisations/_api";
import { CircularProgress } from "@material-ui/core";
import { warning } from "../../../../../helpers/toasts";
import { MappedSelect } from "@bit/the-glue.frontendcomponents.select";

export const PersonalDetails = ({ handleNext, payload, setPayload }) => {
  const { request } = useFetch();

  const [abnLoading, setAbnloading] = useState(false);
  const [validABN, setValidABN] = useState(payload.abn !== "" ? true : false);

  const initialValues = {
    first_name: payload.first_name,
    last_name: payload.last_name,
    email: payload.email,
    phone: payload.phone,
    company_name: payload.company_name,
    company_abn: payload.company_abn,
    is_company: payload.is_company,
    postcode: payload.postcode,
    address: payload.address,
    state: payload.state,
    city: payload.city
  };

  const handleSubmit = values => {
    if (values.is_company && validABN === false) {
      warning(
        "You must provide a valid ABN is you would like to sign up as a company!"
      );
      return;
    }
    setPayload(prev => ({
      ...prev,
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      phone: values.phone,
      company_name: values.company_name,
      company_abn: values.company_abn,
      postcode: values.postcode,
      address: values.address,
      state: values.state,
      is_company: values.is_company,
      city: values.city
    }));
    handleNext();
  };

  const handleABN = (abn, setFieldValue) => {
    if (abn.includes("_")) return;
    setAbnloading(true);
    request(abnLookup, abn)
      .then(response => {
        if (!response) {
          warning(
            "Invalid ABN, please check that you have provided a valid ABN."
          );
          setValidABN(false);
          return;
        }
        setFieldValue("postcode", response.postal_postcode);
        setFieldValue("state", response.postal_state);
        setFieldValue("company_name", response.legal_name);
        setValidABN(true);
      })
      .finally(() => setAbnloading(false));
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={SubscriberDetailsSchema}
    >
      {({ handleSubmit, values, setFieldValue }) => (
        <section className="row justify-content-around align-items-start">
          <div className="w-50 col-12 col-md-6 my-5">
            <h1 className="stepper-title text-capitalize my-5">
              Please enter your personal details
            </h1>
            <p className="stepper-description text-start">
              To get started, please tell us who will receive your reports. If
              you want a tax invoice for your company please provide your
              company ABN. Some of the fields will be automatically completed
              for you.
            </p>
          </div>
          <div className="col-12 col-md-6">
            <div className="ml-5 mt-10 mb-5">
              <h4>Subscriber details</h4>
            </div>
            <div>
              {values.is_company ? (
                <div className="row">
                  <div className="col-sm-12 col-md-6 my-2">
                    <MappedMaskedInput
                      label="ABN"
                      name="company_abn"
                      mask="99 999 999 999"
                      color="#6BC200"
                      variant="filled"
                      onBlur={e => handleABN(e.target.value, setFieldValue)}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 my-2">
                    <MappedInput
                      inputWrapperStyle={stepperInputStyle}
                      label="Company Name"
                      name="company_name"
                      color="#6BC200"
                      readOnly
                    />
                  </div>
                </div>
              ) : (
                <>
                  <div className="row">
                    <div className="col-sm-12 col-md-6 my-2">
                      <MappedInput
                        inputWrapperStyle={stepperInputStyle}
                        label="First Name"
                        name="first_name"
                        color="#6BC200"
                      />
                    </div>
                    <div className="col-sm-12 col-md-6 my-2">
                      <MappedInput
                        inputWrapperStyle={stepperInputStyle}
                        label="Last Name"
                        name="last_name"
                        color="#6BC200"
                      />
                    </div>
                  </div>
                </>
              )}
              <div className="row">
                <div className="col-sm-12 col-md-6 my-2">
                  <MappedInput
                    inputWrapperStyle={stepperInputStyle}
                    label="Email"
                    name="email"
                    color="#6BC200"
                  />
                </div>
                <div className="col-sm-12 col-md-6 my-2">
                  <MappedInput
                    inputWrapperStyle={stepperInputStyle}
                    label="Phone"
                    name="phone"
                    color="#6BC200"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <MappedInput
                    inputWrapperStyle={stepperInputStyle}
                    label="Address"
                    name="address"
                    color="#6BC200"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-4 my-2">
                  <MappedInput
                    inputWrapperStyle={stepperInputStyle}
                    label="Postcode"
                    name="postcode"
                    color="#6BC200"
                  />
                </div>
                <div className="col-sm-12 col-md-4 my-2">
                  <MappedSelect
                    label="State"
                    name="state"
                    value={values.state}
                    options={states}
                    color="#6BC200"
                  />
                </div>
                <div className="col-sm-12 col-md-4 my-2">
                  <MappedInput
                    inputWrapperStyle={stepperInputStyle}
                    label="City"
                    name="city"
                    color="#6BC200"
                  />
                </div>
              </div>

              <div className="d-flex justify-content-start align-items-center">
                <MappedCheckbox name="is_company" checked={values.is_company} />
                <p className="m-0">I am signing up as a company</p>
              </div>
              <div className="mt-10 mb-5 d-flex justify-content-end align-items-center">
                {abnLoading ? (
                  <div className="d-flex justify-content-around justify-content-sm-end align-items-center my-5">
                    <p className="my-0 mr-2 stepper-description">
                      Checking ABN...
                    </p>
                    <CircularProgress />
                  </div>
                ) : (
                  <ListButton
                    label="Next"
                    size="large"
                    onClick={handleSubmit}
                    boxShadow={false}
                  />
                )}
              </div>
            </div>
          </div>
        </section>
      )}
    </Formik>
  );
};
