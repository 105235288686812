import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import SearchBar from "@bit/the-glue.frontendcomponents.search-bar";
import { getSelectedId, handleSearch } from "../../../../ui/helpers";
import { HEADINGS, searchMap } from "../constants";
import { removeUser, setUsersList, updateUserList } from "../_redux/actions";
import { modifyUserData, modifyUsers, renderUsers } from "../helpers";
import { useFetch } from "../../../../hooks/fetch.hook";
import { archiveUser, getUserById, getUsers, reactivateUser } from "../_api";
import { ListButton } from "../../../../ui/components/ListButton";
import { ListHeader } from "../../../../ui/structures/ListHeader";
import { UnarchiveButton } from "../../../../ui/components/UnarchiveButton";
import { ArchiveCheckbox } from "../../../../ui/components/ArchiveCheckbox";
import { ConfirmDelete } from "../../../../ui/components/ConfirmDelete";
import { Modal } from "../../../../ui/components/Modal";
import { TableSortLabel } from "@material-ui/core";
import { sortHeaderStyle } from "../../../constants";
import { info } from "../../../../helpers/toasts";
import { Loader } from "../../../../ui/components/Loader";

export const List = () => {
  const dispatch = useDispatch();
  const { request } = useFetch();

  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState({});
  const [actionLoading, setActionLoading] = useState(false);
  const [focused, setFocused] = useState(false);
  const [initialData, setInitialData] = useState([]);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [archivedLoading, setArchivedLoading] = useState(false);
  const [archived, setArchived] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [unArchiving, setUnarchiving] = useState(false);
  const [field, setField] = useState("");
  const [direction, setDirection] = useState("");

  const handleModalClose = () => setConfirmDeleteOpen(false);

  const data = useSelector(({ users: { usersList } }) => usersList);

  const loadUsers = () => {
    setLoading(true);
    request(getUsers, search, archived, field, field && direction)
      .then(data => {
        if (data) {
          dispatch(setUsersList(modifyUsers(data)));
          setInitialData(modifyUsers(data));
        }
      })
      .finally(() => {
        setArchivedLoading(false);
        setLoading(false);
      });
  };

  useEffect(() => {
    loadUsers();
    // eslint-disable-next-line
  }, [search, archived, field, direction]);

  const unArchiveDisabled = useMemo(
    () => Object.values(selected).filter(Boolean).length !== 1,
    [selected]
  );

  const handleArchive = id => {
    setConfirmDeleteOpen(false);
    setActionLoading(true);
    request(archiveUser, id)
      .then(data => {
        if (!data) return;
        if (archived) {
          request(getUserById, id).then(
            data => data && dispatch(updateUserList(modifyUserData(data)))
          );
        } else dispatch(removeUser(id));
        info("User has been archived!");
        setSelected({});
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  const handleUnarchive = () => {
    const userID = Number(getSelectedId(selected));
    const status = (data.find(({ id }) => id === userID) || {}).status;
    if (status !== "archived") return;
    setUnarchiving(true);
    request(reactivateUser, userID)
      .then(data => {
        if (!data) return;
        dispatch(updateUserList(modifyUserData(data)));
        setSelected({});
        info("User has been unarchived!");
      })
      .finally(() => {
        setUnarchiving(false);
      });
  };

  const KEY_MAP = {
    Name: data.map(item => [item.first_name, item.last_name].join(" "))
  };

  const renderButtons = () => (
    <>
      {archived && (
        <UnarchiveButton
          handleClick={handleUnarchive}
          disabled={unArchiveDisabled}
          loading={unArchiving}
        />
      )}
      <div className="mr-10">
        <Link
          to={{
            pathname: `/add-user`
          }}
          data-testid="new"
        >
          <ListButton
            label="New"
            size="small"
            boxShadow={false}
            background="rgba(107, 194, 0, 0.3)"
            text="#6BC200"
          />
        </Link>
      </div>
    </>
  );

  const showArchived = () => {
    setArchivedLoading(true);
    setArchived(!archived);
  };

  const handleOpenModal = id => {
    setConfirmDeleteOpen(true);
    setSelectedItem(id);
  };

  const resetSearch = () => {
    dispatch(setUsersList(initialData));
    setSearch("");
  };
  const handleFocus = () => setFocused(true);

  const isSortable = key => ["first_name", "id", "status"].includes(key);

  function renderHeaderWithSorting(headings) {
    return headings.map(([key, header]) => {
      if (isSortable(key)) {
        return (
          <th
            key={key}
            style={sortHeaderStyle}
            className="px-5 text-center"
            onClick={() => {
              setDirection(
                key !== field ? "desc" : direction === "desc" ? "asc" : "desc"
              );
              setField(key);
            }}
          >
            <span style={{ cursor: "pointer" }}>{header}</span>
            {key === field && <TableSortLabel active direction={direction} />}
          </th>
        );
      } else
        return (
          <th key={key} style={sortHeaderStyle} className="px-5 text-center">
            <span>{header}</span>
          </th>
        );
    });
  }

  return (
    <>
      {actionLoading && <Loader title="Loading..." isOpen={actionLoading} />}
      {confirmDeleteOpen && (
        <Modal
          isOpen={confirmDeleteOpen}
          submitable
          onClose={handleModalClose}
          maxWidth="sm"
          modalContent={
            <ConfirmDelete
              handleClose={handleModalClose}
              handleSubmit={handleArchive}
              id={selectedItem}
              name={
                (data.find(({ id }) => id === selectedItem) || {}).first_name
              }
            />
          }
        />
      )}
      <div className="row justify-content-center mt-10">
        <div className="col-12">
          <div
            className="bg-white rounded py-7 px-10"
            style={{ border: "1px solid #E5E5E5", borderRadius: "4px" }}
          >
            <ListHeader
              title="Users"
              renderButtons={renderButtons}
              handleFocus={handleFocus}
            />
            <div>
              <SearchBar
                onSearch={data => handleSearch(data, searchMap, setSearch)}
                clearSearch={resetSearch}
                keyMap={KEY_MAP}
                placeholder="Filter Users..."
                elevation={2}
                chipBackgroundColor="rgba(107, 194, 0, 0.3)"
                chipColor="#6BC200"
                focused={focused}
                setFocused={setFocused}
              />
            </div>
            <ArchiveCheckbox
              archivedLoading={archivedLoading}
              archived={archived}
              showArchived={showArchived}
            />
            <Datagrid
              data={data}
              headings={HEADINGS}
              renderRow={renderUsers}
              selected={selected}
              setSelected={setSelected}
              renderHeaderWithSorting={renderHeaderWithSorting}
              editable
              loading={loading}
              selectable
              deletable
              handleDelete={handleOpenModal}
              link="users"
            />
          </div>
        </div>
      </div>
    </>
  );
};
