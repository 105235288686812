import React from "react";
import { getStatusRowStyle, isStatus } from "../../../../ui/helpers";

export const renderSubscribers = (headings, item) => {
  return headings.map(([key]) => {
    return (
      <td
        key={key}
        className={` no-line border-0 px-5 py-5 text-center ${isStatus(key) &&
          getStatusRowStyle(item)}`}
      >
        {key === "status" && !isValidStatus(item[key]) ? (
          <>
            <span>active until:</span>
            <br />
            {item[key]}
          </>
        ) : (
          item[key]
        )}
      </td>
    );
  });
};

export const isValidStatus = status =>
  [
    "active",
    "inactive",
    "new",
    "past_due",
    "unpaid",
    "canceled",
    "incomplete",
    "incomplete_expired",
    "expired",
    "trialing",
    "paused"
  ].includes(status);

export const filterSearchValue = searchValue => {
  if (!searchValue || !searchValue.includes("status")) return searchValue;

  const statusFilter = searchValue
    .split("&")
    .find(item => item.includes("status"));

  const filters =
    "&" +
    searchValue
      .split("&")
      .filter(item => !item.includes("status"))
      .join("&");

  if (statusFilter.includes("active") && !statusFilter.includes("inactive")) {
    return "status=active" + filters;
  } else if (statusFilter.includes("inactive")) {
    return "status=inactive" + filters;
  } else if (statusFilter.includes("canceled")) {
    return "status=canceled" + filters;
  } else if (statusFilter.includes("archived")) {
    return "status=archived" + filters;
  }
};

export const isSortable = key => ["name", "id", "status"].includes(key);

export const generateSubscriberPayload = (values, cardData) => {
  return {
    ...cardData,
    name: values.name,
    email: values.email,
    phone: values.phone,
    line1: values.address,
    postal_code: values.postcode,
    city: values.city,
    state: values.state
  };
};

export const isActiveStatus = status => ["active", "paused"].includes(status);

export const getStatusColor = status => {
  if (status === "active") return "text-primary";
  if (status === "inactive" || status === "archived") return "text-danger";
  if (status === "canceled") return "text-warning";
  if (status === "paused") return "text-muted";
  return "text-primary";
};

export const validateSettings = (settings, status) => {
  if (
    settings.discount &&
    !settings.discount.percent_off &&
    !settings.discount.terminated
  ) {
    return "You must set the percentage if you apply a discount!";
  }
  if (
    settings.discount &&
    settings.discount.duration === "repeating" &&
    !settings.discount.percent_off &&
    !settings.discount.terminated
  ) {
    return "You must set the percentage and the number of months if you apply a recurring discount!";
  }
  if (status !== "active") {
    return `You can only edit a subscription, that is still active.`;
  }
};

export const isNotEditableStatus = status =>
  [
    "inactive",
    "new",
    "past_due",
    "unpaid",
    "canceled",
    "incomplete",
    "incomplete_expired",
    "expired",
    "trialing",
    "paused"
  ].includes(status);
