import React from "react";
import { Switch, Redirect } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { ContentRoute } from "../../../../_metronic/layout";
import Login from "./Login";
import "../../../../_metronic/_assets/sass/pages/login/login-3.scss";
import { useHtmlClassService } from "../../../../_metronic/layout/_core/MetronicLayout";

export function AuthPage() {
  const uiService = useHtmlClassService();

  return (
    <>
      <div
        className="d-flex flex-column flex-root login-background"
        style={{
          backgroundImage: `linear-gradient(rgba(255, 255, 255, .5), rgba(113, 191, 68, .4)), url(${toAbsoluteUrl(
            uiService.getLoginBackground()
          )})`
        }}
      >
        <div className="d-flex flex-column-fluid flex-center">
          <Switch>
            <ContentRoute path="/auth/login" component={Login} />
            <Redirect from="/auth" exact={true} to="/auth/login" />
            <Redirect to="/auth/login" />
          </Switch>
        </div>
      </div>
    </>
  );
}
