/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useCallback } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";
import { ITEM_TYPES, MENU } from "./constants";
import "./AsideColorFix.css";

export function AsideMenuList({ layoutProps }) {
  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {MENU.map((item, index) => (
          <MenuItem key={index} item={item} />
        ))}
      </ul>
    </>
  );
}

function MenuItem({ item, subMenu }) {
  const location = useLocation();
  const getMenuItemActive = useCallback(
    url => {
      return checkIsActive(location, url)
        ? "menu-item-active menu-item-open mx-3 mt-1"
        : "mt-1";
    },
    [location]
  );

  const getMenuItemTextActive = useCallback(
    url => {
      return checkIsActive(location, url) ? "text-active " : "";
    },
    [location]
  );

  const getMenuBackgroundActive = useCallback(
    url => {
      return checkIsActive(location, url) ? "bg-active" : "";
    },
    [location]
  );

  const { type } = item;
  switch (type) {
    case ITEM_TYPES.link:
      return (
        <li className={`menu-item mx-3 ${getMenuItemActive(item.to)}`}>
          <NavLink
            className={`menu-link aside-menu-item${getMenuBackgroundActive(
              item.to
            )}`}
            to={item.to}
          >
            {Boolean(subMenu) && (
              <i className="menu-bullet menu-bullet-dot">
                <span style={{ backgroundColor: "#6BC200" }} />
              </i>
            )}
            {Boolean(item.icon) && (
              <span className="svg-icon menu-icon">{item.icon}</span>
            )}
            <span className={`menu-text ${getMenuItemTextActive(item.to)}`}>
              {item.title}
            </span>
          </NavLink>
        </li>
      );
    case ITEM_TYPES.heading:
      return (
        <li className="menu-item mx-3" style={{ pointerEvents: "none" }}>
          <div className="menu-link">
            <span
              className="menu-text font-weight-bold"
              style={{ color: "#464E5F", cursor: "default" }}
            >
              {item.title}
            </span>
          </div>
        </li>
      );
    case ITEM_TYPES.menu:
      return (
        <li
          className={`menu-item menu-item-submenu mx-3 ${getMenuItemActive(
            item.to
          )}`}
          data-menu-toggle="hover"
        >
          <NavLink
            className={`menu-link menu-toggle aside-menu-item${getMenuBackgroundActive(
              item.to
            )}`}
            to={item.to}
          >
            <span className="svg-icon menu-icon">{item.icon}</span>
            <span className={`menu-text ${getMenuItemTextActive(item.to)}`}>
              {item.title}
            </span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent">
                <span className="menu-link">
                  <span
                    className={`menu-text ${getMenuItemTextActive(item.to)}`}
                  >
                    {item.title}
                  </span>
                </span>
              </li>
              {item.items.map((subItem, index) => (
                <MenuItem key={index} item={subItem} subMenu />
              ))}
            </ul>
          </div>
        </li>
      );
    default:
      return null;
  }
}
