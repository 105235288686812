import React from "react";
import { Dialog, DialogContent } from "@material-ui/core";
import { ListButton } from "../../../ui/components/ListButton";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

export const WelcomeOverlay = ({
  onClose,
  isOpen,
  maxWidth = "md",
  ...other
}) => {
  return (
    <Dialog
      onClose={onClose}
      open={isOpen}
      fullWidth={true}
      maxWidth={maxWidth}
      {...other}
    >
      <DialogContent>
        <div className="welcome-overlay">
          <img
            className="img-fluid"
            src={toAbsoluteUrl("/assets/icons/Logo.svg")}
            alt="Logo"
          />
          <h2 className="">Welcome!</h2>
          <div className="welcome-overlay-description">
            The team at Datafresh are happy to welcome you to our new
            application. <br /> We're sure you'll love the new application but
            if you have questions you can get in touch by phone
            <span className="text-primary"> (03) 9408 6627</span> or email{" "}
            <span className="text-primary">datafresh@freshstate.com.au</span>
          </div>
          <ListButton label="OK" size="large" onClick={onClose} />
        </div>
      </DialogContent>
    </Dialog>
  );
};
