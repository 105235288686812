import config from "../../../../../config";

export const addTag = data =>
  fetch(`${config.ApiUrl.Rest}/tags`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include"
  });

export const getTags = (search = "", archived = "", field, direction) =>
  fetch(
    `${config.ApiUrl.Rest}/tags?${search && "&" + search}${(archived &&
      "&status=all") ||
      ""}${(field && "&order_by=" + field) || ""}${(direction &&
      "&order_dir=" + direction) ||
      ""}`,
    {
      mode: "cors",
      credentials: "include"
    }
  );

export const getCropTags = (search = "", archived = "", field, direction) =>
  fetch(
    `${config.ApiUrl.Rest}/tags?&type=_like(Crop)_${search &&
      "&" + search}${(archived && "&status=all") || ""}${(field &&
      "&order_by=" + field) ||
      ""}${(direction && "&order_dir=" + direction) || ""}`,
    {
      mode: "cors",
      credentials: "include"
    }
  );

export const getSpecificCropTags = crop_id =>
  fetch(`${config.ApiUrl.Rest}/crops/${crop_id}/tags`, {
    mode: "cors",
    credentials: "include"
  });

export const getTagById = tag_id =>
  fetch(`${config.ApiUrl.Rest}/tags/${tag_id}`, {
    mode: "cors",
    credentials: "include"
  });

export const modifyTag = (data, tag_id) =>
  fetch(`${config.ApiUrl.Rest}/tags/${tag_id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include"
  });

export const deleteTag = tag_id =>
  fetch(`${config.ApiUrl.Rest}/tags/${tag_id}`, {
    method: "DELETE",
    mode: "cors",
    credentials: "include"
  });

export const getTagsListAutocomplete = (
  page_size = 80,
  autocompleteField,
  autocompleteText = "",
  search = ""
) =>
  fetch(
    `${config.ApiUrl.Rest}/tags?&page_size=${page_size}${search &&
      "&" + search}$${(autocompleteField &&
      "&distinct=" +
        autocompleteField +
        "&" +
        autocompleteField +
        "=_like(" +
        autocompleteText +
        ")_") ||
      ""}`,
    {
      mode: "cors",
      credentials: "include"
    }
  );
