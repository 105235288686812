import config from "../../../../../config";

export const getOrganisationCrops = (org_id, search = "", field, direction) =>
  fetch(
    `${config.ApiUrl.Rest}/organisations/${org_id}/crops?${search &&
      "&" + search}${(field && "&order_by=" + field) || ""}${(direction &&
      "&order_dir=" + direction) ||
      ""}`,
    {
      mode: "cors",
      credentials: "include"
    }
  );

export const filterSubCategories = (search = "") =>
  fetch(`${config.ApiUrl.Rest}/sub_categories?category_id=${search}`, {
    mode: "cors",
    credentials: "include"
  });

export const getContactCrops = (contact_id, search = "", field, direction) =>
  fetch(
    `${config.ApiUrl.Rest}/contacts/${contact_id}/crops?${search &&
      "&" + search}${(field && "&order_by=" + field) || ""}${(direction &&
      "&order_dir=" + direction) ||
      ""}`,
    {
      mode: "cors",
      credentials: "include"
    }
  );

export const getCrops = (search = "", field, direction) =>
  fetch(
    `${config.ApiUrl.Rest}/crops?${search && "&" + search}${(field &&
      "&order_by=" + field) ||
      ""}${(direction && "&order_dir=" + direction) || ""}`,
    {
      mode: "cors",
      credentials: "include"
    }
  );

export const addCrop = data =>
  fetch(`${config.ApiUrl.Rest}/crops`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include"
  });

export const getCropById = crop_id =>
  fetch(`${config.ApiUrl.Rest}/crops/${crop_id}`, {
    mode: "cors",
    credentials: "include"
  });

export const modifyCrop = (data, crop_id) =>
  fetch(`${config.ApiUrl.Rest}/crops/${crop_id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include"
  });

export const archiveCrop = crop_id =>
  fetch(`${config.ApiUrl.Rest}/crops/${crop_id}`, {
    method: "DELETE",
    mode: "cors",
    credentials: "include"
  });

export const editCropTags = (crop_id, data) =>
  fetch(`${config.ApiUrl.Rest}/crops/${crop_id}/tags`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include"
  });

export const deleteCropTags = (crop_id, data) =>
  fetch(`${config.ApiUrl.Rest}/crops/${crop_id}/tags`, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include"
  });

export const getAllContacts = () =>
  fetch(`${config.ApiUrl.Rest}/contacts`, {
    method: "GET",
    mode: "cors",
    credentials: "include"
  });

export const getContact = crop_id =>
  fetch(`${config.ApiUrl.Rest}/crops/${crop_id}/contacts`, {
    method: "GET",
    mode: "cors",
    credentials: "include"
  });

export const updateCropContact = (crop_id, payload) =>
  fetch(`${config.ApiUrl.Rest}/crops/${crop_id}/contacts`, {
    headers: { "Content-Type": "application/json" },
    method: "POST",
    body: JSON.stringify(payload),
    mode: "cors",
    credentials: "include"
  });

export const getPackageSizes = name =>
  fetch(`${config.ApiUrl.Rest}/package_sizes${name ? `?name=${name}` : ""}`, {
    method: "GET",
    mode: "cors",
    credentials: "include"
  });

export const savePackageSizes = (crop_id, data) =>
  fetch(`${config.ApiUrl.Rest}/crops/${crop_id}/package_sizes`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include"
  });

export const getStates = () =>
  fetch(`${config.ApiUrl.Rest}/countries-with-states`, {
    method: "GET",
    mode: "cors",
    credentials: "include"
  });
