import moment from "moment";

export const generateDates = (data) => {
  const dates = [];
  data.forEach((element) => {
    const date = moment(
      new Date(element.year, element.month - 1, element.day),
      "dd/MM/yyyy"
    );
    dates.push(date);
  });
  return dates;
};
