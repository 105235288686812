import React, { useState } from "react";
import { ListButton } from "../../../../../ui/components/ListButton";
import { StripeModal } from "../../../../../ui/structures/StripeModal";
import config from "../../../../../config";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe(config.Stripe.clientKey);

export const Payment = ({ handleBack, payload }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const stripeOptions = {
    clientSecret: payload.client_secret,
    appearance: {
      theme: "stripe",
      variables: {
        colorPrimary: "#0570de",
        colorBackground: "#fff",
        colorText: "#30313d",
        colorDanger: "#df1b41",
        borderRadius: "14px"
      }
    }
  };

  const handleModalClose = () => setModalOpen(!modalOpen);
  const startPayment = () => setModalOpen(true);

  return (
    <>
      <Elements stripe={stripePromise} options={stripeOptions}>
        <StripeModal
          open={modalOpen}
          handleModalClose={handleModalClose}
          subscriptionPrice={payload.pro_ration}
        />
      </Elements>
      <section className="row justify-content-around align-items-start">
        <div className="w-50 col-12 my-5">
          <h1 className="stepper-title text-capitalize my-5">Plan Cost</h1>
          <p className="stepper-description text-start">
            Based on the type and frequency of reports you've selected your
            monthly cost will be ${payload.monthly_subscription_fee.toFixed(2)}
            <br />
            <br />
            Given we're only part way through the month your initial charge will
            be ${payload.pro_ration.toFixed(2)}
            <br />
            <br />
            Our recurring payments are securely handled by Stripe. You'll be
            asked for your credit card or direct debit details on the next
            screen.
          </p>
        </div>
      </section>
      <div className="mt-10 pb-5 d-flex justify-content-between align-items-center w-full">
        <ListButton
          label="Back"
          variant="outlined"
          borderColor="#fff"
          size="large"
          onClick={handleBack}
          boxShadow={false}
        />
        <ListButton
          label="Pay now"
          size="large"
          onClick={startPayment}
          boxShadow={false}
        />
      </div>
    </>
  );
};
