import config from "../../../../config";

export const getUser = () =>
  fetch(`${config.ApiUrl.Rest}/users/me`, {
    mode: "cors",
    credentials: "include"
  });

export const modifyCurrentUser = userData =>
  fetch(`${config.ApiUrl.Rest}/users/me`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(userData),
    mode: "cors",
    credentials: "include"
  });

export const DBWakeUp = () =>
  fetch(`${config.ApiUrl.Rest}/wake-up`, {
    mode: "cors",
    credentials: "include"
  });

export const getBackendVersion = () =>
  fetch(`${config.ApiUrl.Rest}/version`, {
    mode: "cors",
    credentials: "include"
  });
