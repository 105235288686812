import React from "react";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ListButton } from "../../../../ui/components/ListButton";

export const TradingNameSelect = ({
  onClose,
  handleSubmit,
  setTradingNameValue,
  tradingNamesList = []
}) => {
  const handleChange = (_, value) => {
    setTradingNameValue(value);
  };

  return (
    <>
      <h4 className="mb-10 text-center">
        <strong>Select Business Name</strong>
      </h4>
      <div className="d-flex">
        <div className="w-100">
          <Autocomplete
            options={tradingNamesList}
            id="2"
            getOptionLabel={({ label }) => label}
            renderInput={params => (
              <TextField
                {...params}
                label="Select Business Name..."
                variant="outlined"
                color="secondary"
                data-testid="edit-name"
              />
            )}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="my-5 d-flex justify-content-end mr-4">
        <div className="mr-3">
          <ListButton
            label="Cancel"
            size="large"
            onClick={onClose}
            data-testid="cancel"
            boxShadow={false}
          />
        </div>
        <div>
          <ListButton
            label="Submit"
            size="large"
            onClick={handleSubmit}
            boxShadow={false}
            data-testid="submit"
          />
        </div>
      </div>
    </>
  );
};
