import React from "react";
import { ListButton } from "../../../../../ui/components/ListButton";
import { ReportSettings } from "../../../../../ui/components/ReportSettings";
import { getPayload, validatePlanStep } from "../helpers";
import { error, warning } from "../../../../../helpers/toasts";
import { useFetch } from "../../../../../hooks/fetch.hook";
import { getClientSecretID } from "../_api";
import { useState } from "react";
import { CircularProgress } from "@material-ui/core";

export const PlanDetails = ({
  handleNext,
  handleBack,
  payload,
  setPayload
}) => {
  const [loading, setLoading] = useState(false);
  const { request } = useFetch();

  const handleSubmit = () => {
    if (validatePlanStep(payload)) {
      setLoading(true);
      request(getClientSecretID, getPayload(payload))
        .then(response => {
          if (!response || !response.client_secret) {
            warning(
              "Something has happened while processing your data, please check that you provided all details, or try again later!"
            );
            return;
          } else {
            setPayload(prev => ({
              ...prev,
              client_secret: response.client_secret,
              monthly_subscription_fee: response.monthly_subscription_fee / 100,
              pro_ration: response.pro_ration / 100
            }));
            handleNext();
          }
        })
        .finally(() => setLoading(false));
    } else {
      error(
        "Please make sure that you select the report format and at least one day and one category, or monthly report option to proceed."
      );
    }
  };
  return (
    <>
      <section className="row justify-content-evenly align-items-start">
        <div className="w-50 col-12 col-lg-6 my-5">
          <h1 className="stepper-title text-capitalize my-5">
            Select Your Report Preferences
          </h1>
          <p className="stepper-description text-start">
            Our pricing is based on tne number of categories and frequency of
            reporting Please select your preferred format (CSV or PDF) and the
            days and categories you would like to receive.
            <br />
            <br />
            We can also provide an optional monthly summary report if you simply
            require this for statistical purposes.
          </p>
        </div>
        <ReportSettings payload={payload} setPayload={setPayload} />
      </section>

      {loading ? (
        <div className="d-flex justify-content-around justify-content-sm-end align-items-center my-5">
          <p className="my-0 mr-2 stepper-description">
            Processing your data...
          </p>
          <CircularProgress />
        </div>
      ) : (
        <div className="mt-10 pb-5 d-flex justify-content-between align-items-center w-full">
          <ListButton
            label="Back"
            variant="outlined"
            borderColor="#fff"
            size="large"
            onClick={handleBack}
            boxShadow={false}
          />
          <ListButton
            label="Next"
            size="large"
            onClick={handleSubmit}
            boxShadow={false}
          />
        </div>
      )}
    </>
  );
};
