import React, { useState, useEffect } from "react";
import {
  Authenticator,
  SignIn,
  ConfirmSignIn,
  RequireNewPassword,
  TOTPSetup
} from "aws-amplify-react";
import { connect } from "react-redux";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { injectIntl } from "react-intl";
import { useFetch } from "../../../../hooks/fetch.hook";
import * as auth from "../_redux/authRedux";
import { ForgotPassword } from "./ForgotPassword";
import { MyGreetings } from "./MyGreetings";
import { Auth } from "@aws-amplify/auth";
import { I18n } from "@aws-amplify/core";
import "./TotpQrCodeFix.css";
import config from "../../../../config";
import { DBWakeUp, getUser } from "../_api";
import { WarningAlertBar } from "../../../../ui/components/AlertBar";
import { NewPassword } from "./NewPassword";
import { WelcomeOverlay } from "../WelcomeOverlay";
import { authenticatorTheme } from "../constants";
import { errorMap } from "../helpers";

const dict = {
  en: {
    "Forget your password? ": " ",
    "Confirm TOTP Code": "2FA Login Code",
    "Enter your username": "Enter your email",
    AWSCognito: config.CognitoInformation.IssuerName
  }
};

I18n.setLanguage("en");
I18n.putVocabularies(dict);

function Login(props) {
  const [status, setStatus] = useState("");
  const [step, setStep] = useState("signIn");
  const [code, setCode] = useState("");
  const [expiredMessage, setExpiredMessage] = useState(false);
  const [welcomeOpen, setWelcomeOpen] = useState(true);

  const handleWelcomeClose = () => setWelcomeOpen(false);

  const { request } = useFetch();

  const getUrlParams = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get("code");
    return code;
  };

  useEffect(() => {
    DBWakeUp().catch(err => console.error(err));
    setExpiredMessage(Boolean(localStorage.getItem("sessionExpired")));
    const code = getUrlParams();
    if (code) {
      setCode(code);
      setStep("newPassword");
    }
  }, []);

  function handleAuthStateChange(state) {
    setStatus(state);
    if (state === "signedIn") {
      Auth.currentUserInfo().then(userInfo => {
        if (userInfo && userInfo.attributes) {
          // Require check as this bounces on logout
          request(getUser).then(userData => {
            if (userData) {
              const {
                attributes: { email, name }
              } = userInfo;

              const { id, roles, policies, permissions, groups } = userData;

              const setUserData = (integrations = []) => {
                const user = {
                  id: id,
                  username: email,
                  email: email,
                  fullname: name,
                  roles: roles,
                  permissions,
                  groups,
                  policies,
                  sessionStart: new Date(),
                  integrations
                };
                localStorage.setItem("sessionExpired", "");
                setExpiredMessage(false);
                props.fulfillUser(user);
              };
              setUserData();
            }
          });
        }
      });
    }
  }

  const handleResetPassword = () => setStep("resetPassword");

  const stepsMap = {
    signIn: (
      <>
        <div
          className="d-flex justify-content-center align-items-center mt-10"
          style={{ minHeight: "50%" }}
        >
          <div
            className={`bg-white border border-secondary py-10 px-10 rounded col-md-6 col-lg-4`}
          >
            <div className="d-flex justify-content-center login-form login-signin">
              <Authenticator
                theme={authenticatorTheme}
                onStateChange={handleAuthStateChange}
                amplifyConfig={config.Auth}
                errorMessage={errorMap}
                hideDefault={true}
              >
                <SignIn />
                <ConfirmSignIn />
                <RequireNewPassword />
                <TOTPSetup />
                <MyGreetings props={props} />
              </Authenticator>
            </div>
            {status === "signIn" && (
              <div
                style={authenticatorTheme.hint}
                className="mt-5 cursor-pointer"
                onClick={handleResetPassword}
              >
                Forgot Password? Reset Password
              </div>
            )}
          </div>
        </div>
      </>
    ),
    resetPassword: <ForgotPassword MyTheme={authenticatorTheme} />,
    newPassword: <NewPassword MyTheme={authenticatorTheme} code={code} />
  };

  return (
    <>
      <WelcomeOverlay isOpen={welcomeOpen} onClose={handleWelcomeClose} />
      <div className="containter w-100 h-100">
        <div className="p-5 bg-white">
          <span className="mr-5">
            <img
              className="img-fluid"
              src={toAbsoluteUrl("/assets/icons/Logo.svg")}
              alt="Logo"
            />
          </span>
        </div>
        {expiredMessage && (
          <WarningAlertBar message="Your session has expired. Please, reauthenticate." />
        )}
        {!welcomeOpen && stepsMap[step]}
      </div>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
