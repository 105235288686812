import config from "../../../../../config";

export const addSubCategory = data =>
  fetch(`${config.ApiUrl.Rest}/sub_categories`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include"
  });

export const getSubCategories = (search = "", archived, field, direction) =>
  fetch(
    `${config.ApiUrl.Rest}/sub_categories?${search &&
      "&" + search}${(archived && "&status=all") || ""}${(field &&
      "&order_by=" + field) ||
      ""}${(direction && "&order_dir=" + direction) || ""}`,
    {
      mode: "cors",
      credentials: "include"
    }
  );

export const modifySubCategory = (data, category_id) =>
  fetch(`${config.ApiUrl.Rest}/sub_categories/${category_id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include"
  });

export const deleteSubCategory = category_id =>
  fetch(`${config.ApiUrl.Rest}/sub_categories/${category_id}`, {
    method: "DELETE",
    mode: "cors",
    credentials: "include"
  });

export const getSubCategoryById = category_id =>
  fetch(`${config.ApiUrl.Rest}/sub_categories/${category_id}`, {
    mode: "cors",
    credentials: "include"
  });

export const getCategoryList = () =>
  fetch(`${config.ApiUrl.Rest}/categories`, {
    mode: "cors",
    credentials: "include"
  });
