import React from "react";
import { withStyles, LinearProgress } from "@material-ui/core/";
import { Greetings } from "aws-amplify-react";
import {
  NavBar,
  Nav,
  NavRight,
} from "aws-amplify-react/lib/Amplify-UI/Amplify-UI-Components-React";

const LoginLinearProgress = withStyles((theme) => ({
  root: {
    height: 15,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "rgb(240, 240, 240);",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "rgb(54, 153, 255)",
    background: `linear-gradient(90deg, #e68653 0%, #FA5700 100%)`,
  },
}))(LinearProgress);

export class MyGreetings extends Greetings {
  render() {
    const authState = this.props.authState || this.state.authState;
    const signedIn = authState === "signedIn";
    const theme = this.props.theme;
    const greeting = signedIn
      ? this.userGreetings(theme)
      : this.noUserGreetings(theme);
    if (!greeting) {
      return null;
    }

    return (
      <NavBar theme={theme}>
        <Nav theme={theme}>
          <NavRight theme={theme}>
            <LoginLinearProgress />
          </NavRight>
        </Nav>
      </NavBar>
    );
  }
}
