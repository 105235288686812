import React from "react";
import WarningIcon from "@material-ui/icons/Warning";

export const AbnOfflineWarning = ({ onClose, id }) => {
  return (
    <div className="text-center">
      <div className="mb-5">
        <WarningIcon fontSize="large" color="action" />
      </div>
      <h3 className="text-danger mb-2">
        ABN Lookup Service is currently unavailable!
      </h3>
      <div>
        {`You can proceed with ${
          id ? "editing" : "creating"
        } organisation, but it will be flagged as unverified`}
        .
      </div>
      <div className="my-8 text-right">
        <button onClick={onClose} className="btn btn-primary mr-3">
          <strong>OK</strong>
        </button>
      </div>
    </div>
  );
};
