import React, { useState, useEffect } from "react";
import { AccountInfo } from "./components/AccountInfo";
import { PasswordInfo } from "./components/PasswordInfo";
import { getUser } from "../Auth/_api";
import { useFetch } from "../../../hooks/fetch.hook";
import { Skeleton } from "@material-ui/lab";

export const UserSettings = () => {
  const { request } = useFetch();
  const [user, setUser] = useState({});

  useEffect(() => {
    request(getUser).then(data => setUser(mapUserData(data)));
    // eslint-disable-next-line
  }, []);

  if (!Object.keys(user).length) {
    return <Skeleton variant="rect" width={"100%"} height={812} />;
  }

  return (
    <div
      className="mt-10"
      style={{
        border: "1px solid #E5E5E5",
        borderRadius: "4px",
        fontFamily: "Roboto-Medium"
      }}
    >
      <AccountInfo user={user} />
      <PasswordInfo />
    </div>
  );
};

const mapUserData = response => {
  const {
    user_info: {
      email = "",
      phone_number = "",
      "custom:title": title = ""
    } = {},
    name = "",
    id = ""
  } = response || {};
  const [first_name = "", last_name = ""] = name.split(" ");
  return {
    first_name,
    last_name,
    title,
    email,
    phone_number,
    id
  };
};
