const type = "packages";

export const setPackageList = (payload) => ({
  execute: (state) => {
    return {
      ...state,
      packageList: payload,
    };
  },
  type,
});

export const removePackage = (payload) => ({
  execute: (state) => {
    return {
      ...state,
      packageList: state.packageList.filter(({ id }) => id !== payload),
    };
  },
  type,
});

export const unArchivePackage = (payload) => ({
  execute: (state) => {
    const index = state.packageList.findIndex((item) => item.id === payload.id);
    const myState = [...state.packageList];
    myState.splice(index, 1, payload);
    return {
      ...state,
      packageList: [...myState],
    };
  },
  type,
});
