import React from "react";
import {
  StepLabel,
  Step,
  Stepper as MuiStepper,
  StepConnector
} from "@material-ui/core";
import { StepIcon } from "./StepIcon";
import { withStyles } from "@material-ui/styles";

const Connector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)"
  },
  active: {
    "& $line": {
      borderColor: "#6BC200"
    }
  },
  completed: {
    "& $line": {
      borderColor: "#6BC200"
    }
  },
  line: {
    borderColor: "#B0B0B0",
    borderTopWidth: 3,
    borderRadius: 1
  }
})(StepConnector);

export const DesktopStepper = ({ steps, activeStep }) => {
  return (
    <>
      <MuiStepper
        activeStep={activeStep}
        connector={<Connector />}
        className="stepper-main mx-10 my-5 px-20 d-none d-md-flex"
      >
        {steps.map((_, index) => (
          <Step key={index}>
            <StepLabel StepIconComponent={StepIcon} />
          </Step>
        ))}
      </MuiStepper>
    </>
  );
};
