import config from "../../../../../config";

export const getClientSecretID = payload =>
  fetch(`${config.ApiUrl.Rest}/subscriptions`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(payload),
    mode: "cors",
    credentials: "include"
  });
