import React, { useState } from "react";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { Badge } from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { CalendarView } from "./Calendar.style";
import NotificationsOffIcon from "@material-ui/icons/NotificationsOff";
/**
 * Datepicker/Calendar components
 */

export const Calendar = (props) => {
  const [date, changeDate] = useState(new Date());
  const [selectedDays, setSelectedDays] = useState(
    props.invalidDates.map((date) => moment(date).format("DD/MM/yyyy"))
  );

  const handleMonthChange = async () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        setSelectedDays(
          props.invalidDates.map((date) => moment(date).format("DD/MM/yyyy"))
        );
        resolve();
      }, 1000);
    });
  };

  return (
    <CalendarView
      style={{ border: "1px solid #E5E5E5", borderRadius: "4px" }}
      className="mb-5"
    >
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker
          autoOk
          orientation="landscape"
          variant="static"
          disableToolbar="true"
          openTo="date"
          value={date}
          onChange={changeDate}
          onMonthChange={handleMonthChange}
          renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => {
            const isSelected =
              isInCurrentMonth &&
              selectedDays.includes(moment(day).format("DD/MM/yyyy"));
            return (
              <Badge
                badgeContent={
                  isSelected ? (
                    <NotificationsOffIcon
                      style={{ color: "F2994A", fontSize: "14px" }}
                    />
                  ) : (
                    undefined
                  )
                }
              >
                {isSelected ? <del>{dayComponent}</del> : dayComponent}
              </Badge>
            );
          }}
        />
      </MuiPickersUtilsProvider>
    </CalendarView>
  );
};
