/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  getMessageRecipient,
  getSubmissionMessages
} from "../../app/modules/Submissions/_api";
import { useFetch } from "../../hooks/fetch.hook";
import { CircularProgress } from "@material-ui/core";

export const MessageBox = ({ submission_id }) => {
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [method, setMethod] = useState("");
  const [contact, setContact] = useState([]);
  const { request } = useFetch();

  useEffect(() => {
    setLoading(true);
    request(getSubmissionMessages, submission_id).then(messageData => {
      if (!messageData) return;
      setMethod(messageData[0].method);
      setMessages(messageData.map(message => message.content));
      request(getMessageRecipient, messageData[0].recipient)
        .then(contactData => {
          if (!contactData) return;
          setContact(contactData);
        })
        .finally(() => setLoading(false));
    });
  }, []);
  return (
    <div className="d-flex justify-content-center align-items-center">
      {loading && <CircularProgress size="3rem" className="my-10" />}
      {!loading && (
        <>
          <div className="message-box-wrapper bg-white rounded py-7 px-10 my-2">
            <div className="text-left">
              <h2>Messages</h2>
              <div>{`To: ${
                method === "sms" ? contact.mobile : contact.email
              }`}</div>
            </div>
            <>
              {messages.map((message, index) => {
                return (
                  <div key={index} className="message-box my-5">
                    {message}
                  </div>
                );
              })}
            </>
          </div>
        </>
      )}
    </div>
  );
};
