/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useFetch } from "../../../../hooks/fetch.hook";
import { useParams } from "react-router-dom";
import { DesktopCard } from "./DesktopCard";
import { Headers } from "../../../../ui/components/Headers";
import { Card } from "./Card";
import { Edit } from "./Edit";
import { success, warning } from "../../../../helpers/toasts";
import { Skeleton } from "@material-ui/lab";
import {
  checkPriceCompletition,
  deleteCrop,
  duplicateCrop,
  generateCropsData,
  generateSubmissionPayload
} from "../helpers";
import { useHistory } from "react-router-dom";
import { Loader } from "../../../../ui/components/Loader";
import {
  SwipeableList,
  SwipeableListItem
} from "@sandstreamdev/react-swipeable-list";
import "@sandstreamdev/react-swipeable-list/dist/styles.css";
import {
  changeSubmissionStatus,
  createSubmissionPrice,
  getSubmissionDetails
} from "../_api";
import { ListButton } from "../../../../ui/components/ListButton";

export const List = () => {
  const [crops, setCrops] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submissionId, setSubmissionId] = useState();
  const [currentCrop, setCurrentCrop] = useState();
  const [isEdit, setEdit] = useState(false);
  const [priceLoading, setPriceLoading] = useState(false);

  const { request } = useFetch();
  const history = useHistory();
  const { id } = useParams();

  const getSubmissionData = () => {
    setCrops([]);
    setLoading(true);
    request(getSubmissionDetails, id)
      .then(submissionData => {
        if (!submissionData) {
          warning(
            "Something has gone wrong, please try reloading the page and make sure you have opened the latest submission!"
          );
          return;
        }
        if (
          submissionData.submission.status === "active" ||
          submissionData.submission.status === "overdue"
        ) {
          setCrops(generateCropsData(submissionData.crops));
          setSubmissionId(submissionData.submission.id);
        } else {
          history.push("/error/error-submitted");
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getSubmissionData();
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (document.querySelector(".crop-pricing")) {
      document.querySelector("body").classList.add("body-pricing-override");
    }
  }, []);

  const handleSubmit = () => {
    if (checkPriceCompletition(crops)) {
      setPriceLoading(true);
      const payload = generateSubmissionPayload(crops);
      request(createSubmissionPrice, submissionId, payload).then(
        priceResponse => {
          if (priceResponse.length > 0) {
            request(changeSubmissionStatus, submissionId, {
              status: "submitted"
            })
              .then(statusResponse => {
                if (!statusResponse) return;
                success("We have successfully saved your changes!");
                history.push("/submitted");
              })
              .finally(() => {
                setPriceLoading(false);
              });
          } else {
            warning(
              "Oops, something has gone wrong! Please make sure you fill all the details for each crop, or try again later"
            );
          }
        }
      );
    } else {
      warning("Please make sure you filled all the details for each crops !");
    }
  };

  return (
    <section className="crop-pricing">
      <Headers />
      {priceLoading && <Loader title="Loading..." isOpen={priceLoading} />}
      {loading && (
        <div>
          <Skeleton
            variant="rect"
            width={"100%"}
            height={122}
            className="my-2"
          />
          <Skeleton
            variant="rect"
            width={"100%"}
            height={122}
            className="my-2"
          />
          <Skeleton variant="rect" width={"100%"} height={122} />
        </div>
      )}
      {isEdit ? (
        <Edit
          cropData={currentCrop}
          setEdit={setEdit}
          setCrops={setCrops}
          crops={crops}
        />
      ) : (
        <SwipeableList>
          {crops.map(crop => (
            <SwipeableListItem
              key={crop.generated_id}
              swipeLeft={{
                content: (
                  <div className="swipe-item swipe-delete">
                    <span className="mr-5">Remove</span>
                  </div>
                ),
                action: () => deleteCrop(crop.generated_id, setCrops, crops)
              }}
              swipeRight={{
                content: (
                  <div className="swipe-item swipe-duplicate">
                    <span className="ml-5">Duplicate</span>
                  </div>
                ),
                action: () => duplicateCrop(crop, setCrops)
              }}
            >
              <Card
                cropData={crop}
                setCurrentCrop={setCurrentCrop}
                setEdit={setEdit}
              />
            </SwipeableListItem>
          ))}
        </SwipeableList>
      )}
      {!isEdit &&
        crops.map(crop => (
          <DesktopCard
            key={crop.generated_id}
            cropData={crop}
            crops={crops}
            setCrops={setCrops}
          />
        ))}
      {!isEdit && (
        <div className="mt-10 d-flex justify-content-around align-items-center">
          <ListButton
            label="Submit"
            size="large"
            text="white"
            background="#6BC200"
            textTransform="capitalize"
            className="py-4 mb-2 crop-edit-button"
            fontWeight="400"
            onClick={handleSubmit}
          />
        </div>
      )}
    </section>
  );
};
