export const HEADINGS = [
  ["code", "Code"],
  ["name", "Name"],
  ["status", "Status"]
];

export const searchMap = {
  Code: "code",
  Name: "name"
};
