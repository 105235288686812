import { Formik } from "formik";
import React, { useState } from "react";
import { useFetch } from "../../../../hooks/fetch.hook";
import { ListButton } from "../../../../ui/components/ListButton";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { Loader } from "../../../../ui/components/Loader";
import { updatePricing } from "../_api";

export const EditPricing = ({
  pricingDetails,
  setModalOpen,
  setData,
  data
}) => {
  const { request } = useFetch();
  const [loading, setLoading] = useState(false);

  const initialValues = {
    low: pricingDetails.low || "",
    high: pricingDetails.high || "",
    best: pricingDetails.best || ""
  };

  const handleSubmit = values => {
    setLoading(true);
    request(
      updatePricing,
      values,
      pricingDetails.submission_id,
      pricingDetails.id
    )
      .then(response => {
        setData(
          data.map(pricing => {
            if (pricing.id === response.id) {
              return {
                ...pricing,
                low: response.low,
                high: response.high,
                best: response.best,
                price_changes: response.price_changes
              };
            }
            return pricing;
          })
        );
      })
      .finally(() => {
        setLoading(false);
        setModalOpen(false);
      });
  };

  return (
    <div className="bg-white p-10">
      {loading && <Loader isOpen={loading} maxWidth="xs" title="Loading..." />}
      <Formik onSubmit={handleSubmit} initialValues={initialValues}>
        {({ handleSubmit }) => (
          <>
            <h3 className="mb-10">
              <strong>Edit Pricing</strong>
            </h3>
            <div className="row justify-content-between">
              <div className="col-8">
                <MappedInput
                  label="Low Price"
                  name="low"
                  data-testid="low"
                  color="#6BC200"
                />
                <MappedInput
                  label="High Price"
                  name="high"
                  data-testid="high"
                  color="#6BC200"
                />
                <MappedInput
                  label="Best Price"
                  name="best"
                  data-testid="best"
                  color="#6BC200"
                />
              </div>
            </div>
            <div className="mt-10 text-right">
              <ListButton
                label="Save"
                size="large"
                onClick={handleSubmit}
                boxShadow={false}
              />
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};
