import React from "react";
import { ListButton } from "./ListButton";

export const ConfirmAction = ({
  handleClose,
  handleSubmit,
  warningMessage,
  confirmLabel = "Confirm"
}) => {
  return (
    <div className="px-5 py-3">
      <div className="text-center">
        <h3 className="text-warning">{warningMessage}</h3>
      </div>
      <div className="mt-8 d-flex justify-content-end">
        <div className="mr-3">
          <ListButton
            label="Back"
            size="large"
            onClick={handleClose}
            variant="outlined"
            boxShadow={false}
            text="#407A28"
          />
        </div>
        <ListButton
          label={confirmLabel}
          size="large"
          boxShadow={false}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};
