import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { ListButton } from "../../../../ui/components/ListButton";
import { error } from "../../../../helpers/toasts";

export const ResetPassword = ({ handleClose, setStep, username }) => {
  const [submitting, setSubmitting] = useState(false);
  const handleSubmit = () => {
    setSubmitting(true);
    Auth.forgotPassword(username)
      .then(data => data && setStep("confirm"))
      .catch(err => error(err.message))
      .finally(() => setSubmitting(false));
  };
  return (
    <div className="px-5 py-10">
      <div className="mb-5">Password reset</div>
      <h4>Are you sure you want to reset your password?</h4>

      <div className="mt-15 d-flex justify-content-end align-items-center">
        <ListButton
          label="Cancel"
          size="small"
          onClick={handleClose}
          data-testid="cancel"
          boxShadow={false}
          className="mx-2"
        />
        <ListButton
          label="Reset"
          size="small"
          onClick={handleSubmit}
          data-testid="reset"
          boxShadow={false}
          disabled={submitting}
        />
      </div>
    </div>
  );
};
