import { useState, useCallback, useRef } from "react";
import { error } from "../helpers/toasts";

export const useFetch = () => {
  const [isLoading, setLoading] = useState(false);
  let errorTime = useRef(0);

  const request = useCallback(async (apiCall, ...params) => {
    let tryTimes = params.includes("justOnce") ? 1 : 0;
    while (tryTimes < 1) {
      try {
        setLoading(true);
        const response = await apiCall(...params);

        if (response.status === 204) return "success";
        if (response.status === 403) {
          error("Your session is expired");
        }
        if (
          !params.includes("no504") &&
          (response.status === 503 || response.status === 504)
        ) {
          error("Timeout or other error occurred!");
          return "Server error";
        }

        const jsonData = await response.json();
        if (response.status === 200 && jsonData.data !== 0 && !jsonData.data) {
          return "success";
        }
        if (!response.ok) {
          throw new Error((jsonData || {}).message);
        }
        return jsonData.data;
      } catch (err) {
        if (tryTimes === 1 && Date.now() - errorTime.current > 3000) {
          errorTime.current = Date.now();
          error(err.message || "Something goes wrong...");
        }
      } finally {
        tryTimes++;
        setLoading(false);
      }
    }
    // eslint-disable-next-line
  }, []);

  return { isLoading, request };
};
