import React, { useMemo } from "react";
import { useField } from "formik";
import { Checkbox as MuiCheckbox, FormControlLabel } from "@material-ui/core";

export function Checkbox({
  label,
  error,
  inputClasses,
  labelPlacement,
  wrapperClasses,
  ...other
}) {
  return (
    <FormControlLabel
      className={`${wrapperClasses} m-0`}
      control={
        <MuiCheckbox className={inputClasses} color="primary" {...other} />
      }
      label={label}
      labelPlacement={labelPlacement}
    />
  );
}

export function MappedCheckbox(props) {
  const [field, meta] = useField(props.name);
  const getInputClasses = useMemo(() => {
    if (meta.touched && meta.error) {
      return "is-invalid";
    }
    if (meta.touched && !meta.error) {
      return "is-valid";
    }
    return "";
  }, [meta]);

  return (
    <Checkbox
      {...field}
      {...props}
      error={meta.error}
      inputClasses={getInputClasses}
    />
  );
}
