export const generateDiscountPayload = values => {
  if (values.duration === "repeating") {
    return {
      duration: values.duration,
      percent_off: values.percent_off,
      duration_in_months: values.duration_in_months
    };
  } else if (!values.duration) {
    return {
      terminated: true
    };
  } else {
    return {
      duration: values.duration,
      percent_off: values.percent_off
    };
  }
};
