import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import SearchBar from "@bit/the-glue.frontendcomponents.search-bar";
import { HEADINGS, searchMap } from "../constants";
import { ListHeader } from "../../../../ui/structures/ListHeader";
import { useFetch } from "../../../../hooks/fetch.hook";
import { Message as MessageIcon } from "@material-ui/icons";
import { sortHeaderStyle } from "../../../constants";
import { TableSortLabel } from "@material-ui/core";
import { Modal } from "../../../../ui/components/Modal";
import { ConfirmDelete } from "../../../../ui/components/ConfirmDelete";
import { ArchiveCheckbox } from "../../../../ui/components/ArchiveCheckbox";
import { UnarchiveButton } from "../../../../ui/components/UnarchiveButton";
import { info } from "../../../../helpers/toasts";
import { isSortable, modifySubmissionData } from "../helpers";
import { ListButton } from "../../../../ui/components/ListButton";
import { useLocation } from "react-router-dom";
import {
  handleSearch,
  getSelectedId,
  getStatusRowStyle,
  isStatusField,
  isStatus
} from "../../../../ui/helpers";
import {
  removeSubmission,
  setSubmissionList,
  unArchiveSubmission
} from "../_redux/actions";
import {
  getSubmissionById,
  modifySubmission,
  deleteSubmission,
  getAllSubmissions,
  sendReminders
} from "../_api";
import { MessageBox } from "../../../../ui/components/MessageBox";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const List = ({ id }) => {
  const dispatch = useDispatch();
  const { request } = useFetch();
  let query = useQuery().get("status");

  const [loading, setLoading] = useState(false);
  const [focused, setFocused] = useState(false);
  const [initialData, setInitialData] = useState([]);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [archivedLoading, setArchivedLoading] = useState(false);
  const [archived, setArchived] = useState(false);
  const [search, setSearch] = useState(query || "");
  const [selected, setSelected] = useState({});
  const [unArchiving, setUnarchiving] = useState(false);
  const [field, setField] = useState("");
  const [direction, setDirection] = useState("");
  const [messageModal, setMessageModal] = useState(false);
  const [submissionId, setSubmissionId] = useState("");

  const data = modifySubmissionData(
    useSelector(({ submissions: { submissionList } }) => submissionList)
  );

  const fetchSubmissions = (search, field, direction) => {
    setLoading(true);
    request(getAllSubmissions, search, field, field && direction)
      .then(submissions => {
        if (!submissions) return;
        dispatch(setSubmissionList(submissions));
        setInitialData(modifySubmissionData(submissions));
      })
      .finally(() => {
        setArchivedLoading(false);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!archived && search !== "overdue") {
      fetchSubmissions(
        search === ""
          ? "status=active&status=overdue"
          : search.search("status") === -1
          ? search + "&status=active&status=overdue"
          : search,
        field,
        direction
      );
    } else if (archived) {
      fetchSubmissions(
        search === ""
          ? "status=all"
          : search.search("status") === -1
          ? search + "&status=all"
          : search,
        field,
        direction
      );
    } else if (search === "overdue") {
      fetchSubmissions("status=overdue", field, direction);
    }
    // eslint-disable-next-line
  }, [search, archived, field, direction]);

  const handleFocus = () => setFocused(true);
  const handleMessageModalClose = () => setMessageModal(false);
  const handleMessageModalOpen = () => setMessageModal(true);
  const handleModalClose = () => setConfirmDeleteOpen(false);
  const handleOpenModal = id => {
    setConfirmDeleteOpen(true);
    setSelectedItem(id);
  };

  const showArchived = () => {
    setArchivedLoading(true);
    setArchived(!archived);
  };

  const unArchiveDisabled = useMemo(
    () => Object.values(selected).filter(Boolean).length !== 1,
    [selected]
  );

  const handleUnarchive = () => {
    const SubmissionID = getSelectedId(selected);
    const status = (data.find(({ id }) => id === SubmissionID) || {}).status;
    if (status !== "archived") return;
    setUnarchiving(true);
    request(modifySubmission, { status: "active" }, SubmissionID)
      .then(data => {
        if (!data) return;
        dispatch(unArchiveSubmission(data));
        setSelected({});
      })
      .finally(() => setUnarchiving(false));
  };

  const handleArchive = id => {
    setLoading(true);
    setConfirmDeleteOpen(false);
    request(deleteSubmission, id)
      .then(data => {
        if (!data) return;
        if (archived) {
          request(getSubmissionById, id).then(
            data => data && dispatch(unArchiveSubmission(data))
          );
        } else {
          dispatch(removeSubmission(id));
          setSelected({});
          info("Submission has been archived");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const KEY_MAP = {
    "Submission ID": data.map(item => item.code),
    Status: data.map(item => item.status),
    Contact: data.map(item => item.contact_name)
  };

  const resetSearch = () => {
    dispatch(setSubmissionList(initialData));
    setSearch("");
  };

  const handleReminders = () => {
    const remindersPayload = { submissions: Object.keys(selected) };
    setLoading(true);
    request(sendReminders, remindersPayload)
      .then(response => {
        if (!response) return;
        info("Reminders have been sent!");
      })
      .finally(() => {
        setLoading(false);
        fetchSubmissions(
          search === ""
            ? "status=active&status=overdue"
            : search.search("status") === -1
            ? search + "&status=active&status=overdue"
            : search,
          field,
          direction
        );
      });
  };

  const renderButtons = () => (
    <>
      {archived && (
        <UnarchiveButton
          handleClick={handleUnarchive}
          disabled={unArchiveDisabled}
          loading={unArchiving}
        />
      )}
      <ListButton
        label="Send Reminders"
        size="small"
        onClick={handleReminders}
        boxShadow={false}
        disabled={
          selected === undefined
            ? true
            : false ||
              initialData.length === 0 ||
              Object.keys(selected).length === 0
        }
        data-test="reminders-btn"
      />
    </>
  );

  function renderHeaderWithSorting(headings) {
    return headings.map(([key, header]) => {
      if (isSortable(key)) {
        return (
          <th
            key={key}
            style={sortHeaderStyle}
            className="px-5 text-nowrap text-center"
            onClick={() => {
              setDirection(
                key !== field ? "desc" : direction === "desc" ? "asc" : "desc"
              );
              setField(key);
            }}
          >
            <span style={{ cursor: "pointer" }}>{header}</span>
            {key === field && <TableSortLabel active direction={direction} />}
          </th>
        );
      } else
        return (
          <th
            key={key}
            style={sortHeaderStyle}
            className="px-5 text-nowrap text-center"
          >
            <span>{header}</span>
          </th>
        );
    });
  }

  function renderSubmissionsRow(headings, item) {
    return headings.map(([key]) => {
      return (
        <td
          key={key}
          className={`text-center no-line border-0 px-2 py-5 ${isStatus(key) &&
            getStatusRowStyle(item)}`}
        >
          {isStatusField(key, item.status)}
          {key === "message" ? (
            <MessageIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                setSubmissionId(item.id);
                handleMessageModalOpen();
              }}
            />
          ) : (
            item[key]
          )}
        </td>
      );
    });
  }

  return (
    <div className="row justify-content-center mt-10">
      <div className="col-12">
        {confirmDeleteOpen && (
          <Modal
            isOpen={confirmDeleteOpen}
            submitable
            onClose={handleModalClose}
            maxWidth="sm"
            modalContent={
              <ConfirmDelete
                handleClose={handleModalClose}
                handleSubmit={handleArchive}
                id={selectedItem}
                name={(data.find(({ id }) => id === selectedItem) || {}).name}
              />
            }
          />
        )}
        {messageModal && (
          <Modal
            isOpen={messageModal}
            onClose={handleMessageModalClose}
            maxWidth="sm"
            buttonText="Back"
            modalContent={
              <MessageBox
                submission_id={submissionId}
                onClose={handleMessageModalClose}
              />
            }
          />
        )}
        <div
          className="bg-white rounded py-7 px-10"
          style={{ border: "1px solid #E5E5E5", borderRadius: "4px" }}
        >
          <ListHeader
            title="Submissions"
            renderButtons={renderButtons}
            handleFocus={handleFocus}
          />
          <div>
            <SearchBar
              onSearch={data => handleSearch(data, searchMap, setSearch)}
              clearSearch={resetSearch}
              keyMap={KEY_MAP}
              placeholder="Filter Submissions..."
              elevation={2}
              chipBackgroundColor="rgba(107, 194, 0, 0.3)"
              chipColor="#6BC200"
              focused={focused}
              setFocused={setFocused}
            />
          </div>
          <ArchiveCheckbox
            archivedLoading={archivedLoading}
            archived={archived}
            showArchived={showArchived}
            label="Display All Records"
          />
          <Datagrid
            data={data}
            headings={HEADINGS}
            renderRow={renderSubmissionsRow}
            renderHeaderWithSorting={renderHeaderWithSorting}
            selectable
            selected={selected}
            setSelected={setSelected}
            deletable
            handleDelete={handleOpenModal}
            loading={loading}
            link="submissions"
            paginationOptions={[25, 50, 100]}
            paginationDefaultValue={50}
          />
        </div>
      </div>
    </div>
  );
};
