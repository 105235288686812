import React from "react";
import Button from "@bit/the-glue.frontendcomponents.button";

export const ListButton = ({ label, size = "small", ...other }) => {
  return (
    <Button
      label={label}
      size={size}
      background="#6BC200"
      text="#FFF"
      boxShadow
      textTransform="uppercase"
      fontWeight={500}
      fontSize="14px"
      {...other}
      style={{ letterSpacing: "1.25px", lineHeight: "16px" }}
    />
  );
};
