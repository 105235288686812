export const authenticatorTheme = {
  toast: {
    backgroundColor: "#e8cccc",
    color: "#8b0000",
    paddingTop: "1.25rem",
    paddingBottom: "1.25rem",
    paddingLeft: "1.25rem",
    paddingRight: "1.25rem",
    border: "1px solid",
    borderRadius: ".42rem",
    borderColor: "#e8cccc"
  },
  sectionFooter: {
    textAlign: "center"
  },
  sectionBody: {
    textAlign: "center"
  },
  sectionHeader: {
    fontWeight: "600",
    fontSize: "1.25rem",
    textAlign: "center",
    width: "100%",
    paddingTop: "1.25rem",
    paddingBottom: "1.25rem",
    paddingLeft: "1.25rem",
    paddingRight: "1.25rem",
    color: "#6BC200",
    textTransform: "uppercase"
  },
  sectionFooterSecondaryContent: {
    textTransform: "uppercase",
    marginTop: "1rem",
    display: "block"
  },
  container: {
    width: "100%"
  },
  formField: {
    width: "100%",
    paddingTop: "1rem",
    paddingBottom: "1rem"
  },
  hint: {
    width: "100%",
    paddingTop: "1.25rem",
    paddingBottom: "1.25rem",
    paddingLeft: "1.25rem",
    paddingRight: "1.25rem",
    color: "#6BC200",
    textAlign: "center",
    textTransform: "uppercase"
  },
  input: {
    width: "100%",
    paddingTop: "1.25rem",
    paddingBottom: "1.25rem",
    paddingLeft: "1.25rem",
    paddingRight: "1.25rem",
    borderRadius: ".42rem",
    border: "1px solid",
    borderColor: "#f3f6f9",
    backgroundColor: "#f3f6f9"
  },
  inputLabel: { display: "none" },
  button: {
    marginTop: "0.75rem",
    marginBottom: "0.75rem",
    paddingTop: "1rem",
    paddingBottom: "1rem",
    paddingLeft: "1.25rem",
    paddingRight: "1.25rem",
    fontSize: "1rem",
    lineHeight: "1.5",
    borderRadius: ".42rem",
    border: "1px solid",
    borderColor: "#6BC200",
    backgroundColor: "#6BC200",
    color: "#FFFFFF",
    width: "100%",
    textTransform: "uppercase"
  }
};
