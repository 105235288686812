import React from "react";
import { ListButton } from "../../../../../ui/components/ListButton";

export const Landing = ({ handleNext }) => {
  return (
    <section className="d-flex justify-content-center align-items-center my-5 flex-column text-center ">
      <h1 className="mt-10 mb-5 stepper-title">
        Welcome to Datafresh Reporting Service
      </h1>
      <p className="stepper-description text-center">
        Thank you for considering signing up for our service. By creating an
        account, you'll be able to access all of the features and benefits that
        our platform has to offer. With just a few simple steps, you'll be able
        to start using our service to your advantage. So don't wait – sign up
        now and start enjoying all that we have to offer!
      </p>
      <ListButton
        label="Let`s get started"
        size="large"
        onClick={handleNext}
        boxShadow={false}
        className="mt-5 mb-10"
      />
    </section>
  );
};
