/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from "react";
import Tabs from "@bit/the-glue.frontendcomponents.tabs";
import { CropDetails } from "./CropDetails";
import { CropPackages } from "./CropPackages";
import { getAllContacts, getStates } from "../_api";
import { useFetch } from "../../../../../hooks/fetch.hook";
import { sortHelper } from "../helpers";
import { getCategoryList } from "../../../Settings/Subcategories/_api";
import { modifyCategoryList } from "../../../Settings/Subcategories/helpers";

export const Card = ({
  match: {
    params: { id }
  }
}) => {
  const { request } = useFetch();
  const [contacts, setContacts] = useState([]);
  const [states, setStates] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    Promise.all([request(getAllContacts), request(getStates)]).then(
      ([contacts, states]) => {
        contacts && setContacts(contacts.sort(sortHelper));
        states && setStates(states);
      }
    );
  }, []);

  useEffect(() => {
    request(getCategoryList).then(
      data => data && setCategories(modifyCategoryList(data))
    );
  }, []);

  const contactOptions = useMemo(
    () =>
      contacts.map(contact => ({
        value: contact.id,
        label: `${contact.first_name} ${contact.last_name}`
      })),
    [contacts]
  );

  const statesOptions = useMemo(
    () =>
      states.map(state => ({
        value: state.state_id,
        label: state.country_name + " " + state.state_name
      })),
    [states]
  );

  const tabs = [
    {
      label: "Crop Details",
      tabPanel: (
        <CropDetails
          id={id}
          contactOptions={contactOptions}
          stateOptions={statesOptions}
          parentCategories={categories}
        />
      )
    },
    {
      label: "Package Sizes",
      tabPanel: <CropPackages id={id} />
    }
  ];

  return (
    <div className="mt-10">
      <Tabs
        defaultActive={"0"}
        tabs={tabs}
        align="right"
        background="#6BC200"
        text="white"
      />
    </div>
  );
};
