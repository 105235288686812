const config = {
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: "ap-southeast-2",

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: "ap-southeast-2_vP5LE3mfR",

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: "2di0fe4rjtu1ei9jdqubhh8hu7",

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,
    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      domain: "app.datafresh.com.au",
      // OPTIONAL - Cookie path
      //path: '/',
      // OPTIONAL - Cookie expiration in days
      expires: 1,
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      secure: true,
      // CSRF
      sameSite: "lax"
    }
  },
  ApiUrl: {
    Rest: "https://api.app.datafresh.com.au",
    WebSocket: "https://api.app.datafresh.com.au",
    Url: "https://app.datafresh.com.au"
  },
  Branding: {
    LoginBackground: {
      "app.datafresh.com.au": "/media/bg/datafresh-bg.jpg"
    }
  },
  CognitoInformation: {
    IssuerName: "DataFresh"
  },
  Stripe: {
    clientKey:
      "pk_live_51NOUKDKXWcAmmOgEaZgRUoUx7iLpKW2bzqPUo1G3IhVXJW74MLh7432UNdVFyPw2ZxpuRq8zIr3YQT61gyvIwuWW00zogdmnoS"
  }
};

export default config;
