import React, { forwardRef, useCallback } from "react";
import { useField, useFormikContext } from "formik";
import { Input as MuiInput } from "@material-ui/core";

const NakedInput = forwardRef(function(
  {
    id,
    name,
    tip,
    customClasses = "",
    handleSubmit,
    value,
    startAdornment,
    customInputProps,
    placeholder,
    useStyles,
    ...other
  },
  ref
) {
  const classes = useStyles();
  const { values } = useFormikContext();
  return (
    <div className="d-flex justify-content-center align-items-center">
      <MuiInput
        id={id}
        name={name}
        value={value}
        className={`${customClasses}`}
        variant="standard"
        inputRef={ref}
        startAdornment={startAdornment}
        placeholder={placeholder}
        classes={{
          root:
            name === "low_price" || name === "high_price" ? classes.root : "",
          underline: classes.underline
        }}
        inputProps={{
          ...customInputProps,
          inputMode: "decimal",
          pattern: "[0-9]+([.,][0-9]+)?",
          type: "text",
          tabIndex: name === "best_price" ? -1 : 1,
          onBlur: () => {
            if (handleSubmit !== undefined) handleSubmit(values);
          },
          onChange: () => {
            if (handleSubmit !== undefined) handleSubmit(values);
          }
        }}
        {...other}
      />
    </div>
  );
});

export function MappedNakedInput(props) {
  const errorStyle = { color: "red", marginBottom: "10px" };

  function getTip(meta = {}, tip) {
    if (meta.touched && meta.error && typeof meta.error !== "object") {
      return <span style={errorStyle}>{meta.error}</span>;
    }
    if (tip) {
      return <span>{tip}</span>;
    }
  }

  const [field, meta] = useField(props.name);

  const getInputClasses = useCallback(() => {
    if (meta.touched && meta.error) {
      return "is-invalid";
    }

    if (meta.touched && !meta.error) {
      return "is-valid";
    }

    return "";
  }, [meta]);

  return (
    <NakedInput
      {...field}
      {...props}
      inputClasses={getInputClasses()}
      tip={getTip(meta, props.tip)}
    />
  );
}
