import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { MappedInput } from "../../../ui/components/Input/index";
import { Skeleton } from "@material-ui/lab";
import { ContactDetailsSchema } from "../../../helpers/schemas";
import { useFetch } from "../../../hooks/fetch.hook";
import { archiveUser } from "../../modules/Users/_api";
import { AutocompleteMultiple } from "../../../ui/components/AutocompleteMultiple";
import { sortHelper } from "../../../ui/helpers";
import { TagsField } from "../../../ui/structures/TagsForm/TagsField";
import { TagsAutocomplete } from "../../../ui/structures/TagsForm/TagsAutocomplete";
import { info } from "../../../helpers/toasts";
import { getTags } from "../../modules/Settings/Tags/_api";
import { Loader } from "../../../ui/components/Loader";
import { Modal } from "../../../ui/components/Modal";
import { ListButton } from "../../../ui/components/ListButton";
import { MappedSelect } from "@bit/the-glue.frontendcomponents.select";
import { methodOptions, statusOptions } from "./constants";
import {
  addContact,
  inviteUser,
  modifyContact,
  linkOrganisationToContact,
  deleteContactTags,
  editContactTags,
  getContactTags
} from "../../modules/Contacts/_api";

export const ContactCard = ({
  data = {},
  id,
  handleCancel,
  organisationOptions = [],
  linkedOrganisations = [],
  organisationId
}) => {
  const history = useHistory();
  const { request } = useFetch();

  const [loading, setLoading] = useState(false);
  const [revokeIsloading, setRevokeIsLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const [linkedTags, setLinkedTags] = useState([]);
  const [inviteLoading, setInviteLoading] = useState(false);
  const [profileLoading, setProfileLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const isLinkedContact =
    history.location.pathname === `/organisations-list/${organisationId}`;

  const name = [data.first_name, data.last_name].join(" ");

  useEffect(() => {
    setProfileLoading(true);
    if (id) {
      Promise.all([
        request(getContactTags, id),
        request(getTags, "type=_like(Contact)_")
      ])
        .then(([contactTags, tags]) => {
          tags && setTags(tags);
          contactTags && setLinkedTags(contactTags);
        })
        .finally(() => {
          setProfileLoading(false);
        });
    } else {
      request(getTags, "type=_like(Contact)_")
        .then(data => {
          data && setTags(data);
        })
        .finally(() => {
          setProfileLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialValues = {
    first_name: data.first_name || "",
    last_name: data.last_name || "",
    phone: data.phone || "",
    mobile: data.mobile || "",
    email: data.email || "",
    position_title: data.position_title || "",
    organisation_id: data.organisation_id || [],
    tags: id ? (linkedTags || []).map(({ id }) => id) : [],
    status: id ? data.status || "" : "active",
    price_collection_method: data.price_collection_method || ""
  };

  const createContact = (values, contactPayload) => {
    setLoading(true);
    request(addContact, contactPayload)
      .then(data => {
        if (!data) return;
        id = data.id;
        handleSubmitTags(values.tags);
      })
      .finally(() => {
        setLoading(false);
        history.goBack();
      });
  };

  const updateContact = (values, contactPayload) => {
    setLoading(true);
    Promise.all([
      request(modifyContact, id, contactPayload),
      request(
        linkOrganisationToContact,
        id,
        values.organisation_id.map(id => ({
          id
        }))
      )
    ])
      .then(([contactData, contactLinkage]) => {
        handleSubmitTags(values.tags);
        if (!contactData || !contactLinkage) return;
        info("Details have been updated!");
      })
      .finally(() => {
        setLoading(false);
        history.goBack();
      });
  };

  const handleSubmit = values => {
    const contactPayload = {
      first_name: values.first_name,
      last_name: values.last_name,
      phone: values.phone,
      mobile: values.mobile,
      email: values.email,
      position_title: values.position_title,
      organisation_id: values.organisation_id,
      status: values.status,
      price_collection_method: values.price_collection_method
    };
    if (id) {
      updateContact(values, contactPayload);
    } else {
      createContact(values, contactPayload);
    }
  };

  const editTagsRequest = tags => {
    if (tags.length) {
      return request(
        editContactTags,
        id,
        tags.map(tagID => ({ id: tagID }))
      ).then(
        data =>
          data &&
          request(getContactTags, id).then(data => data && setLinkedTags(data))
      );
    } else {
      return request(
        deleteContactTags,
        id,
        linkedTags.map(({ id }) => ({ id }))
      ).then(data => data && setLinkedTags([]));
    }
  };

  const handleSubmitTags = tags => {
    setLoading(true);
    editTagsRequest(tags)
      .then(data => {
        if (!data) return;
        info("Contact's Tags have been updated!");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const inviteUserToPortal = () => {
    setInviteLoading(true);
    request(inviteUser, id)
      .then(data => {
        if (!data) return;
        info(`${name} has been Invited to the portal!`);
      })
      .finally(() => setInviteLoading(false));
  };

  const revokeAccess = () => {
    setRevokeIsLoading(true);
    if (!data.user_id) {
      info(`${name} is not invited to the portal`);
      setRevokeIsLoading(false);
    } else
      request(archiveUser, data.user_id)
        .then(data => {
          if (!data) return;
          info("Portal access has been revoked!");
        })
        .finally(() => setRevokeIsLoading(false));
  };

  return (
    <div>
      {profileLoading ? (
        <Skeleton variant="rect" width={"100%"} height={687.27} />
      ) : (
        <div
          className="bg-white p-10"
          style={{ border: "1px solid #E5E5E5", borderRadius: "4px" }}
        >
          {loading && <Loader title="Loading..." isOpen={loading} />}
          <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={ContactDetailsSchema}
          >
            {({ handleSubmit, setFieldValue, values, ...formik }) => (
              <>
                {modalOpen && (
                  <Modal
                    maxWidth="sm"
                    isOpen={modalOpen}
                    submitable
                    onClose={handleModalClose}
                    modalContent={
                      <TagsAutocomplete
                        name="tags"
                        placeholder="Select Tags"
                        setValue={setFieldValue}
                        options={tags}
                        loading={!tags.length}
                        onClose={handleModalClose}
                        currentValue={values.tags}
                        multiple
                      />
                    }
                  />
                )}
                {id && (
                  <div className="my-5 d-flex col-8 justify-content-end">
                    {inviteLoading ? (
                      <div className="mr-15 d-flex justify-content-center align-items-center">
                        <CircularProgress size="1.2rem" />
                      </div>
                    ) : (
                      <div className="mr-3">
                        <ListButton
                          label="Invite User to Portal"
                          size="small"
                          background="rgba(107, 194, 0, 0.3)"
                          text="#6BC200"
                          textTransform="uppercase"
                          fontWeight="600"
                          onClick={inviteUserToPortal}
                        />
                      </div>
                    )}
                    {revokeIsloading ? (
                      <div className="ml-15 d-flex justify-content-center align-items-center">
                        <CircularProgress size="1.2rem" />
                      </div>
                    ) : (
                      <ListButton
                        label="Revoke Portal Access"
                        background="rgba(107, 194, 0, 0.3)"
                        text="#6BC200"
                        textTransform="uppercase"
                        fontWeight="600"
                        onClick={revokeAccess}
                      />
                    )}
                  </div>
                )}
                <h3 className="mb-5">
                  <strong>{id ? name : "New Contact"}</strong>
                </h3>

                <div className="row justify-content-between">
                  <div className="col-8">
                    <MappedSelect
                      name="status"
                      label="Contact Status"
                      value={values.status}
                      color="#6BC200"
                      options={statusOptions}
                    />
                    <MappedSelect
                      name="price_collection_method"
                      label="Price Request Method"
                      value={values.price_collection_method}
                      color="#6BC200"
                      options={methodOptions}
                    />
                    <MappedInput
                      label="First Name"
                      name="first_name"
                      data-testid="first_name"
                      color="#6BC200"
                    />
                    <MappedInput
                      label="Last Name"
                      name="last_name"
                      data-testid="last_name"
                      color="#6BC200"
                    />
                    <MappedInput
                      label="Phone"
                      name="phone"
                      data-testid="phone"
                      color="#6BC200"
                    />
                    <MappedInput
                      label="Mobile"
                      name="mobile"
                      data-testid="mobile"
                      color="#6BC200"
                    />
                    <MappedInput
                      label="Email"
                      name="email"
                      data-testid="email"
                      color="#6BC200"
                    />
                    <MappedInput
                      label="Position"
                      name="position_title"
                      data-testid="position_title"
                      color="#6BC200"
                    />
                    {organisationOptions.length ? (
                      <div className="mt-3 col-12">
                        <AutocompleteMultiple
                          name="organisation_id"
                          placeholder="Organisation"
                          color="#6BC200"
                          setValue={setFieldValue}
                          options={organisationOptions.sort(sortHelper)}
                          defaultValueField="value"
                          disabled={isLinkedContact}
                          defaultValue={linkedOrganisations}
                          variant="standard"
                        />
                        {formik.touched.organisation_id &&
                        formik.errors.organisation_id ? (
                          <div className="text-danger">
                            {formik.errors.organisation_id}
                          </div>
                        ) : null}
                      </div>
                    ) : (
                      <div className="mt-3 col-8">
                        <Skeleton variant="rect" width={"100%"} height={55} />
                      </div>
                    )}
                  </div>
                  <div className="col-4">
                    <TagsField
                      label="Tags"
                      tags={values.tags}
                      predefinedTags={tags}
                      handleAdd={handleModalOpen}
                      setValue={setFieldValue}
                    />
                  </div>
                </div>
                <div className="mt-10 d-flex justify-content-end align-items-center">
                  <ListButton
                    label="Cancel"
                    size="large"
                    onClick={handleCancel}
                    boxShadow={false}
                    className="mx-2"
                  />
                  <ListButton
                    label="Save"
                    size="large"
                    onClick={handleSubmit}
                    boxShadow={false}
                  />
                </div>
              </>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
};
