import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { Link } from "react-router-dom";
import "./StatusTile.css";

export const StatusTile = ({ icon, quantity, title, path, status, search }) => {
  return (
    <>
      <Link
        to={{
          pathname: path,
          status: status,
          search: search,
        }}
      >
        <div
          className="d-flex-column justify-content-center align-items-center text-center py-8 tile-wrapper"
          style={{ width: 210, height: 165 }}
        >
          <div className="mb-5">{icon}</div>
          <div style={{ fontSize: "2rem" }} className="mb-3 text-center">
            {quantity || quantity === 0 ? (
              quantity
            ) : (
              <div className="d-flex justify-content-center align-items-center">
                <Skeleton variant="circle" width={40} height={40} />
              </div>
            )}
          </div>
          <div style={{ fontSize: "1rem" }}>{title}</div>
        </div>
      </Link>
    </>
  );
};
