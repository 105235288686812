import React from "react";
import { FormControlLabel, CircularProgress } from "@material-ui/core";
import { ListButton } from "./ListButton";

export const UnarchiveButton = ({
  loading,
  handleClick,
  disabled,
  label = "Unarchive"
}) => {
  return (
    <div
      style={{ marginTop: "0.5rem", minWidth: "120px", minHeight: "36px" }}
      className="d-flex justify-content-center align-items-center"
    >
      <FormControlLabel
        control={
          loading ? (
            <CircularProgress size="1.3rem" />
          ) : (
            <ListButton
              label={label}
              disabled={disabled}
              onClick={handleClick}
              background="rgba(107, 194, 0, 0.3)"
              text="#6BC200"
            />
          )
        }
      />
    </div>
  );
};
