import moment from "moment";

export const modifySubmissionData = data => {
  return data.map(submission => {
    return {
      ...submission,
      created_at: moment(submission.created_at.split("T")[0]).format(
        "DD/MM/YYYY"
      ),
      contact_name:
        submission.contact.first_name + " " + submission.contact.last_name
    };
  });
};

export const isSortable = key =>
  ["id", "status", "code", "created_at"].includes(key);
