import React from "react";
import { Link } from "react-router-dom";
import { getStatusRowStyle, isLink, isStatus } from "../../../../ui/helpers";

export function renderCropsRow(headings, item) {
  return headings.map(([key]) => (
    <td
      key={key}
      className={`align-middle no-line border-0 px-5 py-5 ${isStatus(key) &&
        getStatusRowStyle(item)}`}
    >
      {isLink(key) ? (
        <Link className="text-dark" to={`/crops/${item.id}`}>
          {item[key]}
        </Link>
      ) : (
        item[key]
      )}
    </td>
  ));
}

export const sortHelper = (a, b) => {
  if (a.label > b.label) return 1;
  if (a.label < b.label) return -1;
  return 0;
};

export const sortPackageSizes = (a, b) => {
  if (a.name > b.name) return 1;
  if (a.name < b.name) return -1;
  return 0;
};

export const compareContacts = (originalContacts, newContacts) => {
  newContacts.forEach((contact, index) => {
    if (contact !== originalContacts[index]) {
      return false;
    }
  });
  return true;
};

export const isUnassigned = (status, key) => {
  if (status === "unassigned" && key === "status") {
    return true;
  } else return false;
};
