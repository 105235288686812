import React from "react";
import { Headers } from "../../../../ui/components/Headers";
import { Logo } from "../../../../ui/helpers/constants";

export const SuccessRedirect = () => {
  return (
    <>
      <div className="d-flex-column justify-content-center align-items-center">
        <Headers />
        <div className="d-flex-column justify-content-center align-items-center">
          <div className="d-flex justify-content-center align-items-center my-30">
            <div
              className="success-redirect"
              style={{
                backgroundImage: Logo,
                height: "60px",
                width: "310px",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "50% 50%"
              }}
            ></div>
          </div>
          <p
            className="text-center my-20 mx-5"
            style={{
              fontFamily: "Montserrat-Regular",
              fontWeight: "700",
              color: "#929292",
              fontSize: "1.3rem"
            }}
          >
            Thank you, your price submission has been successful.
          </p>
        </div>
      </div>
    </>
  );
};
