import config from "../../../../../config";

export const getSubscribers = (search = "", field, direction) =>
  fetch(
    `${config.ApiUrl.Rest}/subscriptions?${search && "&" + search}${(field &&
      "&order_by=" + field) ||
      ""}${(direction && "&order_dir=" + direction) || ""}`,
    {
      mode: "cors",
      credentials: "include"
    }
  );

export const getSubscriberById = subscriptionId =>
  fetch(`${config.ApiUrl.Rest}/subscriptions/${subscriptionId}`, {
    mode: "cors",
    credentials: "include"
  });

export const modifySubscriber = (subscriberData, subscriptionId) =>
  fetch(`${config.ApiUrl.Rest}/subscriptions/${subscriptionId}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(subscriberData),
    mode: "cors",
    credentials: "include"
  });

export const archiveSubscriber = subscriptionId =>
  fetch(`${config.ApiUrl.Rest}/subscriptions/${subscriptionId}`, {
    method: "DELETE",
    mode: "cors",
    credentials: "include"
  });
