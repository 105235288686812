import React, { useState } from "react";
import { Formik } from "formik";
import Tabs from "@bit/the-glue.frontendcomponents.tabs";
import { assignGroupToUser, getUserGroups, removeGroupFromUser } from "../_api";
import { useFetch } from "../../../../hooks/fetch.hook";
import { ListButton } from "../../../../ui/components/ListButton";
import { info } from "../../../../helpers/toasts";
import { MappedRadio } from "../../../../ui/components/Radio";
import { Loader } from "../../../../ui/components/Loader";
import { useHistory } from "react-router-dom";

export const UserPermissions = ({
  name,
  userGroups = [],
  groupsList = [],
  id,
  setUserGroups
}) => {
  const adminGroup = groupsList.find(({ type }) => type === "global_admin");
  const guestGroup = groupsList.find(({ name }) => name === "Guest");
  const history = useHistory();

  const userGroupsIds = userGroups.map(({ id }) => id);

  const { request } = useFetch();

  const [loading, setLoading] = useState(false);

  const permissionsList = [adminGroup, guestGroup];

  const globalPermissions = [adminGroup, guestGroup];

  const initialValues = {
    global_permission:
      (
        globalPermissions.find(permission =>
          userGroupsIds.includes((permission || {}).id)
        ) || {}
      ).id || ""
  };

  const handleReset = resetForm => () => {
    resetForm({ values: initialValues });
    history.goBack();
  };

  const handleSubmit = (values, { setSubmitting }) => {
    if (values.global_permission && values.tenant_permission) {
      info("User can't have Global and State permissions at the same time");
      setSubmitting(false);
    } else {
      setLoading(true);
      const groupsIDs = Object.values(values).filter(Boolean);
      const groupsToAdd = groupsIDs.map(id => ({ id }));
      const groupsToRemove = permissionsList
        .filter(group => !groupsIDs.includes((group || {}).id))
        .map(group => ({ id: (group || {}).id }));

      let promisePayload = [
        request(assignGroupToUser, groupsToAdd, id),
        request(removeGroupFromUser, groupsToRemove, id)
      ];

      Promise.all(promisePayload)
        .then(() => {
          request(getUserGroups, id)
            .then(data => {
              if (!data) return;
              info("User permissions have been updated!");
              setUserGroups(data);
            })
            .finally(() => setLoading(false));
        })
        .finally(() => setSubmitting(false));
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ handleSubmit, isSubmitting, values, resetForm }) => (
        <>
          {loading && <Loader title="Loading..." isOpen={loading} />}
          <div
            className="bg-white py-15 px-10"
            style={{
              border: "1px solid #E5E5E5",
              borderRadius: "4px",
              minWidth: "535px"
            }}
          >
            <div>
              <h3 className="mb-15">
                <strong>{name}</strong>
              </h3>
              <Tabs
                defaultActive="0"
                tabs={[
                  {
                    label: "Global",
                    tabPanel: (
                      <div className="border 1px solid #E5E5E5 pl-5 pt-10">
                        <MappedRadio
                          options={globalPermissions}
                          name="global_permission"
                          labelName="name"
                        />
                      </div>
                    )
                  }
                ]}
                background="#6BC200"
                text="white"
                selectedColor="#fff"
              />
            </div>
            <div className="mt-30 d-flex align-items-center justify-content-end">
              <div className="mt-10 text-right">
                <ListButton
                  label="Cancel"
                  size="large"
                  onClick={handleReset(resetForm)}
                  boxShadow={false}
                  className="mx-2"
                />
                <ListButton
                  label="Save"
                  size="large"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                  boxShadow={false}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};
