import config from "../../../../config";

export const getDashboardClients = () => {
  return fetch(`${config.ApiUrl.Rest}/organisations?count_only=y`, {
    mode: "cors",
    credentials: "include"
  });
};

export const getNonSubmissionDates = () => {
  return fetch(`${config.ApiUrl.Rest}/calendar`, {
    mode: "cors",
    credentials: "include"
  });
};

export const getSubmissions = status => {
  return fetch(
    `${config.ApiUrl.Rest}/submissions?count_only=y${
      status ? `&status=${status}` : ""
    }`,
    {
      mode: "cors",
      credentials: "include"
    }
  );
};

export const getReportData = () => {
  return fetch(`${config.ApiUrl.Rest}/dashboard/submissions`, {
    mode: "cors",
    credentials: "include"
  });
};
