import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";
import { MappedInput } from "../../../../../ui/components/Input";
import { getPackage } from "../_redux/selectors";
import { useFetch } from "../../../../../hooks/fetch.hook";
import { PackageDetailsSchema } from "../../../../../helpers/schemas";
import { ListButton } from "../../../../../ui/components/ListButton";
import { Loader } from "../../../../../ui/components/Loader";
import { getPackageById, modifyPackage, addPackage } from "../_api/index";
import { getCrops, getPackageSizes } from "../../../Crops/CropsManagement/_api";
import { MappedSelect } from "@bit/the-glue.frontendcomponents.select";
import { Switch } from "@material-ui/core";
import { uniqBy } from "lodash";

export const Card = ({
  match: {
    params: { id }
  }
}) => {
  const history = useHistory();
  const { request } = useFetch();
  const data = useSelector(getPackage(id));

  const [loading, setLoading] = useState(false);
  const [cropLoading, setCropLoading] = useState(false);
  const [cardData, setCardData] = useState({});
  const [cropOptions, setCropOptions] = useState([]);
  const [isNewPackage, setNewPackage] = useState(false);

  const initialValues = {
    name: cardData.name || "",
    new_crop_name: cardData.name || "",
    quantity: cardData.quantity || "",
    measure: cardData.measure || "",
    custom_name: isNewPackage
  };

  useEffect(() => {
    setCropLoading(true);
    if (id && !data) {
      Promise.all([
        request(getPackageById, id),
        request(getCrops),
        request(getPackageSizes)
      ])
        .then(([packageSize, crops, allPackages]) => {
          if (!packageSize) return;
          setCardData(packageSize);
          if (!crops) return;
          setCropOptions(generateCropOptions(crops, packageSize, allPackages));
        })
        .finally(() => setCropLoading(false));
    } else {
      data && setCardData(data);
      Promise.all([request(getCrops), request(getPackageSizes)])
        .then(([crops, allPackages]) => {
          setCropOptions(generateCropOptions(crops, data, allPackages));
        })
        .finally(() => setCropLoading(false));
    }
    // eslint-disable-next-line
  }, []);

  const generateCropOptions = (crops, packageSize, allPackages) => {
    let optionArray = crops.map(crop => ({
      value: crop.name,
      label: crop.name
    }));
    if (packageSize) {
      if (!optionArray.some(option => option.name === packageSize.name)) {
        optionArray.push({
          value: packageSize.name,
          label: packageSize.name
        });
      }
    }
    if (allPackages) {
      allPackages.forEach(element => {
        optionArray.push({
          value: element.name,
          label: element.name
        });
      });
    }
    return uniqBy(optionArray, "label");
  };

  const handleSwitch = values => {
    if (isNewPackage) {
      setNewPackage(false);
      values.custom_name = false;
    } else {
      setNewPackage(true);
      values.custom_name = true;
    }
  };

  const handleSubmit = values => {
    setLoading(true);
    let payload = {};
    if (values.name === "") {
      payload = {
        name: values.new_crop_name,
        measure: values.measure,
        quantity: values.quantity
      };
    } else {
      payload = {
        ...values
      };
    }
    request(id ? modifyPackage : addPackage, payload, id)
      .then(data => data && history.push("/settings/package_sizes"))
      .finally(() => setLoading(false));
  };

  const handleClose = () => history.goBack();

  if ((id && !Object.keys(cardData).length) || cropLoading) {
    return <Skeleton variant="rect" width={"100%"} height={389.7} />;
  }

  return (
    <div
      className="bg-white p-10"
      style={{ border: "1px solid #E5E5E5", borderRadius: "4px" }}
    >
      {loading && <Loader isOpen={loading} maxWidth="xs" title="Loading..." />}
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={PackageDetailsSchema}
      >
        {({ handleSubmit, values }) => (
          <>
            <h3 className="mb-10">
              <strong>{id ? cardData.name : "Add Package"}</strong>
            </h3>
            <div className="row justify-content-between">
              <div className="col-8">
                {isNewPackage ? (
                  <MappedInput
                    label="Crop Name"
                    name="new_crop_name"
                    color="#6BC200"
                    disabled={!isNewPackage}
                  />
                ) : (
                  <MappedSelect
                    label="Crop Name"
                    options={cropOptions}
                    name="name"
                    color="#6BC200"
                    variant="filled"
                    disabled={isNewPackage}
                  />
                )}
                <MappedInput label="Quantity" name="quantity" color="#6BC200" />
                <MappedInput label="Measure" name="measure" color="#6BC200" />
              </div>
            </div>

            <div className="mt-10 text-right">
              <div className="d-flex align-items-center justify-content-end mb-2">
                <p className="mr-2 mt-3">New Crop Package</p>
                <Switch
                  checked={isNewPackage}
                  onChange={() => handleSwitch(values)}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              </div>
              <ListButton
                label="Cancel"
                size="large"
                onClick={handleClose}
                boxShadow={false}
                className="mx-2"
              />
              <ListButton
                label="Save"
                size="large"
                onClick={handleSubmit}
                boxShadow={false}
              />
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};
