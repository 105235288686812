export const discountOptions = [
  {
    value: "forever",
    label: "Lifetime Discount"
  },
  {
    value: "once",
    label: "Discount on the next invoice"
  },
  {
    value: "repeating",
    label: "Discount for given number of months"
  }
];

export const durationInMonths = [
  { value: 1, label: "1 month" },
  { value: 2, label: "2 months" },
  { value: 3, label: "3 months" },
  { value: 4, label: "4 months" },
  { value: 5, label: "5 months" },
  { value: 6, label: "6 months" },
  { value: 7, label: "7 months" },
  { value: 8, label: "8 months" },
  { value: 9, label: "9 months" },
  { value: 10, label: "10 months" },
  { value: 11, label: "11 months" },
  { value: 12, label: "12 months" }
];
