export const reportFormats = [
  { value: "csv", label: "CSV" },
  { value: "pdf", label: "PDF" }
];

export const dayOfWeeks = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday"
];
