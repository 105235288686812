import React from "react";
import { Select as MuiSelect, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    textAlign: "center !important",
  },
});

export default function Select({
  options = [],
  defaultValue = "",
  setCountry,
}) {
  const classes = useStyles();
  return (
    <>
      <MuiSelect
        className="crop-region-country"
        defaultValue={defaultValue}
        disableUnderline={true}
        classes={{ root: classes.root }}
        onChange={(event) => {
          setCountry(event.target.value);
        }}
      >
        {options.map(({ value, label }) => (
          <MenuItem key={value} value={value} children={label} />
        ))}
      </MuiSelect>
    </>
  );
}
