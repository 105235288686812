import { styled } from "@material-ui/styles";
import React from "react";

const IconRoot = styled("div")(({ ownerstate }) => ({
  backgroundColor: "#B0B0B0",
  color: "#fff",
  fontSize: "1.2rem",
  minWidth: 28,
  minHeight: 28,
  padding: 0,
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerstate.active && {
    backgroundColor: "#6BC200",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)"
  }),
  ...(ownerstate.completed && {
    backgroundColor: "#6BC200",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)"
  })
}));

export const StepIcon = ({ active, completed, icon }) => {
  return <IconRoot ownerstate={{ completed, active }}>{icon}</IconRoot>;
};
