export const HEADINGS = [
  ["name", "Name"],
  ["variety", "Variety"],
  ["grade", "Grade"],
  ["package_size", "Package Size"],
  ["low", "Low"],
  ["high", "High"],
  ["best", "Best"],
  ["location", "Location"],
  ["supply", "Supply"],
  ["demand", "Demand"],
  ["carryover", "Carryover"],
  ["quality", "Quality"],
  ["contact", "Contact"],
  ["edit", "Edit"]
];
