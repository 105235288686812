import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Headers } from "../../../../ui/components/Headers";
import { mobileStepValues, stepLabels } from "./constants";
import {
  DesktopStepper,
  MobileStepper
} from "../../../../ui/components/Stepper";
import {
  Landing,
  Payment,
  PersonalDetails,
  PlanDetails,
  Summary
} from "./Steps";

export const SignUpStepper = () => {
  const { search } = useLocation();
  const [activeStep, setActiveStep] = useState(0);
  const [payload, setPayload] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    company_name: "",
    company_abn: "",
    is_company: false,
    postcode: "",
    address: "",
    state: "",
    city: "",
    monthly_report: false,
    report_format: "pdf",
    veg_report_days: [],
    fruit_report_days: [],
    client_secret: "",
    monthly_subscription_fee: 0,
    pro_ration: 0
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    if (searchParams.get("redirect_status") === "succeeded") {
      setActiveStep(4);
    }
  }, [search]);

  const handleNext = () => setActiveStep(activeStep + 1);
  const handleBack = () => setActiveStep(activeStep - 1);
  const getStepContent = () => steps[activeStep];

  const steps = [
    <Landing handleNext={handleNext} />,
    <PersonalDetails
      payload={payload}
      setPayload={setPayload}
      handleNext={handleNext}
      handleBack={handleBack}
    />,
    <PlanDetails
      payload={payload}
      setPayload={setPayload}
      handleNext={handleNext}
      handleBack={handleBack}
    />,
    <Payment
      payload={payload}
      setPayload={setPayload}
      handleNext={handleNext}
      handleBack={handleBack}
    />,
    <Summary
      payload={payload}
      setPayload={setPayload}
      handleBack={handleBack}
    />
  ];

  return (
    <>
      <div className="stepper-wrapper overflow-auto">
        <Headers title="Reports" label="Reports" color="#6BC200" />
        <DesktopStepper steps={steps} activeStep={activeStep} />
        <div className="d-flex d-md-none justify-content-between align-items-center mx-10 my-5 p-5 mobile-stepper-header">
          <MobileStepper
            value={mobileStepValues[activeStep]}
            label={`${activeStep + 1} of ${steps.length}`}
          />
          <div className="">
            <h1 className="text-right stepper-title">
              {stepLabels[activeStep]}
            </h1>
            <h4 className="text-right stepper-description">
              {activeStep === 4
                ? "Final Step"
                : `Next: ${stepLabels[activeStep + 1]}`}
            </h4>
          </div>
        </div>
        <div className="stepper-card mx-10 mb-5 px-10 py-5">
          {getStepContent()}
        </div>
      </div>
    </>
  );
};
