import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { organisationsReducer } from "../app/modules/Organisations/_redux/reducer";
import { usersReducer } from "../app/modules/Users/_redux/reducer";
import { tagsReducer } from "../app/modules/Settings/Tags/_redux/reducer";
import { cropsReducer } from "../app/modules/Crops/CropsManagement/_redux/reducer";
import { contactsReducer } from "../app/modules/Contacts/_redux/reducer";
import { packagesReducer } from "../app/modules/Settings/PackageSizes/_redux/reducer";
import { submissionsReducer } from "../app/modules/Submissions/_redux/reducer";
import { subCategoryReducer } from "../app/modules/Settings/Subcategories/_redux/reducer";
import { subscribersReducer } from "../app/modules/Subscription/Management/_redux/reducer";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  organisations: organisationsReducer,
  users: usersReducer,
  tags: tagsReducer,
  crops: cropsReducer,
  contacts: contactsReducer,
  packages: packagesReducer,
  submissions: submissionsReducer,
  sub_categories: subCategoryReducer,
  subscribers: subscribersReducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
