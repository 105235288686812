import React, { useState } from "react";
import { useFormikContext } from "formik";
import { useEffect } from "react";
import { Tooltip } from "@material-ui/core";
import { Error as ErrorIcon } from "@material-ui/icons";

export const Selector = ({
  labels,
  title,
  value,
  name,
  handleSubmit,
  classes,
  wrapperClasses = "d-flex-column justify-content-center align-items-center",
  errors,
  changeEvent
}) => {
  const [option, setOption] = useState(value);
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (handleSubmit) handleSubmit(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [option]);

  return (
    <div>
      <div className={wrapperClasses}>
        <div className="crop-edit-selector-text">
          {title}
          {errors && (
            <Tooltip
              title={
                <p
                  style={{
                    fontSize: "10px",
                    margin: 0
                  }}
                >
                  {errors}
                </p>
              }
              placement="right"
            >
              <ErrorIcon
                style={{
                  color: "red",
                  fontSize: "20px",
                  marginLeft: "5px"
                }}
              />
            </Tooltip>
          )}
        </div>
        <div className="d-flex justify-content-center align-items-center">
          {labels.map((label, index) => (
            <button
              key={index}
              id={name}
              type="button"
              value={index}
              className={`${classes} ${
                labels[value] === label ? "is-selected" : ""
              }`}
              onClick={e => {
                if (changeEvent) {
                  changeEvent(label);
                }
                setFieldValue(name, parseInt(e.target.value));
                setOption(e.target.value);
              }}
            >
              {label}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};
