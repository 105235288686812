import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Tabs from "@bit/the-glue.frontendcomponents.tabs";
import Skeleton from "@material-ui/lab/Skeleton";
import { OrganisationDetails } from "./OrganisationDetails";
import { getOrganisation } from "../_redux/selectors";
import { Contacts } from "../../../submodules/Contacts";
import { OrganisationUsers } from "../../../submodules/OrganisationUsers";
import { useFetch } from "../../../../hooks/fetch.hook";
import { modifyOrganisationData } from "../helpers";
import { List as Submissions } from "./Submissions";
import { List } from "../../Crops/CropsManagement";
import { useLocation } from "react-router-dom";
import {
  getOrganisationById,
  getOrganisationTags,
  getSimpleOrganisations
} from "../_api";

export const Card = ({
  match: {
    params: { id }
  }
}) => {
  const { request } = useFetch();

  const data = useSelector(getOrganisation(id));

  const [cardData, setCardData] = useState({});
  const [organisations, setOrganisations] = useState([]);
  const [activeOrgtab, setActiveOrgTab] = useState("0");

  const { legal_name } = cardData || "";
  const location = useLocation();

  useEffect(() => {
    if (!data) {
      Promise.all([
        request(getOrganisationById, id),
        request(getOrganisationTags, id)
      ]).then(([organisation, tags]) => {
        if (!organisation) return;
        setCardData({
          ...modifyOrganisationData(organisation),
          tags
        });
      });
    } else {
      request(getOrganisationTags, id).then(
        tags => tags && setCardData({ ...data, tags })
      );
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    request(getSimpleOrganisations).then(
      data => data && setOrganisations(data)
    );
    // eslint-disable-next-line
  }, []);

  if (id && !Object.keys(cardData).length) {
    return <Skeleton variant="rect" width={"100%"} height={520} />;
  }

  const tabs = [
    {
      label: "Profile",
      tabPanel: (
        <OrganisationDetails
          name={legal_name}
          data={cardData}
          id={id}
          simpleOrganisations={organisations}
          setCardData={setCardData}
        />
      )
    },
    {
      label: "Contacts",
      tabPanel: (
        <Contacts
          name={legal_name}
          organisationId={id}
          simpleOrganisations={organisations}
        />
      )
    },
    {
      label: "Submissions",
      tabPanel: <Submissions id={id} />
    },
    {
      label: "Crops",
      tabPanel: (
        <List
          id={id}
          location={location.pathname}
          setActiveOrgTab={setActiveOrgTab}
          orgTabNumber="3"
          is_organisation={true}
        />
      )
    },
    {
      label: "Linked Users",
      tabPanel: <OrganisationUsers organisationId={id} />
    }
  ];

  return (
    <div className="mt-10">
      <Tabs
        defaultActive={activeOrgtab}
        tabs={tabs}
        align="right"
        background="#6BC200"
        text="white"
      />
    </div>
  );
};
