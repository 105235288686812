export const HEADINGS = [
  ["first_name", "First Name"],
  ["last_name", "Last Name"],
  ["position_title", "Position"],
  ["phone", "Phone"],
  ["mobile", "Mobile"],
  ["email", "Email"],
  ["status", "Status"],
  ["id", "Crop List"]
];

export const searchMap = {
  "First Name": "first_name",
  "Last Name": "last_name",
  Email: "email",
  Phone: "phone",
  Mobile: "mobile"
};

export const reportFormatOptions = [
  { value: "csv", label: "CSV" },
  { value: "pdf", label: "PDF" }
];
