import React, { useState, useEffect, useCallback } from "react";
import config from "../../../../../config";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";
import { Slider, IconButton } from "@material-ui/core";
import { MappedInput } from "../../../../../ui/components/Input/index";
import { getCrop } from "../_redux/selectors";
import { TagsField } from "../../../../../ui/structures/TagsForm/TagsField";
import { Modal } from "../../../../../ui/components/Modal";
import { TagsAutocomplete } from "../../../../../ui/structures/TagsForm/TagsAutocomplete";
import { AutocompleteMultiple } from "../../../../../ui/components/AutocompleteMultiple";
import { useFetch } from "../../../../../hooks/fetch.hook";
import { getCropTags, getSpecificCropTags } from "../../../Settings/Tags/_api";
import { Loader } from "../../../../../ui/components/Loader";
import { ListButton } from "../../../../../ui/components/ListButton";
import { sortHelper, sortPackageSizes } from "../helpers";
import { MappedSelect } from "@bit/the-glue.frontendcomponents.select";
import { modifySubCategoryList } from "../../../Settings/Subcategories/helpers";
import { getSubCategories } from "../../../Settings/Subcategories/_api";
import { CropDetailsSchema } from "../../../../../helpers/schemas";
import { error, info } from "../../../../../helpers/toasts";
import { getCroppedImg } from "../canvasUtils";
import { uploadFile } from "../../../AWS-S3/upload";
import {
  ZoomOutMap as ZoomOutMapIcon,
  CropRotate as CropRotateIcon,
  Delete as DeleteIcon
} from "@material-ui/icons";
import {
  addCrop,
  deleteCropTags,
  editCropTags,
  getCropById,
  modifyCrop,
  getContact,
  updateCropContact,
  getPackageSizes,
  savePackageSizes,
  filterSubCategories
} from "../_api";
import Dropzone from "react-dropzone";
import Cropper from "react-easy-crop";
import { MappedCheckbox } from "../../../../../ui/components/Checkbox";

export const CropDetails = ({
  id,
  contactOptions = [],
  parentCategories = [],
  stateOptions = []
}) => {
  const { request } = useFetch();
  const data = useSelector(getCrop(id));
  const history = useHistory();

  const [cardData, setCardData] = useState({});
  const [categoryDetails, setCategoryDetails] = useState({});
  const [subCategories, setSubCategories] = useState([]);
  const [filter, setFilter] = useState();
  const [tags, setTags] = useState([]);
  const [linkedContacts, setLinkedContacts] = useState([]);
  const [packages, setPackages] = useState([]);
  const [packageFilter, setPackageFilter] = useState("");

  const [modalOpen, setModalOpen] = useState(false);
  const [packageModalOpen, setPackageModalOpen] = useState(false);
  const [isNewCrop, setIsNewCrop] = useState(false);

  const [packageLoading, setPackageLoading] = useState(false);
  const [cropLoading, setCropLoading] = useState(false);
  const [categoryLoading, setCategoryLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(true);

  const [fileData, setFileData] = useState([]);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const handlePackageModalOpen = () => setPackageModalOpen(true);
  const handlePackageModalClose = () => setPackageModalOpen(false);

  useEffect(() => {
    request(getCropTags).then(cropTags => {
      cropTags && setTags(cropTags.sort(sortHelper));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (id && !data) {
      setCropLoading(true);
      setCategoryLoading(true);
      Promise.all([
        request(getCropById, id),
        request(getSpecificCropTags, id),
        request(getContact, id)
      ])
        .then(([details, tags, contacts]) => {
          if (!details) return;
          setCardData({ ...details, tags });
          setCategoryDetails({ ...details.sub_categories });
          if (details.sub_categories) {
            getFilteredSubCategories(details.sub_categories.categories.id);
          }
          if (contacts.length === 0) return;
          setLinkedContacts(contacts.map(contact => contact.id));
        })
        .finally(() => {
          setCropLoading(false);
          setCategoryLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (id && data) {
      setCropLoading(true);
      setCategoryLoading(true);
      Promise.all([request(getSpecificCropTags, id), request(getContact, id)])
        .then(([tags, contacts]) => {
          setCardData({ ...data, tags });
          if (data.sub_categories) {
            setCategoryDetails(data.sub_categories);
            getFilteredSubCategories("all");
          }
          if (contacts.length === 0) return;
          setLinkedContacts(contacts.map(contact => contact.id));
        })
        .finally(() => {
          setCropLoading(false);
          setCategoryLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!id && !data) {
      setCropLoading(true);
      setCategoryLoading(true);
      setIsNewCrop(true);
      request(getPackageSizes)
        .then(response => {
          if (!response) return;
          setPackages(response.sort(sortPackageSizes));
        })
        .finally(() => {
          info("If you haven't already done, please add a pack size first!");
          setCropLoading(false);
          setCategoryLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filter !== "" && filter) {
      getFilteredSubCategories(filter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    getFilteredPackageSizes(packageFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packageFilter]);

  useEffect(() => {
    fileData.length && showCroppedImage();
    // eslint-disable-next-line
  }, [fileData, croppedAreaPixels, rotation]);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage_current = await getCroppedImg(
        fileData[0],
        croppedAreaPixels,
        rotation
      );

      fetch(croppedImage_current)
        .then(response => response.blob())
        .then(blob => {
          let file_current = new File(
            [blob],
            id ? `${cardData.id}_${cardData.name}.png` : "crop.png",
            {
              type: "image/png"
            }
          );
          Object.assign(file_current, {
            preview: URL.createObjectURL(file_current)
          });
          setCroppedImage(file_current);
        });
    } catch (e) {
      console.error(e);
    }
    // eslint-disable-next-line
  }, [fileData, croppedAreaPixels, rotation]);

  const getFilteredSubCategories = filter => {
    setCategoryLoading(true);
    if (filter === "all") {
      request(getSubCategories)
        .then(response => {
          if (!response) return;
          setSubCategories(modifySubCategoryList(response));
        })
        .finally(() => setCategoryLoading(false));
    } else {
      request(filterSubCategories, filter)
        .then(response => {
          if (!response) return;
          setSubCategories(modifySubCategoryList(response));
        })
        .finally(() => setCategoryLoading(false));
    }
  };

  const getFilteredPackageSizes = () => {
    setPackageLoading(true);
    request(getPackageSizes, packageFilter)
      .then(packageData => {
        if (!packageData || packageData.length === 0) return;
        setPackages(packageData.sort(sortPackageSizes));
      })
      .finally(() => setPackageLoading(false));
  };

  const saveUrlFieldsValues = value => {
    request(modifyCrop, { image_url: value }, id);
  };

  const initialValues = {
    name: cardData?.name || "",
    variety: cardData?.variety || "",
    grade: cardData?.grade || "",
    image_url: cardData?.image_url || "",
    tags: id ? (cardData.tags || []).map(({ id }) => id) : [],
    contact: linkedContacts,
    parent_category:
      categoryDetails.category_id === null ? "" : categoryDetails.category_id,
    sub_category: categoryDetails.id === null ? "" : categoryDetails.id,
    package_sizes: [],
    newCrop: isNewCrop ? true : false,
    hide_states: cardData?.hide_states?.map(state => state.id) || [],
    hide_all:
      cardData?.hide_states?.length === stateOptions.length &&
      cardData?.hide_states?.length !== 0
        ? true
        : false
  };

  const createCrop = (values, contactArr, packagePayload) => {
    const cropPayload = {
      name: values.name,
      grade: values.grade,
      variety: values.variety,
      image_url: values.image_url,
      sub_category_id: values.sub_category,
      status: "active"
    };
    setLoading(true);
    request(addCrop, cropPayload)
      .then(cropResponse => {
        if (!cropResponse) return;
        Promise.all([
          request(updateCropContact, cropResponse.id, contactArr),
          request(savePackageSizes, cropResponse.id, packagePayload)
        ]);
        if (values.tags.length) {
          return request(
            editCropTags,
            cropResponse.id,
            values.tags.map(tagID => ({ id: tagID }))
          );
        } else return data;
      })
      .finally(() => {
        setLoading(false);
        setIsNewCrop(false);
        history.goBack();
        info("Crop has been added!");
      });
  };

  const updateCrop = (values, contactArr, statesPayload) => {
    const cropPayload = {
      name: values.name,
      grade: values.grade,
      variety: values.variety,
      image_url: values.image_url,
      sub_category_id: values.sub_category,
      status: cardData.package_sizes.length > 0 ? "active" : "unassigned",
      hide_state_ids: statesPayload
    };
    setLoading(true);
    Promise.all([
      request(updateCropContact, id, contactArr),
      request(modifyCrop, cropPayload, id),
      editTagsRequest(values)
    ])
      .then(([updateResponseData, cropResponseData, tagsResponseData]) => {
        if (!cropResponseData || !tagsResponseData || !updateResponseData) {
          return;
        } else {
          info("Details have been updated!");
        }
      })
      .finally(() => {
        history.goBack();
        setLoading(false);
      });
  };

  const editTagsRequest = values => {
    if (values.tags.length) {
      return request(
        editCropTags,
        id,
        values.tags.map(tagID => ({ id: tagID }))
      );
    } else if (values.tags.length === 0) {
      return request(deleteCropTags, id, cardData.tags);
    } else return "success";
  };

  const handleSubmit = values => {
    const contactArr = values.contact.map(contact => ({ id: contact }));
    const packagePayload = values.package_sizes.map(size => ({
      id: size
    }));
    if (id) {
      updateCrop(
        values,
        contactArr,
        values.hide_all ? "all" : values.hide_states
      );
    } else {
      createCrop(values, contactArr, packagePayload);
    }
    let file = croppedImage || undefined;
    id && file && uploadFile("crop_images", id, file, saveUrlFieldsValues);
    setFileData([]);
  };

  const handleClose = () => history.goBack();

  if ((id && !Object.keys(cardData).length) || cropLoading) {
    return <Skeleton variant="rect" width={"100%"} height={480.14} />;
  }

  return (
    <>
      {loading && <Loader isOpen={loading} maxWidth="xs" title="Loading..." />}
      <div
        className="bg-white p-10"
        style={{ border: "1px solid #E5E5E5", borderRadius: "4px" }}
      >
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={CropDetailsSchema}
        >
          {({ handleSubmit, values, setFieldValue, errors, touched }) => (
            <>
              {modalOpen && (
                <Modal
                  maxWidth="sm"
                  isOpen={modalOpen}
                  submitable
                  onClose={handleModalClose}
                  modalContent={
                    <TagsAutocomplete
                      name="tags"
                      placeholder="Select Tags"
                      setValue={setFieldValue}
                      options={tags.filter(
                        item => !values.tags.includes(item.id)
                      )}
                      loading={!tags.length}
                      onClose={handleModalClose}
                      currentValue={values.tags}
                      multiple
                    />
                  }
                />
              )}
              {packageModalOpen && !packageLoading && (
                <Modal
                  maxWidth="sm"
                  isOpen={packageModalOpen}
                  submitable
                  onClose={handlePackageModalClose}
                  modalContent={
                    <TagsAutocomplete
                      name="package_sizes"
                      placeholder="Package Sizes"
                      setValue={setFieldValue}
                      options={packages.filter(
                        item => !values.package_sizes.includes(item.id)
                      )}
                      loading={!packages.length}
                      onClose={handlePackageModalClose}
                      currentValue={values.package_sizes}
                      multiple
                    />
                  }
                />
              )}
              <h3 className="mb-10">
                <strong>{id ? cardData.name : "Add Crop"}</strong>
              </h3>
              <div className="row justify-content-between">
                <div className="col-9">
                  <div className="mb-5">
                    <MappedInput
                      label="Crop Name"
                      name="name"
                      data-test="crop-name"
                      color="#6BC200"
                      setPackageFilter={setPackageFilter}
                    />
                  </div>
                  <div className="mb-5">
                    <MappedInput
                      label="Grade"
                      name="grade"
                      color="#6BC200"
                      data-test="crop-grade"
                    />
                  </div>
                  <div className="mb-5">
                    <MappedInput
                      label="Variety"
                      color="#6BC200"
                      name="variety"
                      data-test="crop-variety"
                    />
                  </div>
                  <div className="mb-5">
                    <MappedSelect
                      name="parent_category"
                      label="Parent Category"
                      value={values.parent_category}
                      color="#6BC200"
                      options={parentCategories}
                      onChange={e => {
                        setFieldValue("parent_category", e.target.value);
                        setFilter(e.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-5">
                    {categoryLoading ? (
                      <Skeleton variant="rect" width={"100%"} height={54.22} />
                    ) : (
                      <MappedSelect
                        name="sub_category"
                        label="Sub Category"
                        value={values.sub_category}
                        color="#6BC200"
                        options={subCategories}
                      />
                    )}
                  </div>
                  {contactOptions.length ? (
                    <div className="mt-3">
                      <AutocompleteMultiple
                        name="contact"
                        placeholder="Contacts"
                        color="#6BC200"
                        setValue={setFieldValue}
                        options={contactOptions.sort(sortHelper)}
                        defaultValueField="value"
                        defaultValue={values.contact}
                        variant="standard"
                        data-test="crop-contact"
                      />
                      {touched.contact && errors.contact ? (
                        <div className="text-danger">{errors.contact}</div>
                      ) : null}
                    </div>
                  ) : (
                    <div className="mt-3 col-8">
                      <Skeleton variant="rect" width={"100%"} height={55} />
                    </div>
                  )}
                  <div className="">
                    {id ? (
                      stateOptions.length > 0 ? (
                        <>
                          <div className="mt-3 w-100">
                            <AutocompleteMultiple
                              name="hide_states"
                              placeholder="Hide Crop in the following states"
                              color="#6BC200"
                              setValue={setFieldValue}
                              disabled={values.hide_all}
                              currentSelectedValues={values.hide_states}
                              options={stateOptions}
                              defaultValueField="value"
                              defaultValue={values.hide_states}
                              variant="standard"
                            />
                            {touched.hide_states && errors.hide_states ? (
                              <div className="text-danger">
                                {errors.hide_states}
                              </div>
                            ) : null}
                          </div>
                          <div className="p-4">
                            <MappedCheckbox
                              name="hide_all"
                              label="Hide in all states"
                              checked={values.hide_all}
                            />
                          </div>
                        </>
                      ) : (
                        <div className="mt-3 col-8">
                          <Skeleton variant="rect" width={"100%"} height={55} />
                        </div>
                      )
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="col-3">
                  <TagsField
                    label="Tags"
                    tags={values.tags}
                    predefinedTags={tags}
                    handleAdd={handleModalOpen}
                    setValue={setFieldValue}
                  />
                  {isNewCrop && !packageLoading && (
                    <TagsField
                      label="Package Sizes"
                      name="package_sizes"
                      tags={values.package_sizes}
                      predefinedTags={packages}
                      handleAdd={handlePackageModalOpen}
                      setValue={setFieldValue}
                    />
                  )}
                  {errors.package_sizes && (
                    <span className="text-warning">{errors.package_sizes}</span>
                  )}
                  {id ? (
                    <div className="w-100 pr-5">
                      <h5 className="d-flex justify-content-between align-items-center mb-6 mt-3">
                        <strong>Preview</strong>
                        {values?.image_url ||
                        fileData.length ||
                        croppedImage ? (
                          <IconButton
                            onClick={() => {
                              fileData.length
                                ? setFileData([])
                                : croppedImage
                                ? setCroppedImage(null)
                                : setFieldValue("image_url", "");
                            }}
                            aria-label="delete"
                            className="crop-upload-icon"
                          >
                            <DeleteIcon
                              fontSize="inherit"
                              style={{ color: "red" }}
                            />
                          </IconButton>
                        ) : (
                          ""
                        )}
                      </h5>
                      <Dropzone
                        maxFiles={1}
                        maxSize={5000000}
                        accept={["image/*"]}
                        disabled={fileData.length}
                        onDropRejected={event =>
                          event.map(({ errors, file }) =>
                            error(`${file?.name} - ${errors[0]?.message}`)
                          )
                        }
                        onDrop={acceptedFiles => {
                          setFileData(
                            acceptedFiles.map(file =>
                              Object.assign(file, {
                                preview: URL.createObjectURL(file)
                              })
                            )
                          );
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div
                              {...getRootProps()}
                              className="crop-upload-wrapper"
                            >
                              <input {...getInputProps()} />
                              {fileData.length ? (
                                <>
                                  <div className="crop-upload-cropper-wrapper">
                                    <div className="crop-upload-cropper">
                                      <Cropper
                                        style={{ background: "#fff" }}
                                        image={fileData[0].preview}
                                        crop={crop}
                                        zoom={zoom}
                                        rotation={rotation}
                                        aspect={1 / 1}
                                        onCropChange={setCrop}
                                        onCropComplete={onCropComplete}
                                        onRotationChange={setRotation}
                                        onZoomChange={setZoom}
                                      />
                                    </div>
                                    <div style={{ marginTop: 10 }}>
                                      <div className="d-flex align-items-center">
                                        <ZoomOutMapIcon
                                          color="primary"
                                          className="mr-5"
                                        />
                                        <Slider
                                          value={zoom}
                                          min={1}
                                          max={3}
                                          step={0.1}
                                          aria-labelledby="Zoom"
                                          onChange={(e, zoom) => setZoom(zoom)}
                                        />
                                      </div>
                                      <div className="d-flex align-items-center">
                                        <CropRotateIcon
                                          color="primary"
                                          className="mr-5"
                                        />
                                        <Slider
                                          value={rotation}
                                          min={0}
                                          max={360}
                                          step={1}
                                          aria-labelledby="Rotation"
                                          onChange={(e, rotation) =>
                                            setRotation(rotation)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  {values?.image_url || croppedImage ? (
                                    <>
                                      {imageLoading ? (
                                        <Skeleton
                                          variant="rect"
                                          width={210}
                                          height={210}
                                          style={{ margin: "0 auto 20px" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      <picture>
                                        <img
                                          src={
                                            croppedImage
                                              ? croppedImage.preview
                                              : `${config.ApiUrl.Rest}/${values?.image_url}`
                                          }
                                          alt={id ? cardData.name : "Add Crop"}
                                          loading={"eager"}
                                          onLoad={() => setImageLoading(false)}
                                          style={{
                                            display: imageLoading
                                              ? "none"
                                              : "block",
                                            width: 210,
                                            height: "auto",
                                            margin: "0 auto 20px"
                                          }}
                                        />
                                      </picture>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  <p>
                                    Drag 'n' drop some files here, or click to
                                    select files
                                  </p>
                                </>
                              )}
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="mt-10 text-right">
                <ListButton
                  label="Cancel"
                  size="large"
                  onClick={handleClose}
                  boxShadow={false}
                  className="mx-2"
                />
                <ListButton
                  label="Save"
                  size="large"
                  onClick={handleSubmit}
                  boxShadow={false}
                />
              </div>
            </>
          )}
        </Formik>
      </div>
    </>
  );
};
