import React from "react";
import { Formik } from "formik";
import { ListButton } from "../../../../ui/components/ListButton";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { useFetch } from "../../../../hooks/fetch.hook";
import { CircularProgress } from "@material-ui/core/";
import { modifyCurrentUser } from "../../Auth/_api";
import { userProfileSchema } from "../../../../helpers/schemas";
import { info } from "../../../../helpers/toasts";

export const AccountInfo = ({ user }) => {
  const { request } = useFetch();

  const { first_name, last_name, phone_number, email } = user || {};

  const initialValues = {
    first_name: first_name || "",
    last_name: last_name || "",
    phone_number: phone_number || "",
    email: email || ""
  };

  const handleSubmit = (values, { setSubmitting }) => {
    request(modifyCurrentUser, {
      phone_number: values.phone_number,
      name: [values.first_name, values.last_name].join(" ")
    })
      .then(data => data && info("User information has been updated"))
      .finally(() => setSubmitting(false));
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={userProfileSchema}
    >
      {({ handleSubmit, isSubmitting }) => (
        <>
          <div className="bg-white p-8 rounded">
            <div className="row">
              <div className="col col-lg-12">
                <form>
                  <h3 className="mb-10">
                    <strong>Account Information</strong>
                  </h3>
                  <div className="row mb-5">
                    <div className="col-6">
                      <MappedInput
                        name="first_name"
                        label="First Name"
                        color="#6BC200"
                        variant="filled"
                      />
                    </div>
                    <div className="col-6">
                      <MappedInput
                        name="last_name"
                        label="Last Name"
                        color="#6BC200"
                        variant="filled"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <MappedInput
                        name="phone_number"
                        label="Phone Number"
                        color="#6BC200"
                        variant="filled"
                      />
                    </div>
                    <div className="col-6">
                      <MappedInput
                        name="email"
                        label="Email"
                        color="#6BC200"
                        variant="filled"
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-end mt-5">
                    <div className="d-flex justify-content-between align-items-center">
                      {isSubmitting && (
                        <div className="mx-2">
                          <CircularProgress color="primary" size={20} />
                        </div>
                      )}
                      <ListButton
                        label={isSubmitting ? "Updating..." : "Save"}
                        size="large"
                        onClick={handleSubmit}
                        disabled={isSubmitting}
                        data-testid="cancel"
                        boxShadow={false}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};
