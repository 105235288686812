import config from "../../../../config";

export const addContact = data =>
  fetch(`${config.ApiUrl.Rest}/contacts`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include"
  });

export const getContacts = (search = "", archived = "", field, direction) =>
  fetch(
    `${config.ApiUrl.Rest}/contacts?${search}${(archived && "&status=all") ||
      ""}${(field && "&order_by=" + field) || ""}${(direction &&
      "&order_dir=" + direction) ||
      ""}`,
    {
      mode: "cors",
      credentials: "include"
    }
  );

export const getContactById = contact_id =>
  fetch(`${config.ApiUrl.Rest}/contacts/${contact_id}`, {
    mode: "cors",
    credentials: "include"
  });

export const getContactSettings = contact_id =>
  fetch(`${config.ApiUrl.Rest}/contacts/${contact_id}/price_reports`, {
    mode: "cors",
    credentials: "include"
  });

export const modifyContact = (contact_id, data) =>
  fetch(`${config.ApiUrl.Rest}/contacts/${contact_id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include"
  });

export const archiveContact = id =>
  fetch(`${config.ApiUrl.Rest}/contacts/${id}`, {
    method: "DELETE",
    mode: "cors",
    credentials: "include"
  });

export const inviteUser = (contact_id, payload) =>
  fetch(`${config.ApiUrl.Rest}/contacts/${contact_id}/invite`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(payload),
    mode: "cors",
    credentials: "include"
  });

export const getContactOrganisations = contact_id =>
  fetch(`${config.ApiUrl.Rest}/contacts/${contact_id}/organisations`, {
    mode: "cors",
    credentials: "include"
  });

export const linkOrganisationToContact = (contact_id, data) =>
  fetch(`${config.ApiUrl.Rest}/contacts/${contact_id}/organisations`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include"
  });

export const editContactTags = (contact_id, data) =>
  fetch(`${config.ApiUrl.Rest}/contacts/${contact_id}/tags`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include"
  });

export const deleteContactTags = (contact_id, data) =>
  fetch(`${config.ApiUrl.Rest}/contacts/${contact_id}/tags`, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include"
  });

export const getContactTags = contact_id =>
  fetch(`${config.ApiUrl.Rest}/contacts/${contact_id}/tags`, {
    mode: "cors",
    credentials: "include"
  });

export const deleteContactCrops = (contact_id, payload) =>
  fetch(`${config.ApiUrl.Rest}/contacts/${contact_id}/crops`, {
    headers: { "Content-Type": "application/json" },
    method: "DELETE",
    body: JSON.stringify(payload),
    mode: "cors",
    credentials: "include"
  });

export const cropDataExport = payload =>
  fetch(`${config.ApiUrl.Rest}/crops/exporter`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(payload),
    mode: "cors",
    credentials: "include"
  });
