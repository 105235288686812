/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import "./StyleFixes.css";
import { Skeleton } from "@material-ui/lab";
import { generateDates } from "./helpers";
import { Calendar } from "../../../ui/components/Calendar/index";
import { ColumnChart } from "../../../ui/components/ColumnChart";
import { StatusTile } from "../../../ui/structures/StatusTile/index";
import { ReactComponent as ClientsIcon } from "../../../ui/static/Clients.svg";
import { ReactComponent as AwaitingIcon } from "../../../ui/static/Awaiting.svg";
import { ReactComponent as OverdueIcon } from "../../../ui/static/Overdue.svg";
import { useFetch } from "../../../hooks/fetch.hook";
import {
  getDashboardClients,
  getNonSubmissionDates,
  getReportData,
  getSubmissions,
} from "./_api/index";

const tilesMap = [
  {
    title: "Clients",
    icon: <ClientsIcon />,
    search: "",
    path: "/organisations/organisations-list",
  },
  {
    title: "Submissions",
    icon: <AwaitingIcon />,
    path: "/submissions",
    search: "",
    status: "submissions",
  },
  {
    title: "Overdue Submissions",
    icon: <OverdueIcon />,
    path: "/submissions",
    search: "?status=overdue",
    status: "overdue",
  },
];

const args = {
  type: "minimal",
  horizontal: false,
  categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
  color: "#71BF44",
  titleColor: "#263238",
  title: "Monthly Submissions",
  titlePosition: "center",
};

const channelReportArgs = {
  type: "grouped",
  horizontal: true,
  categories: [],
  color: "#6BC200",
  secondBarColor: "#2196F3",
  titleColor: "#263238",
  title: "Data Submissions by Channel",
  titlePosition: "left",
};

export const Dashboard = () => {
  const { request } = useFetch();

  const [submissions, setSubmissions] = useState("");
  const [overdueSubmission, setOverdueSubmission] = useState("");
  const [clients, setClients] = useState("");
  const [nonSubmissionDates, setNonSubmissionDates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [monthlyReport, setMonthlyReport] = useState([]);
  const [channelReport, setChannelReport] = useState([]);

  useEffect(() => {
    setLoading(true);
    Promise.all([
      request(getDashboardClients),
      request(getNonSubmissionDates),
      request(getSubmissions, "active"),
      request(getSubmissions, "overdue"),
      request(getReportData),
    ])
      .then(([allClients, dates, submitted, overdue, reports]) => {
        allClients && setClients(allClients);
        setSubmissions(submitted);
        setOverdueSubmission(overdue);
        if (!dates) return;
        setNonSubmissionDates(generateDates(dates));
        if (!reports) return;
        setMonthlyReport(reports.data_by_days);
        setChannelReport(reports.data_by_channel);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const quantityMap = {
    Clients: clients,
    Submissions: submissions,
    "Overdue Submissions": overdueSubmission,
  };

  const getChannelData = () => {
    let smsData = [];
    let emailData = [];
    for (let key in channelReport) {
      smsData.push(channelReport[key].total_sms);
      emailData.push(channelReport[key].total_email);
    }
    return [
      { name: "Sms", data: smsData },
      { name: "Email", data: emailData },
    ];
  };

  return (
    <div>
      <div className="dashboard-wrapper">
        <div className="first-column">
          <div className="tiles-container mb-10">
            {tilesMap.map(({ title, icon, path, status, search }, index) => (
              <div key={index} className="mb-5">
                <StatusTile
                  quantity={quantityMap[title]}
                  title={title}
                  icon={icon}
                  path={path}
                  search={search}
                  status={status}
                />
              </div>
            ))}
          </div>
          {loading ? (
            <Skeleton
              variant="rect"
              width={750}
              height={354}
              className="mb-10"
            />
          ) : (
            <div className="bg-white d-flex justify-content-start align-items-center channel-chart p-5 mb-10">
              <ColumnChart
                options={{
                  ...channelReportArgs,
                  categories: Object.keys(channelReport),
                }}
                series={getChannelData()}
                height="305"
                id="channels"
                width="680"
              />
            </div>
          )}
        </div>
        <div className="second-column">
          <div className="calendar mb-6">
            {loading ? (
              <Skeleton
                variant="rect"
                width={317.75}
                height={305}
                className="mb-5"
              />
            ) : (
              <Calendar invalidDates={nonSubmissionDates} />
            )}
          </div>

          {loading ? (
            <Skeleton variant="rect" width={317} height={227.64} />
          ) : (
            <div className="bg-white monthly-chart pt-5">
              <ColumnChart
                options={{ ...args }}
                series={[
                  {
                    name: "Monthly Submissions",
                    data: monthlyReport,
                  },
                ]}
                id="monthly"
                width={315.5}
              />
            </div>
          )}
        </div>
      </div>
      <div className="d-flex mt-10 justify-content-between bottom-wrapper"></div>
    </div>
  );
};
