import React, { useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import { get } from "lodash";
import { TextField } from "@material-ui/core";
import { ListButton } from "../../components/ListButton";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  root: {
    background: "white !important",
  },
});

export const TagsAutocomplete = ({
  name,
  placeholder,
  setValue,
  options,
  loading,
  onClose,
  currentValue = [],
  multiple,
  defaultValue,
  disabled,
  ...other
}) => {
  const [tags, setTags] = useState([]);
  const classes = useStyles();

  const handleSubmit = () => {
    setValue(name, [...currentValue, ...tags.map((tag) => tag.id)]);
    onClose();
  };

  const handleChangeMultiple = (_, value, reason) => {
    setTags(value);
  };

  const handleChange = (_, value) => {
    setValue(name, get(value, "name", ""));
  };

  return (
    <>
      <Autocomplete
        options={options}
        id={name}
        multiple={multiple}
        onChange={multiple ? handleChangeMultiple : handleChange}
        getOptionLabel={(item) => {
          if (item.category) {
            return item.category
              ? `${item.name} - ${item.category}`
              : item.name;
          } else if (item.measure && item.quantity) {
            return `${item.name} - ${item.quantity} ${item.measure}`;
          }
        }}
        defaultValue={options.find(({ id }) => id === defaultValue)}
        disabled={disabled}
        data-test={name + "-autocomplete"}
        {...other}
        renderInput={(params) => (
          <TextField
            {...params}
            label={placeholder}
            variant="filled"
            classes={{ root: classes.root }}
          />
        )}
      />
      <div className="d-flex justify-content-end m-2">
        <ListButton
          label="Cancel"
          size="medium"
          onClick={onClose}
          boxShadow={false}
          className="mx-2"
        />
        <ListButton
          label="Add"
          size="medium"
          onClick={handleSubmit}
          boxShadow={false}
          data-test="add-tag"
        />
      </div>
    </>
  );
};
