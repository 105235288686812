import config from "../../../../../config";

export const getPackages = (search = "", archived = "", field, direction) =>
  fetch(
    `${config.ApiUrl.Rest}/package_sizes?${search && "&" + search}${(archived &&
      "&status=all") ||
      ""}${(field && "&order_by=" + field) || ""}${(direction &&
      "&order_dir=" + direction) ||
      ""}`,
    {
      mode: "cors",
      credentials: "include"
    }
  );

export const getPackageById = package_id =>
  fetch(`${config.ApiUrl.Rest}/package_sizes/${package_id}`, {
    mode: "cors",
    credentials: "include"
  });

export const addPackage = data =>
  fetch(`${config.ApiUrl.Rest}/package_sizes`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include"
  });

export const modifyPackage = (data, package_id) =>
  fetch(`${config.ApiUrl.Rest}/package_sizes/${package_id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include"
  });

export const deletePackage = package_id =>
  fetch(`${config.ApiUrl.Rest}/package_sizes/${package_id}`, {
    method: "DELETE",
    mode: "cors",
    credentials: "include"
  });

export const getPackageRelatedCrops = package_id =>
  fetch(`${config.ApiUrl.Rest}/package_sizes/${package_id}/crops`, {
    mode: "cors",
    credentials: "include"
  });
