import { get } from "lodash";
import { useSelector } from "react-redux";
import { getUserRole } from "../ui/helpers";

export const IsGuest = () => {
  const user = useSelector(({ auth: { user } }) => user) || {};
  const tenants = get(user, "permissions.tenant", {});

  const isNationalAdmin = get(user, "permissions.is_national_admin");
  const isTenantAdmin = getUserRole("is_tenant_admin", tenants);
  const isEndUser = get(user, "permissions.is_end_user");
  return !isNationalAdmin && !isTenantAdmin && !isEndUser;
};
