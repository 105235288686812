import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import SearchBar from "@bit/the-glue.frontendcomponents.search-bar";
import { HEADINGS, searchMap } from "../constants";
import { removeCrop, setCropsList, updateCropsList } from "../_redux/actions";
import { ListButton } from "../../../../../ui/components/ListButton";
import { ListHeader } from "../../../../../ui/structures/ListHeader";
import { useFetch } from "../../../../../hooks/fetch.hook";
import { Edit as EditIcon, Warning as WarningIcon } from "@material-ui/icons";
import { UnarchiveButton } from "../../../../../ui/components/UnarchiveButton";
import { Modal } from "../../../../../ui/components/Modal";
import { ConfirmDelete } from "../../../../../ui/components/ConfirmDelete";
import { ArchiveCheckbox } from "../../../../../ui/components/ArchiveCheckbox";
import { sortHeaderStyle } from "../../../../constants";
import { TableSortLabel } from "@material-ui/core";
import { getTags } from "../../../Settings/Tags/_api";
import { info, warning } from "../../../../../helpers/toasts";
import { Loader } from "../../../../../ui/components/Loader";
import { isUnassigned } from "../helpers";
import { cropDataExport } from "../../../Contacts/_api";
import {
  archiveCrop,
  getContactCrops,
  getCropById,
  getCrops,
  getOrganisationCrops,
  modifyCrop
} from "../_api";
import {
  getSelectedId,
  getStatusRowStyle,
  handleSearch,
  isStatus
} from "../../../../../ui/helpers";

export const List = ({ id, is_contact = false, is_organisation = false }) => {
  const dispatch = useDispatch();
  const { request } = useFetch();

  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState({});
  const [focused, setFocused] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [initialData, setInitialData] = useState([]);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [archivedLoading, setArchivedLoading] = useState(false);
  const [archived, setArchived] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [unArchiving, setUnarchiving] = useState(false);
  const [field, setField] = useState("");
  const [direction, setDirection] = useState("");
  const [tags, setTags] = useState([]);
  const handleModalClose = () => setConfirmDeleteOpen(false);

  const data = useSelector(({ crops: { cropsList } }) => cropsList);

  const fetchOrganisationCrops = (search, field, direction) => {
    setLoading(true);
    request(getOrganisationCrops, id, search, field, field && direction)
      .then(orgCrops => {
        if (!orgCrops) return;
        dispatch(setCropsList(orgCrops));
        setInitialData(orgCrops);
      })
      .finally(() => {
        setArchivedLoading(false);
        setLoading(false);
      });
  };

  const fetchContactCrops = (search, field, direction) => {
    setLoading(true);
    request(getContactCrops, id, search, field, field && direction)
      .then(contactCrops => {
        if (!contactCrops) return;
        dispatch(setCropsList(contactCrops));
        setInitialData(contactCrops);
      })
      .finally(() => {
        setArchivedLoading(false);
        setLoading(false);
      });
  };

  const fetchCrops = (search, field, direction) => {
    setLoading(true);
    request(getCrops, search, field, field && direction)
      .then(allCrops => {
        if (!allCrops) return;
        dispatch(setCropsList(allCrops));
        setInitialData(allCrops);
      })
      .finally(() => {
        setArchivedLoading(false);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (id && is_organisation && !is_contact) {
      fetchOrganisationCrops(
        archived
          ? search === ""
            ? "status=all"
            : search
          : search === ""
          ? "status=active&status=unassigned"
          : search,
        field,
        direction
      );
    } else if (id && is_contact) {
      fetchContactCrops(
        archived
          ? search === ""
            ? "status=all"
            : search
          : search === ""
          ? "status=active&status=unassigned"
          : search,
        field,
        direction
      );
    } else {
      fetchCrops(
        archived
          ? search === ""
            ? "status=all"
            : search
          : search === ""
          ? "status=active&status=unassigned"
          : search,
        field,
        direction
      );
    }
    // eslint-disable-next-line
  }, [search, archived, field, direction]);

  useEffect(() => {
    request(getTags, "type=Contact").then(data => data && setTags(data));
    // eslint-disable-next-line
  }, []);

  const KEY_MAP = {
    "Crop ID": data.map(item => item.code),
    Name: data.map(item => item.name),
    Variety: data.map(item => item.variety),
    Grade: data.map(item => item.grade),
    Tag: tags.map(item => (item || {}).name)
  };

  const unArchiveDisabled = useMemo(
    () => Object.values(selected).filter(Boolean).length !== 1,
    [selected]
  );

  const handleArchive = id => {
    setLoading(true);
    setActionLoading(true);
    setConfirmDeleteOpen(false);
    request(archiveCrop, id)
      .then(data => {
        if (!data) return;
        if (archived) {
          request(getCropById, id).then(
            data => data && dispatch(updateCropsList(data))
          );
        } else dispatch(removeCrop(id));
        setSelected({});
        fetchCrops();
        info("Crop has been archived!");
      })
      .finally(() => {
        setLoading(false);
        setActionLoading(false);
      });
  };

  const handleUnarchive = () => {
    const cropID = getSelectedId(selected);
    const status = (data.find(({ id }) => id === cropID) || {}).status;
    const contacts = (data.find(({ id }) => id === cropID) || {}).contacts
      .length;
    if (status !== "archived") return;
    setUnarchiving(true);
    request(
      modifyCrop,
      contacts >= 1 ? { status: "active" } : { status: "unassigned" },
      cropID
    )
      .then(data => {
        if (!data) return;
        dispatch(updateCropsList(data));
        setSelected({});
        info("Crop has been unarchived!");
      })
      .finally(() => {
        setUnarchiving(false);
      });
  };

  const handleCropListDownload = () => {
    setActionLoading(true);
    request(
      cropDataExport,
      archived === false
        ? { crop_status: ["active", "unassigned"] }
        : { crop_status: "all" }
    )
      .then(response => {
        if (!response) {
          warning("Something has gone wrong, please try again later!");
          return;
        }
        window.open(response.url, "_blank");
        info("Crop List downloaded!");
      })
      .finally(() => setActionLoading(false));
  };

  const renderButtons = () => (
    <>
      {archived && (
        <UnarchiveButton
          handleClick={handleUnarchive}
          disabled={unArchiveDisabled}
          loading={unArchiving}
        />
      )}
      <div className="mx-3">
        <Link
          to={{
            pathname: `/add-crop`
          }}
          data-testid="new"
        >
          <ListButton
            label="New"
            size="small"
            boxShadow={false}
            background="rgba(107, 194, 0, 0.3)"
            text="#6BC200"
          />
        </Link>
      </div>
      <div className="mx-3">
        <ListButton
          label="Download Crop List"
          size="small"
          boxShadow={false}
          background="rgba(107, 194, 0, 0.3)"
          text="#6BC200"
          onClick={() => handleCropListDownload()}
        />
      </div>
    </>
  );

  const showArchived = () => {
    setArchivedLoading(true);
    setArchived(!archived);
  };

  const handleOpenModal = id => {
    setConfirmDeleteOpen(true);
    setSelectedItem(id);
  };

  const resetSearch = () => {
    dispatch(setCropsList(initialData));
    setSearch("");
  };

  const handleFocus = () => setFocused(true);

  function renderCropsRow(headings, item) {
    return headings.map(([key]) => {
      return (
        <td
          key={key}
          className={`text-center no-line border-0 px-5 py-5 ${isStatus(key) &&
            getStatusRowStyle(item)}`}
        >
          {key === "edit" ? (
            <NavLink
              className="text-dark"
              to={{
                pathname: `/crops/${item.id}`
              }}
              data-test="edit-crop"
            >
              <EditIcon style={{ color: "rgba(0, 0, 0, 0.54)" }} />
              {item[key]}
            </NavLink>
          ) : isUnassigned(item[key], key) ? (
            <>
              <WarningIcon style={{ color: "red" }} /> {item[key]}
            </>
          ) : (
            item[key]
          )}
        </td>
      );
    });
  }

  function renderHeaderWithSorting(headings) {
    return headings.map(([key, header]) => (
      <th
        key={key}
        style={sortHeaderStyle}
        className="px-5 text-nowrap text-center"
        onClick={() => {
          setDirection(
            key !== field ? "desc" : direction === "desc" ? "asc" : "desc"
          );
          setField(key);
        }}
      >
        <span style={{ cursor: "pointer" }}>{header}</span>
        {key === field && <TableSortLabel active direction={direction} />}
      </th>
    ));
  }
  return (
    <>
      {actionLoading && <Loader title="Loading..." isOpen={actionLoading} />}
      {confirmDeleteOpen && (
        <Modal
          isOpen={confirmDeleteOpen}
          submitable
          onClose={handleModalClose}
          maxWidth="sm"
          modalContent={
            <ConfirmDelete
              handleClose={handleModalClose}
              handleSubmit={handleArchive}
              id={selectedItem}
              name={(data.find(({ id }) => id === selectedItem) || {}).name}
            />
          }
        />
      )}
      <div className="row justify-content-center mt-10">
        <div className="col-12">
          <div
            className="bg-white py-7 px-10"
            style={{ border: "1px solid #E5E5E5", borderRadius: "4px" }}
          >
            <ListHeader
              title="Crops"
              renderButtons={renderButtons}
              handleFocus={handleFocus}
            />
            <div>
              <SearchBar
                onSearch={data => handleSearch(data, searchMap, setSearch)}
                clearSearch={resetSearch}
                keyMap={KEY_MAP}
                placeholder="Filter Crops..."
                elevation={2}
                chipBackgroundColor="rgba(107, 194, 0, 0.3)"
                chipColor="#6BC200"
                focused={focused}
                setFocused={setFocused}
                data-test="search-bar"
              />
            </div>
            <ArchiveCheckbox
              archivedLoading={archivedLoading}
              archived={archived}
              showArchived={showArchived}
            />
            <Datagrid
              data={data}
              headings={HEADINGS}
              renderRow={renderCropsRow}
              renderHeaderWithSorting={renderHeaderWithSorting}
              selected={selected}
              setSelected={setSelected}
              loading={loading}
              selectable
              deletable
              handleDelete={handleOpenModal}
              link="crops"
            />
          </div>
        </div>
      </div>
    </>
  );
};
