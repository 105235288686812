import {
  getOrganisations,
  archiveOrganisation,
  getOrganisationById,
  modifyOrganisation,
  getOrganisationsAmount
} from "../_api";
import {
  removeOrganisation,
  setOrganisationsList,
  updateOrganisationsList
} from "../_redux/actions";
import {
  modifyOrganisationData,
  modifyOrganisations,
  renderOrganisationsRow
} from "../helpers";
import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { TableSortLabel } from "@material-ui/core";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import { getSelectedId, handleSearch } from "../../../../ui/helpers";
import { HEADINGS, searchMap } from "../constants";
import { ListButton } from "../../../../ui/components/ListButton";
import { ListHeader } from "../../../../ui/structures/ListHeader";
import { useFetch } from "../../../../hooks/fetch.hook";
import { UnarchiveButton } from "../../../../ui/components/UnarchiveButton";
import { Modal } from "../../../../ui/components/Modal";
import { ConfirmDelete } from "../../../../ui/components/ConfirmDelete";
import { ArchiveCheckbox } from "../../../../ui/components/ArchiveCheckbox";
import { sortHeaderStyle } from "../../../constants";
import { Loader } from "../../../../ui/components/Loader";
import { info } from "../../../../helpers/toasts";
import SearchBar from "@bit/the-glue.frontendcomponents.search-bar";
import { getTags } from "../../Settings/Tags/_api";

export const List = () => {
  const dispatch = useDispatch();
  const { request } = useFetch();

  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState({});
  const [focused, setFocused] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [archivedLoading, setArchivedLoading] = useState(false);
  const [archived, setArchived] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [unArchiving, setUnarchiving] = useState(false);
  const [field, setField] = useState("");
  const [direction, setDirection] = useState("");
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [tags, setTags] = useState([]);

  const handleModalClose = () => setConfirmDeleteOpen(false);

  const data = useSelector(
    ({ organisations: { organisationsList } }) => organisationsList
  );

  const fetchOrganisations = () => {
    setLoading(true);
    request(
      getOrganisations,
      "",
      search,
      archived,
      field,
      field && direction,
      page,
      pageSize
    )
      .then(data => data && modifyOrganisations(data))
      .then(data => {
        if (!data) return;
        dispatch(setOrganisationsList(data));
      })
      .finally(() => {
        setArchivedLoading(false);
        setLoading(false);
      });
  };

  useEffect(() => {
    request(getTags, "type=Organisation").then(data => data && setTags(data));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchOrganisations();
    // eslint-disable-next-line
  }, [search, archived, field, direction, page, pageSize]);

  useEffect(() => {
    request(getOrganisationsAmount, search, archived).then(
      data => data && setTotalRecords(data)
    );
    // eslint-disable-next-line
  }, [search, archived]);

  const unArchiveDisabled = useMemo(
    () => Object.values(selected).filter(Boolean).length !== 1,
    [selected]
  );

  const handleArchive = id => {
    setConfirmDeleteOpen(false);
    setActionLoading(true);
    request(archiveOrganisation, id)
      .then(data => {
        if (!data) return;
        if (archived) {
          request(getOrganisationById, id).then(
            data =>
              data &&
              dispatch(updateOrganisationsList(modifyOrganisationData(data)))
          );
        } else dispatch(removeOrganisation(id));
        setSelected({});
        info("Organisation has been archived!");
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  const handleUnarchive = () => {
    const organisationID = getSelectedId(selected);
    const status = (data.find(({ id }) => id === organisationID) || {}).status;
    if (status !== "archived") return;
    setActionLoading(true);
    setUnarchiving(true);
    request(modifyOrganisation, { status: "active" }, organisationID)
      .then(data => {
        if (!data) return;
        dispatch(updateOrganisationsList(modifyOrganisationData(data)));
        setSelected({});
        info("Organisation has been unarchived!");
      })
      .finally(() => {
        setUnarchiving(false);
        setActionLoading(false);
      });
  };

  const renderButtons = () => (
    <>
      {archived && (
        <UnarchiveButton
          handleClick={handleUnarchive}
          disabled={unArchiveDisabled}
          loading={unArchiving}
        />
      )}
      <div className="mr-10">
        <Link
          to={{
            pathname: `/add-organisation`
          }}
          data-testid="new"
        >
          <ListButton
            label="New"
            size="small"
            boxShadow={false}
            background="rgba(107, 194, 0, 0.3)"
            text="#6BC200"
          />
        </Link>
      </div>
    </>
  );

  const showArchived = () => {
    setArchivedLoading(true);
    setArchived(!archived);
  };

  const handleOpenModal = id => {
    setConfirmDeleteOpen(true);
    setSelectedItem(id);
  };

  const resetSearch = () => {
    setSearch("");
  };

  const handleFocus = () => setFocused(true);

  function renderHeaderWithSorting(headings) {
    return headings.map(([key, header]) => (
      <th
        key={key}
        style={sortHeaderStyle}
        className="px-5 text-nowrap text-center"
        onClick={() => {
          setDirection(
            key !== field ? "desc" : direction === "desc" ? "asc" : "desc"
          );
          setField(key);
        }}
      >
        <span style={{ cursor: "pointer" }}>{header}</span>
        {key === field && <TableSortLabel active direction={direction} />}
      </th>
    ));
  }

  const KEY_MAP = {
    "Organisation ID": data.map(item => item.code),
    "Business Name": data.map(item => item.business_name),
    "ABN Status": data.map(item => item.abn_verified),
    Status: data.map(item => item.status),
    Tag: tags.map(item => (item || {}).name)
  };

  return (
    <>
      {actionLoading && <Loader title="Loading..." isOpen={actionLoading} />}
      {confirmDeleteOpen && (
        <Modal
          isOpen={confirmDeleteOpen}
          submitable
          onClose={handleModalClose}
          maxWidth="sm"
          modalContent={
            <ConfirmDelete
              handleClose={handleModalClose}
              handleSubmit={handleArchive}
              id={selectedItem}
              name={
                (data.find(({ id }) => id === selectedItem) || {}).legal_name
              }
            />
          }
        />
      )}
      <div className="row justify-content-center mt-10">
        <div className="col-12">
          <div
            className="bg-white rounded py-7 px-10"
            style={{ border: "1px solid #E5E5E5", borderRadius: "4px" }}
          >
            <ListHeader
              title="Organisations"
              renderButtons={renderButtons}
              handleFocus={handleFocus}
            />
            <div>
              <SearchBar
                onSearch={data => handleSearch(data, searchMap, setSearch)}
                clearSearch={resetSearch}
                keyMap={KEY_MAP}
                placeholder="Filter Crops..."
                elevation={2}
                chipBackgroundColor="rgba(107, 194, 0, 0.3)"
                chipColor="#6BC200"
                focused={focused}
                setFocused={setFocused}
              />
            </div>
            <ArchiveCheckbox
              archivedLoading={archivedLoading}
              archived={archived}
              showArchived={showArchived}
            />
            <Datagrid
              data={data}
              headings={HEADINGS}
              renderRow={renderOrganisationsRow}
              selected={selected}
              setSelected={setSelected}
              editable
              loading={loading}
              selectable
              deletable
              handleDelete={handleOpenModal}
              link="organisations-list"
              renderHeaderWithSorting={renderHeaderWithSorting}
              serverPage={page}
              setServerPage={setPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              isServerPagination
              totalRecords={totalRecords}
            />
          </div>
        </div>
      </div>
    </>
  );
};
