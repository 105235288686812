import React, { useState } from "react";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { MappedInput } from "../../../../../ui/components/Input";
import { useFetch } from "../../../../../hooks/fetch.hook";
import { Loader } from "../../../../../ui/components/Loader";
import { ListButton } from "../../../../../ui/components/ListButton";
import { SubscriberDetailsEditSchema } from "../../../../../helpers/schemas";
import { modifySubscriber } from "../_api";
import { Skeleton } from "@material-ui/lab";
import { generateSubscriberPayload } from "../helpers";
import { warning } from "../../../../../helpers/toasts";
import { MappedSelect } from "@bit/the-glue.frontendcomponents.select";
import { states } from "../../SignUp/constants";

export const SubscriberDetails = ({ cardData, id }) => {
  const { request } = useFetch();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const initialValues = {
    name: cardData?.name || "",
    email: cardData?.email || "",
    phone: cardData?.phone || "",
    address: cardData?.line1 || "",
    city: cardData?.city || "",
    state: cardData?.state || "",
    postcode: cardData?.postal_code || ""
  };

  const handleSubmit = values => {
    if (cardData.status === "active") {
      setLoading(true);
      request(modifySubscriber, generateSubscriberPayload(values, cardData), id)
        .then(data => data && history.push("/subscriptions/subscriptions-list"))
        .finally(() => {
          setLoading(false);
        });
    } else {
      warning(`You can only edit a subscription that is still active.`);
    }
  };

  const handleClose = () => history.goBack();

  if (id && !cardData) {
    return <Skeleton variant="rect" width={"100%"} height={480.14} />;
  }

  return (
    <div
      className="bg-white p-10"
      style={{
        border: "1px solid #E5E5E5",
        borderRadius: "4px",
        minWidth: "535px"
      }}
    >
      {loading && <Loader isOpen={loading} maxWidth="xs" title="Loading..." />}
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={SubscriberDetailsEditSchema}
      >
        {({ handleSubmit, values }) => (
          <>
            <h6 className="mb-5">
              <strong>{cardData.name}</strong>
            </h6>
            <div className="row justify-content-between">
              <div className="col-8 p-5">
                <MappedInput label="Name" name="name" color="#6BC200" />
                <MappedInput label="Email" name="email" color="#6BC200" />
                <MappedInput label="Phone" name="phone" color="#6BC200" />
              </div>
            </div>
            <h6 className="my-5">
              <strong>Address Details</strong>
            </h6>
            <div className="row justify-content-between">
              <div className="col-8 p-5">
                <MappedInput label="Address" name="address" color="#6BC200" />
                <MappedInput label="City" name="city" color="#6BC200" />
                <MappedSelect
                  label="State"
                  name="state"
                  value={values.state}
                  options={states}
                  color="#6BC200"
                />
                <MappedInput
                  label="Postal Code"
                  name="postcode"
                  color="#6BC200"
                />
              </div>
            </div>
            <div className="mt-10 text-right">
              <ListButton
                label="Cancel"
                size="large"
                onClick={handleClose}
                boxShadow={false}
                className="mx-2"
              />
              <ListButton
                label="Save"
                size="large"
                onClick={handleSubmit}
                boxShadow={false}
              />
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};
