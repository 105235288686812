import React from "react";
import { Link } from "react-router-dom";
import { isLink } from "../../../ui/helpers";

export function renderContactsRow(headings, item) {
  return headings.map(([key]) => (
    <td key={key} className="align-middle no-line border-0 px-5 py-5">
      {isLink(key) ? (
        <Link
          className={item.status === "archived" ? "text-danger" : "text-dark"}
          to={`/contacts/${item.id}`}
        >
          {item[key]}
        </Link>
      ) : (
        item[key]
      )}
    </td>
  ));
}

export function renderSettingsRow(headings, item) {
  return headings.map(([key]) => (
    <td key={key} className="align-middle no-line border-0 px-5 py-5">
      {item[key]}
    </td>
  ));
}

export const findDailyReportSetting = (day, reportsData, type) => {
  const reportDailyData = reportsData.find(report => report.day === day);
  return reportDailyData === undefined
    ? false
    : reportDailyData.requested_categories.includes(type);
};

export const getMonthlyReportSetting = reportsData =>
  reportsData.find(report => report.report_type === "monthly") !== undefined
    ? true
    : false;

export const getReportFormat = reportsData =>
  reportsData.length > 0
    ? reportsData[0].report_type === "daily"
      ? reportsData[0].daily_report_format
      : reportsData[0].monthly_report_format
    : "";
