/* eslint-disable array-callback-return */
export const generateReportSettings = (data, subString) =>
  Object.entries(data)
    .filter(([key, value]) => key.includes(subString) && value === true)
    .map(element => {
      if (element[1] === true) {
        return element[0].slice(0, 3);
      }
    });

export const getReportSettings = (reportArray, day) =>
  reportArray ? reportArray.includes(day) : false;

export const generateReportsRows = values => {
  return [
    {
      name: "Fruit",
      elements: [
        {
          name: "monday_fruit",
          value: values.monday_fruit
        },
        {
          name: "tuesday_fruit",
          value: values.tuesday_fruit
        },
        {
          name: "wednesday_fruit",
          value: values.wednesday_fruit
        },
        {
          name: "thursday_fruit",
          value: values.thursday_fruit
        },
        {
          name: "friday_fruit",
          value: values.friday_fruit
        }
      ]
    },
    {
      name: "Vegetable",
      elements: [
        {
          name: "monday_veg",
          value: values.monday_veg
        },
        {
          name: "tuesday_veg",
          value: values.tuesday_veg
        },
        {
          name: "wednesday_veg",
          value: values.wednesday_veg
        },
        {
          name: "thursday_veg",
          value: values.thursday_veg
        },
        {
          name: "friday_veg",
          value: values.friday_veg
        }
      ]
    }
  ];
};

export const generateMobileReportsOptions = values => {
  return [
    {
      day: "Monday",
      fruit: { name: "monday_fruit", value: values.monday_fruit },
      veg: {
        name: "monday_veg",
        value: values.monday_veg
      }
    },
    {
      day: "Tuesday",
      fruit: { name: "tuesday_fruit", value: values.tuesday_fruit },
      veg: {
        name: "tuesday_veg",
        value: values.tuesday_veg
      }
    },
    {
      day: "Wednesday",
      fruit: { name: "wednesday_fruit", value: values.wednesday_fruit },
      veg: {
        name: "wednesday_veg",
        value: values.wednesday_veg
      }
    },
    {
      day: "Thursday",
      fruit: { name: "thursday_fruit", value: values.thursday_fruit },
      veg: {
        name: "thursday_veg",
        value: values.thursday_veg
      }
    },
    {
      day: "Friday",
      fruit: { name: "friday_fruit", value: values.friday_fruit },
      veg: {
        name: "friday_veg",
        value: values.friday_veg
      }
    }
  ];
};
