/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";
import { MappedInput } from "../../../../ui/components/Input/index";
import { MappedMaskedInput } from "@bit/the-glue.frontendcomponents.masked-input";
import { MappedSelect } from "@bit/the-glue.frontendcomponents.select";
import { OrganisationDetailsSchema } from "../../../../helpers/schemas";
import { stateOptions, statusOptions } from "../constants";
import { TagsField } from "../../../../ui/structures/TagsForm/TagsField";
import { Modal } from "../../../../ui/components/Modal";
import { TagsAutocomplete } from "../../../../ui/structures/TagsForm/TagsAutocomplete";
import { useFetch } from "../../../../hooks/fetch.hook";
import { getTags } from "../../Settings/Tags/_api";
import { PostalAddress } from "./PostalAddress";
import { Loader } from "../../../../ui/components/Loader";
import { ListButton } from "../../../../ui/components/ListButton";
import { TradingNameSelect } from "./TradingNameSelect";
import { uniq } from "lodash";
import { AbnOfflineWarning } from "./AbnOfflineWarning";
import { info, warning } from "../../../../helpers/toasts";
import { modifyOrganisationData, sortTagsHelper } from "../helpers";
import {
  addOrganisation,
  getSimpleOrganisations,
  modifyOrganisation,
  abnLookup,
  editOrganisationTags,
  deleteOrganisationTags,
  getOrganisations
} from "../_api";

export const OrganisationDetails = ({
  data = {},
  id,
  name,
  simpleOrganisations = [],
  setCardData
}) => {
  const history = useHistory();
  const { request } = useFetch();

  const [modalOpen, setModalOpen] = useState(false);
  const [tags, setTags] = useState([]);
  const [abnLookedUp, setAbnLookedUp] = useState(id ? true : false);
  const [tradingNameSelect, setTradingNameSelect] = useState(false);
  const [tradingNamesList, setTradingNamesList] = useState([]);
  const [tradingNameValue, setTradingNameValue] = useState("");
  const [tradingNameisInput, setTradingNameisInput] = useState(true);
  const [abnWarningOpen, setAbnWarningOpen] = useState(false);
  const [abnLoading, setAbnloading] = useState(false);
  const [tradingNameLoading, setTradingNameLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tagsLoading, setTagsLoading] = useState(false);
  const [organisationsList, setOrganisationsList] = useState(
    simpleOrganisations
  );

  const handleNameSelectClose = () => setTradingNameSelect(false);
  const handleAbnWarningClose = () => setAbnWarningOpen(false);

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  useEffect(() => {
    setTagsLoading(true);
    request(getTags, "type=_like(Organisation)_")
      .then(tags => tags && setTags(tags.sort(sortTagsHelper)))
      .finally(() => setTagsLoading(false));
  }, []);

  useEffect(() => {
    if (organisationsList.length) return;
    request(getSimpleOrganisations).then(
      data => data && setOrganisationsList(data)
    );
  }, []);

  useEffect(() => {
    if (data.ABN) {
      setTradingNameLoading(true);
      setTradingNameisInput(false);
      request(abnLookup, data.ABN)
        .then(data => {
          if (!data) return;
          const filteredNames = uniq(data.trading_names);
          const tradingNamesOptions = [
            ...filteredNames.map(name => ({
              value: name,
              label: name
            })),
            { value: data.legal_name, label: data.legal_name }
          ];
          setTradingNamesList(tradingNamesOptions);
        })
        .finally(() => setTradingNameLoading(false));
    }
  }, []);

  const initialValues = {
    ABN: data.ABN || "",
    legal_name: data.legal_name || "",
    business_name: data.business_name || "",
    status: data.status || "new",
    notes: data.notes || "",
    tags: id ? (data.tags || []).map(({ id }) => id) : [],
    physical_address: data.physical_address || "",
    physical_address2: data.physical_address2 || "",
    physical_city: data.physical_city || "",
    physical_state: data.physical_state || "",
    physical_postcode: data.physical_postcode || "",
    postal_address: data.postal_address || "",
    postal_address2: data.postal_address2 || "",
    postal_city: data.postal_city || "",
    postal_state: data.postal_state || "",
    postal_postcode: data.postal_postcode || "",
    code: data.code || "",
    as_physical: false
  };

  const createOrganisation = (values, orgPayload) => {
    setLoading(true);
    request(addOrganisation, orgPayload)
      .then(data => {
        if (!data) return;
        if (values.tags.length) {
          return request(
            editOrganisationTags,
            data.id,
            values.tags.map(tagID => ({ id: tagID }))
          ).finally(() => {
            setLoading(false);
            history.goBack();
          });
        } else return data;
      })
      .then(data => data && history.push("/organisations/organisations-list"));
  };

  const updateOrganisation = (values, orgPayload) => {
    setLoading(true);
    Promise.all([
      request(modifyOrganisation, orgPayload, id),
      editTagsRequest(values)
    ])
      .then(([organisationData, tags]) => {
        if (!organisationData || !tags) return;
        const modifiedData = modifyOrganisationData(organisationData);
        setCardData(state => ({ ...state, ...modifiedData }));
        info("Details have been updated!");
      })
      .finally(() => {
        setLoading(false);
        history.goBack();
      });
  };

  const editTagsRequest = values => {
    if (values.tags.length) {
      return request(
        editOrganisationTags,
        id,
        values.tags.map(tagID => ({ id: tagID }))
      );
    } else if (!values.tags.length && data.tags.length) {
      return request(deleteOrganisationTags, id, data.tags);
    } else return "success";
  };

  const handleSubmit = values => {
    const organisationPayload = { ...values };
    delete organisationPayload.tags;
    if (id) {
      updateOrganisation(values, organisationPayload);
    } else {
      createOrganisation(values, organisationPayload);
    }
  };

  const handleDuplicate = (abn = "") => {
    return request(getOrganisations, "", `ABN=_like(${abn})_`);
  };

  const handleBlur = (values, setTouched, setFieldValue) => () => {
    if (values.ABN.includes("_")) return;
    setAbnloading(true);
    handleDuplicate(values.ABN).then(data => {
      if ((data || []).length && ((data || [])[0] || {}).id !== id) {
        setAbnLookedUp(false);
        setAbnloading(false);
        info("Organisation already exists");
      } else if (
        (data || []).length === 0 ||
        ((data || [])[0] || {}).id === id
      ) {
        request(abnLookup, values.ABN)
          .then(data => {
            if (!data) {
              setAbnLookedUp(false);
              setAbnloading(false);
              warning(
                "Invalid ABN, please check that you have provided a valid ABN."
              );
            } else if (data === "Server error") {
              setTradingNameisInput(true);
              setAbnWarningOpen(true);
              setAbnLookedUp(true);
              setAbnloading(false);
            } else {
              setTradingNameisInput(false);
              setAbnLookedUp(true);
              setAbnloading(false);
              Object.keys(data).map(key => setFieldValue(key, data[key]));
              const filteredNames = uniq(data.trading_names);
              const tradingNamesOptions = [
                ...filteredNames.map(name => ({
                  value: name,
                  label: name
                })),
                { value: data.legal_name, label: data.legal_name }
              ];
              setTradingNamesList(tradingNamesOptions);
              if (tradingNamesOptions.length === 1) {
                setFieldValue("trading_name", tradingNamesOptions[0].value);
              } else {
                setFieldValue("trading_name", "");
                setTradingNameSelect(true);
              }
            }
          })
          .finally(() => setAbnloading(false));
      } else {
        setAbnLookedUp(false);
      }
    });
    setTouched({ ABN: true }, true);
  };

  const handleSelectTradingName = setFieldValue => () => {
    setFieldValue("business_name", (tradingNameValue || {}).value);
    setTradingNameSelect(false);
  };

  const handleClose = () => history.goBack();

  return (
    <>
      {(abnLoading || loading) && (
        <Loader
          isOpen={abnLoading || loading}
          maxWidth="xs"
          title="Loading..."
        />
      )}
      {abnWarningOpen && (
        <Modal
          maxWidth="sm"
          isOpen={abnWarningOpen}
          submitable
          onClose={handleAbnWarningClose}
          modalContent={
            <AbnOfflineWarning onClose={handleAbnWarningClose} id={id} />
          }
        />
      )}
      <div
        className="bg-white p-10"
        style={{ border: "1px solid #E5E5E5", borderRadius: "4px" }}
      >
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={OrganisationDetailsSchema}
        >
          {({ handleSubmit, setFieldValue, setTouched, values }) => (
            <>
              {modalOpen && (
                <Modal
                  maxWidth="sm"
                  isOpen={modalOpen}
                  submitable
                  onClose={handleModalClose}
                  modalContent={
                    <TagsAutocomplete
                      name="tags"
                      placeholder="Select Tags"
                      setValue={setFieldValue}
                      options={tags.filter(
                        item => !values.tags.includes(item.id)
                      )}
                      loading={!tags.length}
                      onClose={handleModalClose}
                      currentValue={values.tags}
                      multiple
                    />
                  }
                />
              )}
              {tradingNameSelect && (
                <Modal
                  maxWidth="sm"
                  isOpen={tradingNameSelect}
                  submitable
                  onClose={handleNameSelectClose}
                  modalContent={
                    <TradingNameSelect
                      onClose={handleNameSelectClose}
                      handleSubmit={handleSelectTradingName(setFieldValue)}
                      setTradingNameValue={setTradingNameValue}
                      tradingNamesList={tradingNamesList}
                    />
                  }
                />
              )}
              <h3 className="mb-5 d-flex justify-content-between">
                <div>
                  <strong>{name || "Create Organisation"}</strong>
                </div>
                <div>{id && `ABN Is ${data.abn_verified}`}</div>
              </h3>
              <div className="row justify-content-between align-items-start">
                <div className="col-6">
                  <MappedMaskedInput
                    label="ABN"
                    name="ABN"
                    data-testid="abn"
                    mask="99 999 999 999"
                    color="#6BC200"
                    variant="filled"
                    onBlur={handleBlur(values, setTouched, setFieldValue)}
                  />
                  <MappedInput
                    label="Legal Name"
                    name="legal_name"
                    data-testid="legal_name"
                    disabled
                    color="#6BC200"
                    variant="filled"
                  />
                  {tradingNameLoading ? (
                    <div className="mb-3">
                      <Skeleton variant="rect" width={"100%"} height={50} />
                    </div>
                  ) : tradingNameisInput ? (
                    <MappedInput
                      label="Business Name"
                      name="business_name"
                      data-testid="business_name"
                      disabled={!abnLookedUp}
                      color="#6BC200"
                      variant="filled"
                    />
                  ) : (
                    <MappedSelect
                      label="Business Name"
                      options={tradingNamesList}
                      name="business_name"
                      data-testid="business_name"
                      disabled={!abnLookedUp}
                      color="#6BC200"
                      variant="filled"
                    />
                  )}
                </div>
                <div className="col-6">
                  <div className="d-flex-column align-items-start w-100">
                    <div style={{ width: "100%", marginRight: "15px" }}>
                      <MappedSelect
                        label="Status"
                        options={statusOptions}
                        name="status"
                        data-testid="status"
                        color="#6BC200"
                        variant="filled"
                        disabled={!abnLookedUp}
                      />
                    </div>
                    <div className="">
                      {tagsLoading ? (
                        <div className="mb-5">
                          <Skeleton
                            variant="rect"
                            width={"100%"}
                            height={120.8}
                          />
                        </div>
                      ) : (
                        <TagsField
                          label="Tags"
                          tags={values.tags}
                          handleAdd={handleModalOpen}
                          setValue={setFieldValue}
                          predefinedTags={tags}
                          color="#6BC200"
                          variant="filled"
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div>
                    <MappedInput
                      label="Notes"
                      name="notes"
                      multiline
                      rows={12}
                      color="#6BC200"
                      variant="filled"
                      data-testid="notes"
                      disabled={!abnLookedUp}
                    />
                  </div>
                </div>
              </div>
              <div className="row justify-content-between mt-10">
                <div className="col-6">
                  <h5 className="mb-4">
                    <strong>Physical Address</strong>
                  </h5>
                  <MappedInput
                    label="Address 1"
                    name="physical_address"
                    data-testid="physical_address"
                    color="#6BC200"
                    variant="filled"
                  />
                  <MappedInput
                    label="Address 2"
                    name="physical_address2"
                    data-testid="physical_address2"
                    color="#6BC200"
                    variant="filled"
                  />
                  <MappedInput
                    label="City"
                    name="physical_city"
                    color="#6BC200"
                    variant="filled"
                    data-testid="physical_city"
                  />
                  <div className="row">
                    <div className="col-6">
                      <MappedSelect
                        label="State"
                        name="physical_state"
                        data-testid="physical_state"
                        color="#6BC200"
                        variant="filled"
                        options={stateOptions}
                      />
                    </div>
                    <div className="col-6">
                      <MappedInput
                        label="Postal Code"
                        name="physical_postcode"
                        data-testid="physical_postcode"
                        color="#6BC200"
                        variant="filled"
                      />
                    </div>
                  </div>
                </div>
                <PostalAddress id={id} />
              </div>
              <div className="mt-10 d-flex justify-content-end mr-4">
                <div className="mr-3">
                  <ListButton
                    label="Cancel"
                    size="large"
                    onClick={handleClose}
                    data-testid="cancel"
                    boxShadow={false}
                  />
                </div>
                <div>
                  <ListButton
                    label="Save"
                    size="large"
                    onClick={handleSubmit}
                    boxShadow={false}
                    disabled={!abnLookedUp}
                    data-testid="submit"
                  />
                </div>
              </div>
            </>
          )}
        </Formik>
      </div>
    </>
  );
};
