/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import Select from "../../../../ui/components/Select/index";
import { useFetch } from "../../../../hooks/fetch.hook";
import { useEffect } from "react";
import { getCountries, getCountryStates, getStateRegions } from "../_api";
import { Skeleton } from "@material-ui/lab";
import { CircularProgress } from "@material-ui/core";
import { useFormikContext } from "formik";
import { warning } from "../../../../helpers/toasts";
import {
  carryOverOptions,
  demandOptions,
  qualityOptions,
  supplyOptions
} from "../constants";
import { checkValidation, getCountry, validateRegionSelect } from "../helpers";
import { ListButton } from "../../../../ui/components/ListButton";

export const RegionEdit = ({
  setOpenModal,
  setRegionSelect,
  setCrops,
  crops,
  cropData,
  is_mobile_edit = false,
  setValid
}) => {
  const { request } = useFetch();
  const { values, setFieldValue } = useFormikContext();
  const [previousValues] = useState({
    region_name: values.region_name,
    region_id: values.region_id,
    state_id: values.state_id,
    state_name: values.state_name,
    country_id: values.country,
    country_name: values.country_name
  });

  const [regions, setRegions] = useState([]);
  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);

  const [loading, setLoading] = useState([]);
  const [regionLoading, setRegionLoading] = useState(false);
  const [statesLoading, setStatesLoading] = useState(false);

  const [country, setCountry] = useState(values.country);
  const [currentRegion, setCurrentRegion] = useState({
    id: values.region_id,
    name: values.region_name
  });
  const [currentState, setCurrentState] = useState({
    id: values.state_id,
    name: values.state_name
  });

  //fetch all the countries
  useEffect(() => {
    setLoading(true);
    request(getCountries)
      .then(countries => {
        if (!countries) return;
        setCountries(
          countries.map(country => ({
            value: country.id,
            label: country.name
          }))
        );
      })
      .finally(() => setLoading(false));
  }, []);

  //fetch states for the selected country
  useEffect(() => {
    setRegions([]);
    if (currentState.id !== values.state_id) {
      setCurrentRegion({});
    }
    if (country !== values.country) {
      setCurrentRegion({});
      setCurrentState({});
    }
    setStatesLoading(true);
    if (country !== "") {
      request(getCountryStates, country)
        .then(states => {
          if (!states) return;
          setStates(states);
        })
        .finally(() => setStatesLoading(false));
    }
  }, [country]);

  //fetch the regions for the selected state
  useEffect(() => {
    setRegionLoading(true);
    if (currentState.id !== values.state_id) {
      setCurrentRegion({});
    }
    if (currentState.id !== "") {
      request(getStateRegions, currentState.id)
        .then(regions => {
          if (!regions) return;
          setRegions(regions);
        })
        .finally(() => setRegionLoading(false));
    }
  }, [currentState]);

  const handleSubmit = () => {
    setFieldValue("country", country);
    setFieldValue("country_name", getCountry(country, countries));
    if (validateRegionSelect(currentState.id, currentRegion.id)) {
      saveRegionData();
      if (is_mobile_edit) {
        setRegionSelect(false);
      } else {
        setOpenModal(false);
      }
    } else {
      warning(
        "Please make sure that you select a State and a Region to continue"
      );
    }
  };

  const saveRegionData = () => {
    setCrops(
      crops.map(crop => {
        if (crop.generated_id === cropData.generated_id) {
          crop.prices.low = values.low_price;
          crop.prices.high = values.high_price;
          crop.prices.demand = demandOptions[values.demand];
          crop.prices.supply = supplyOptions[values.supply];
          crop.prices.carryover = carryOverOptions[values.carryover];
          crop.prices.quality = qualityOptions[values.quality];
          crop.prices.crop_id = cropData.id;
          crop.prices.state_name = currentState.name;
          crop.prices.state_id = currentState.id;
          crop.prices.country_id = country;
          crop.prices.country_name = getCountry(country, countries);
          crop.prices.best = values.best_price;
          crop.prices.package_size = cropData.package_size.id;
          crop.prices.region_name = values.region_name;
          crop.prices.region_id = values.region_id;
          if (checkValidation(crop, "card")) {
            crop.isValidated = true;
            if (setValid) setValid(true);
          } else {
            crop.isValidated = false;
            if (setValid) setValid(false);
          }
        }
        return crop;
      })
    );
  };

  const handleBack = () => {
    setFieldValue("region_name", previousValues.region_name);
    setFieldValue("region_id", previousValues.region_id);
    setFieldValue("state_name", previousValues.state_name);
    setFieldValue("state_id", previousValues.state_id);
    setFieldValue("country", previousValues.country_id);
    if (is_mobile_edit) {
      setRegionSelect(false);
    } else {
      setOpenModal(false);
    }
  };

  return (
    <div className="d-flex-column justify-content-center align-items-center mt-5 p-3">
      {loading ? (
        <Skeleton variant="rect" width={"100%"} height={555} />
      ) : (
        <>
          <div className="container">
            <div className="d-flex-column justify-content-between align-items-center mb-5">
              <div className="crop-edit-selector-text">Country Selector</div>
              <Select
                options={countries}
                defaultValue={values.country}
                setCountry={setCountry}
                inputProps={{
                  style: { textAlign: "center !important" }
                }}
              />
            </div>
            <div className="d-flex-column justify-content-between align-items-center mb-5">
              <div className="crop-edit-selector-text">State</div>
              {country === "" && (
                <span className="span-text">Please select a country</span>
              )}
              {statesLoading && country !== "" && (
                <div className="d-flex justify-content-start align-items-center">
                  <CircularProgress size="3rem" />
                </div>
              )}
              {!statesLoading && (
                <div className="state-grid">
                  {states.map(state => (
                    <button
                      key={state.id}
                      id={state.id}
                      className={`${
                        currentState.name === state.name ? "is-selected" : ""
                      } crop-region-country`}
                      onClick={e => {
                        setCurrentState({
                          name: e.target.innerHTML,
                          id: e.target.id
                        });
                        setFieldValue("state_name", e.target.innerHTML);
                        setFieldValue("state_id", e.target.id);
                      }}
                    >
                      {state.name}
                    </button>
                  ))}
                </div>
              )}
            </div>
            <div className="d-flex-column justify-content-between align-items-center">
              <div className="crop-edit-selector-text d-flex-column align-items-center">
                Region
              </div>
              {currentState.id === "" && (
                <span className="span-text">Please select a state</span>
              )}
              {regionLoading && currentState.id !== "" && (
                <div className="d-flex justify-content-start align-items-center">
                  <CircularProgress size="3rem" />
                </div>
              )}
              {!regionLoading && (
                <div className="region-grid">
                  {regions.map(option => (
                    <button
                      key={option.id}
                      id={option.id}
                      className={`${
                        currentRegion.name === option.name ? "is-selected" : ""
                      } crop-region-country`}
                      onClick={e => {
                        setCurrentRegion({
                          name: e.target.innerHTML,
                          id: e.target.id
                        });
                        setFieldValue("region_name", e.target.innerHTML);
                        setFieldValue("region_id", e.target.id);
                      }}
                    >
                      {option.name}
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="mt-10 d-flex justify-content-end align-items-center text-right">
            <ListButton
              label="Back"
              size="large"
              text="#6BC200"
              background="transparent"
              textTransform="capitalize"
              fontWeight="400"
              onClick={handleBack}
              className="py-4 mb-2 crop-edit-button mr-5"
            />
            <ListButton
              label="Save"
              size="large"
              text="white"
              background="#6BC200"
              textTransform="capitalize"
              onClick={handleSubmit}
              className="py-4 mb-2 crop-edit-button"
              fontWeight="400"
            />
          </div>
        </>
      )}
    </div>
  );
};
