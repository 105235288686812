export const HEADINGS = [
  ["status", "Status"],
  ["code", "Submission ID"],
  ["created_at", "Submission Date"],
  ["contact_name", "Contact"],
  ["message", "Messages"],
];

export const searchMap = {
  "Submission ID": "code",
  "Submission Date": "created_at",
  Contact: "contact_name",
  Status: "status",
};
