import React, { useState, useEffect } from "react";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import SearchBar from "@bit/the-glue.frontendcomponents.search-bar";
import { useSelector, useDispatch } from "react-redux";
import { handleSearch } from "../../../../../ui/helpers";
import { HEADINGS, searchMap } from "../constants";
import { useFetch } from "../../../../../hooks/fetch.hook";
import { ListHeader } from "../../../../../ui/structures/ListHeader";
import { TableSortLabel } from "@material-ui/core";
import { sortHeaderStyle } from "../../../../constants";
import { getSubscribers } from "../_api";
import { setSubscribersList } from "../_redux/actions";
import { filterSearchValue, isSortable, renderSubscribers } from "../helpers";

export const List = () => {
  const dispatch = useDispatch();
  const { request } = useFetch();

  const [loading, setLoading] = useState(true);
  const [focused, setFocused] = useState(false);
  const [initialData, setInitialData] = useState([]);
  const [search, setSearch] = useState("");
  const [field, setField] = useState("");
  const [direction, setDirection] = useState("");

  const data = useSelector(
    ({ subscribers: { subscribersList } }) => subscribersList
  );

  const loadSubscribers = () => {
    setLoading(true);
    request(
      getSubscribers,
      filterSearchValue(search),
      field,
      field && direction
    )
      .then(data => {
        if (data) {
          dispatch(setSubscribersList(data));
          setInitialData(data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadSubscribers();
    // eslint-disable-next-line
  }, [search, field, direction]);

  const KEY_MAP = {
    Name: data.map(item => item.name),
    Phone: data.map(item => item.phone),
    Email: data.map(item => item.email),
    Status: data.map(item => item.status)
  };

  const handleFocus = () => setFocused(true);

  const resetSearch = () => {
    dispatch(setSubscribersList(initialData));
    setSearch("");
  };

  const renderHeaderWithSorting = headings => {
    return headings.map(([key, header]) => {
      if (isSortable(key)) {
        return (
          <th
            key={key}
            style={sortHeaderStyle}
            className="px-5 text-center"
            onClick={() => {
              setDirection(
                key !== field ? "desc" : direction === "desc" ? "asc" : "desc"
              );
              setField(key);
            }}
          >
            <span style={{ cursor: "pointer" }}>{header}</span>
            {key === field && <TableSortLabel active direction={direction} />}
          </th>
        );
      } else
        return (
          <th key={key} style={sortHeaderStyle} className="px-5 text-center">
            <span>{header}</span>
          </th>
        );
    });
  };

  return (
    <>
      <div className="row justify-content-center mt-10">
        <div className="col-12">
          <div
            className="bg-white rounded py-7 px-10"
            style={{ border: "1px solid #E5E5E5", borderRadius: "4px" }}
          >
            <ListHeader title="Subscriptions" handleFocus={handleFocus} />
            <div>
              <SearchBar
                onSearch={data => handleSearch(data, searchMap, setSearch)}
                clearSearch={resetSearch}
                keyMap={KEY_MAP}
                placeholder="Filter Subscriptions..."
                elevation={2}
                chipBackgroundColor="rgba(107, 194, 0, 0.3)"
                chipColor="#6BC200"
                focused={focused}
                setFocused={setFocused}
              />
            </div>
            <Datagrid
              data={data}
              headings={HEADINGS}
              renderRow={renderSubscribers}
              renderHeaderWithSorting={renderHeaderWithSorting}
              editable
              loading={loading}
              link="subscriptions"
            />
          </div>
        </div>
      </div>
    </>
  );
};
