import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";
import { MappedInput } from "../../../../../ui/components/Input";
import { MappedSelect } from "@bit/the-glue.frontendcomponents.select";
import { getSubCategory } from "../_redux/selectors";
import { useFetch } from "../../../../../hooks/fetch.hook";
import { CategoryDetailsSchema } from "../../../../../helpers/schemas";
import { ListButton } from "../../../../../ui/components/ListButton";
import { Loader } from "../../../../../ui/components/Loader";
import {
  addSubCategory,
  getSubCategoryById,
  getCategoryList,
  modifySubCategory
} from "../_api";
import { modifyCategoryList } from "../helpers";
import { info } from "../../../../../helpers/toasts";

export const Card = ({
  match: {
    params: { id }
  }
}) => {
  const history = useHistory();
  const { request } = useFetch();
  const data = useSelector(getSubCategory(id));

  const [loading, setLoading] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [cardData, setCardData] = useState({});
  const [categoryList, setCategoryList] = useState([{}]);

  useEffect(() => {
    setListLoading(true);
    if (id && !data) {
      Promise.all([request(getSubCategoryById, id), request(getCategoryList)])
        .then(([subCategory, parentCategories]) => {
          if (!subCategory || !parentCategories) return;
          setCardData(subCategory);
          setCategoryList(parentCategories);
        })
        .finally(() => setListLoading(false));
    } else {
      data && setCardData(data);
      request(getCategoryList)
        .then(parentCategories => {
          if (!parentCategories) return;
          setCategoryList(modifyCategoryList(parentCategories));
        })
        .finally(() => setListLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialValues = {
    name: cardData.name || "",
    category_id: cardData.category_id || ""
  };

  const handleSubmit = values => {
    setLoading(true);
    request(id ? modifySubCategory : addSubCategory, values, id)
      .then(data => {
        if (!data) return;
        history.push("/settings/sub_categories");
        if (id) {
          info("Your changes have been saved!");
        } else {
          info("Your sub category has been created!");
        }
      })
      .finally(() => setLoading(false));
  };

  const handleClose = () => history.goBack();

  if (id && !Object.keys(cardData).length) {
    return <Skeleton variant="rect" width={"100%"} height={532.33} />;
  }

  return (
    <div
      className="bg-white p-10"
      style={{ border: "1px solid #E5E5E5", borderRadius: "4px" }}
    >
      {loading && <Loader isOpen={loading} maxWidth="xs" title="Loading..." />}
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={CategoryDetailsSchema}
      >
        {({ handleSubmit, values }) => (
          <>
            <h3 className="mb-10">
              <strong>{id ? cardData.name : "Add Category"}</strong>
            </h3>
            <div className="row justify-content-between">
              <div className="col-8">
                <MappedInput
                  label="Category Name"
                  name="name"
                  color="#6BC200"
                />
                {listLoading ? (
                  <Skeleton variant="rect" width={467.86} height={54.22} />
                ) : (
                  <MappedSelect
                    label="Parent Category"
                    options={categoryList}
                    name="category_id"
                    value={values.category_id}
                    color="#6BC200"
                  />
                )}
              </div>
            </div>
            <div className="mt-10 text-right">
              <ListButton
                label="Cancel"
                size="large"
                onClick={handleClose}
                boxShadow={false}
                className="mx-2"
              />
              <ListButton
                label="Save"
                size="large"
                onClick={handleSubmit}
                boxShadow={false}
              />
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};
