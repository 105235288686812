import React, { useState } from "react";
import { useFormikContext } from "formik";
import { Slider as MuiSlider } from "@material-ui/core";

export const Slider = ({ value, name, handleSubmit }) => {
  const { setFieldValue, values } = useFormikContext();
  const [sliderValue, setSliderValue] = useState(value);

  return (
    <div className="my-5 w-50">
      <h6>Discount percentage</h6>
      <p className="my-2">{sliderValue}% - off</p>
      <MuiSlider
        id={name}
        value={sliderValue}
        disabled={!values.duration}
        onChange={(_, value) => {
          setSliderValue(value);
          setFieldValue(name, value);
        }}
        onChangeCommitted={() => {
          handleSubmit(values);
        }}
        max={100}
      />
    </div>
  );
};
