import { MappedSelect } from "@bit/the-glue.frontendcomponents.select";
import React from "react";
import { Formik } from "formik";
import { discountOptions, durationInMonths } from "./constants";
import { Slider } from "../Slider";
import { generateDiscountPayload } from "./helpers";
import { ListButton } from "../ListButton";

export const ReportDiscounts = ({ payload, setPayload }) => {
  const initialValues = {
    duration: payload?.discount_duration || "",
    percent_off: payload?.discount_percentage || 0,
    duration_in_months: payload?.discount_duration_in_months || 1
  };

  const handleSubmit = values => {
    setPayload(prev => ({
      ...prev,
      discount: generateDiscountPayload(values)
    }));
  };

  const handleDiscountTerminate = values => {
    values.duration = null;
    values.percent_off = 0;

    setPayload(prev => ({
      ...prev,
      discount: generateDiscountPayload({ duration: null, percent_off: 0 })
    }));
  };

  return (
    <Formik initialValues={initialValues}>
      {({ values }) => (
        <div className="my-5 border p-5">
          <div className="row">
            <div className="w-50 col-12 col-md-6">
              <MappedSelect
                name="duration"
                label="Discount Duration"
                value={values.duration}
                color="#6BC200"
                options={discountOptions}
                onBlur={() => handleSubmit(values)}
              />
            </div>
            {values.duration === "repeating" && (
              <div className="w-50 col-12 col-md-6">
                <MappedSelect
                  name="duration_in_months"
                  label="Number of months"
                  value={values.duration_in_months}
                  color="#6BC200"
                  options={durationInMonths}
                  onBlur={() => handleSubmit(values)}
                />
              </div>
            )}
          </div>
          <Slider
            name="percent_off"
            value={values.percent_off}
            handleSubmit={handleSubmit}
          />
          <ListButton
            label="Terminate Discount"
            size="small"
            disabled={!values.duration || values.duration === ""}
            onClick={() => handleDiscountTerminate(values)}
            boxShadow={false}
            className="mx-2"
          />
        </div>
      )}
    </Formik>
  );
};
