import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";
import { MappedInput } from "../../../../../ui/components/Input";
import { MappedSelect } from "@bit/the-glue.frontendcomponents.select";
import { getTag } from "../_redux/selectors";
import { tagTypeList } from "../constants";
import { useFetch } from "../../../../../hooks/fetch.hook";
import { addTag, getTagById, modifyTag } from "../_api";
import { TagsDetailsSchema } from "../../../../../helpers/schemas";
import { ListButton } from "../../../../../ui/components/ListButton";
import { Loader } from "../../../../../ui/components/Loader";

export const Card = ({
  match: {
    params: { id }
  }
}) => {
  const history = useHistory();
  const { request } = useFetch();
  const data = useSelector(getTag(id));
  const [loading, setLoading] = useState(false);
  const [cardData, setCardData] = useState({});

  const initialValues = {
    name: cardData.name || "",
    type: cardData.type || "",
    category: cardData.category || "",
    description: cardData.description || ""
  };

  useEffect(() => {
    if (id && !data) {
      request(getTagById, id).then(data => data && setCardData(data));
    } else {
      data && setCardData(data);
    }
    // eslint-disable-next-line
  }, []);

  const handleSubmit = values => {
    setLoading(true);
    const payload = {
      ...values,
      name: values.name.charAt(0).toUpperCase() + values.name.slice(1)
    };
    request(id ? modifyTag : addTag, payload, id)
      .then(data => data && history.push("/settings/tags"))
      .finally(() => setLoading(false));
  };

  const handleClose = () => history.goBack();

  if (id && !Object.keys(cardData).length) {
    return <Skeleton variant="rect" width={"100%"} height={532.33} />;
  }

  return (
    <div
      className="bg-white p-10"
      style={{ border: "1px solid #E5E5E5", borderRadius: "4px" }}
    >
      {loading && <Loader isOpen={loading} maxWidth="xs" title="Loading..." />}
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={TagsDetailsSchema}
      >
        {({ handleSubmit, values }) => (
          <>
            <h3 className="mb-10">
              <strong>{id ? cardData.name : "Add Tag"}</strong>
            </h3>
            <div className="row justify-content-between">
              <div className="col-8">
                <MappedInput
                  label="Tag Name"
                  name="name"
                  data-testid="name"
                  color="#6BC200"
                />
                <MappedInput
                  label="Tag Category"
                  name="category"
                  data-testid="category"
                  color="#6BC200"
                />
                <MappedSelect
                  label="Tag Type"
                  options={tagTypeList}
                  name="type"
                  color="#6BC200"
                  data-testid="type"
                />
                <MappedInput
                  label="Tag Description"
                  name="description"
                  multiline
                  color="#6BC200"
                  rows={6}
                  data-testid="description"
                />
              </div>
            </div>
            <div className="mt-10 text-right">
              <ListButton
                label="Cancel"
                size="large"
                onClick={handleClose}
                boxShadow={false}
                className="mx-2"
              />
              <ListButton
                label="Save"
                size="large"
                onClick={handleSubmit}
                boxShadow={false}
              />
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};
