import React from "react";
import { CircularProgress } from "@material-ui/core";

export const MobileStepper = ({ value, label }) => {
  return (
    <div className="d-inline-flex position-relative justify-content-center align-items-center">
      <CircularProgress variant="static" value={value} size="6rem" />
      <div className="position-absolute d-flex justify-content-center align-items-center top-0 bottom-0">
        <p className="m-0" style={{ fontSize: "1.3rem" }}>
          {label}
        </p>
      </div>
    </div>
  );
};
