import React, { useState } from "react";
import { CreditCard as CreditCardIcon } from "@material-ui/icons";
import { ReactComponent as StripeLogo } from "../../static/StripeLogo.svg";
import { error } from "../../../helpers/toasts";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide
} from "@material-ui/core";
import {
  PaymentElement,
  useElements,
  useStripe
} from "@stripe/react-stripe-js";
import config from "../../../config";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const StripeModal = ({ open, handleModalClose, subscriptionPrice }) => {
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const handlePayment = async event => {
    if (!stripe || !elements) {
      error("Service is currently unavailable, please try again later.");
      return;
    }
    setLoading(true);
    await stripe
      .confirmPayment({
        elements,
        confirmParams: {
          return_url: `${config.ApiUrl.Url}/subscribe`
        }
      })
      .then(async response => {
        if (!response || response.error) {
          if (response.error.type !== "validation_error") {
            error(response.error.message);
          }
          return;
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Dialog
        fullWidth
        keepMounted
        open={open}
        maxWidth="md"
        disableBackdropClick
        TransitionComponent={Transition}
        onClose={handleModalClose}
        className="payment-modal"
      >
        <DialogTitle className="payment-modal-title py-0">
          <p className="m-0">DataFresh Subscription</p>
          <StripeLogo width={120} height={50} />
        </DialogTitle>
        <DialogContent className="d-flex-column justify-content-center align-items-center">
          <PaymentElement />
          <div
            className={`text-center mt-5 d-flex ${
              loading ? "justify-content-center" : "justify-content-between"
            } align-items-center`}
          >
            {loading ? (
              <CircularProgress className="my-5" />
            ) : (
              <>
                <button
                  className="py-3 px-5 mb-5 payment-modal-button"
                  onClick={() => {
                    handleModalClose();
                  }}
                >
                  <p className="m-0">Cancel</p>
                </button>
                <button
                  className="py-2 px-5 mb-5 payment-modal-button"
                  onClick={() => {
                    handlePayment();
                  }}
                >
                  <CreditCardIcon className="payment-modal-card-icon mx-2" />
                  <p className="m-0">{`Pay $${subscriptionPrice.toFixed(
                    2
                  )}`}</p>
                </button>
              </>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
