export const HEADINGS = [
  ["first_name", "First Name"],
  ["last_name", "Last Name"],
  ["position_title", "Position"],
  ["phone", "Phone Number"],
  ["mobile", "Mobile"],
  ["email", "Email Address"]
];

export const statusOptions = [
  { value: "active", label: "Active" },
  { value: "away", label: "Away" }
];

export const methodOptions = [
  { value: "email", label: "Email" },
  { value: "mobile", label: "Mobile" },
  { value: "default", label: "System managed" }
];

export const dayOfWeeks = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday"
];
