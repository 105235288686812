import React from "react";
import { Chip, makeStyles, Paper } from "@material-ui/core";
import { ReactComponent as AddIcon } from "../../static/AddIcon.svg";

const useStyles = makeStyles({
  root: {
    background: "#fff",
    borderBottom: "1px solid #6BC200",
    marginBottom: "15px",
    minHeight: "120.8px",
    paddingLeft: "10px",
  },
  colorPrimary: {
    color: "#6BC200",
  },
  deleteIcon: {
    color: "rgba(0, 0, 0, 0.34)",
  },
});

export const TagsField = ({
  label,
  name = "tags",
  tags = [],
  predefinedTags = [],
  handleAdd,
  setValue,
}) => {
  const classes = useStyles();

  const handleTagRemove = (tagToRemove) => {
    setValue(
      name,
      tags.filter((tagName) => tagName !== tagToRemove)
    );
  };

  return (
    <Paper className={classes.root} square elevation={0}>
      {label && (
        <div
          className="ml-4 pt-3"
          style={{ color: "#6BC200", fontSize: "1.2rem", fontWeight: "600" }}
        >
          {label}
        </div>
      )}
      <div className="d-flex flex-wrap">
        {tags.map((tag, index) => {
          const tagObject =
            predefinedTags.filter(({ id }) => id === tag)[0] || {};
          return (
            <div key={index} className="m-3">
              <Chip
                label={
                  tagObject.category
                    ? tagObject.name
                    : tagObject.quantity + " " + tagObject.measure
                }
                color="primary"
                onDelete={() => handleTagRemove(tag)}
                style={{
                  background: "rgba(107, 194, 0, 0.3)",
                  fontSize: "1.1rem",
                }}
                classes={{
                  colorPrimary: classes.colorPrimary,
                  deleteIcon: classes.deleteIcon,
                }}
              />
            </div>
          );
        })}
        <div className="cursor-pointer">
          <AddIcon
            onClick={handleAdd}
            style={{ color: "#6BC200" }}
            data-test={name}
          />
        </div>
      </div>
    </Paper>
  );
};
