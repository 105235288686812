import React from "react";
import { WhiteLogo } from "../helpers/constants";

export const Headers = ({
  title = "Prices",
  label = "Price Submission",
  color = "#565252"
}) => {
  return (
    <>
      <div
        className="d-flex justify-content-center justify-content-md-between align-items-center w-full submission-header-box px-12 py-10"
        style={{ backgroundColor: color }}
      >
        <div
          style={{
            backgroundImage: WhiteLogo,
            height: "55px",
            width: "250px",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "50% 50%",
            padding: "20px 0"
          }}
        ></div>
        <div className="submission-header-title d-none d-md-block">{title}</div>
      </div>
    </>
  );
};
