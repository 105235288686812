import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useFetch } from "../../../../../hooks/fetch.hook";
import { Loader } from "../../../../../ui/components/Loader";
import { ListButton } from "../../../../../ui/components/ListButton";
import { ReportSettings } from "../../../../../ui/components/ReportSettings";
import { modifySubscriber } from "../_api";
import { Skeleton } from "@material-ui/lab";
import { success, warning } from "../../../../../helpers/toasts";
import { Modal } from "../../../../../ui/components/Modal";
import { ConfirmAction } from "../../../../../ui/components/ConfirmAction";
import {
  getStatusColor,
  isActiveStatus,
  isValidStatus,
  validateSettings
} from "../helpers";
import { ReportDiscounts } from "../../../../../ui/components/ReportDiscounts";

export const SubscriberSettings = ({ cardData, id }) => {
  const { request } = useFetch();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [reportSettings, setReportSettings] = useState(cardData);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmAction, setConfirmAction] = useState("");

  const handleSubmit = () => {
    const error = validateSettings(reportSettings, cardData.status);
    if (error) {
      warning(error);
    } else {
      setLoading(true);
      request(modifySubscriber, reportSettings, id)
        .then(data => data && history.push("/subscriptions/subscriptions-list"))
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const changeSubscriptionStatus = status => {
    setLoading(true);
    request(modifySubscriber, { status: status }, id)
      .then(response => {
        if (!response) {
          warning("Oops something has gone wrong! Please try again later.");
          return;
        }
        success(
          status === "canceled"
            ? `Subscription for ${cardData.name} has been canceled, subscription will end on ${response.status}`
            : `Subscription for ${cardData.name} has been changed, subscription is: ${response.status}`
        );
      })
      .finally(() => {
        history.push("/subscriptions/subscriptions-list");
        setLoading(false);
      });
  };

  const handleClose = () => history.goBack();

  const handleStatusConfirm = () => {
    if (confirmAction === "cancel") {
      changeSubscriptionStatus("canceled");
    } else if (confirmAction === "pause") {
      changeSubscriptionStatus("paused");
    } else if (confirmAction === "resume") {
      changeSubscriptionStatus("resumed");
    }
  };

  if (id && !cardData) {
    return <Skeleton variant="rect" width={"100%"} height={480.14} />;
  }

  return (
    <div
      className="bg-white p-10"
      style={{
        border: "1px solid #E5E5E5",
        borderRadius: "4px",
        minWidth: "535px"
      }}
    >
      {confirmOpen && (
        <Modal
          isOpen={confirmOpen}
          submitable
          onClose={() => setConfirmOpen(false)}
          maxWidth="sm"
          modalContent={
            <ConfirmAction
              handleClose={() => {
                setConfirmOpen(false);
                setConfirmAction("");
              }}
              handleSubmit={() => handleStatusConfirm()}
              warningMessage={`Please confirm that you want to ${confirmAction} this subscription.`}
            />
          }
        />
      )}
      {loading && <Loader isOpen={loading} maxWidth="xs" title="Loading..." />}
      <section>
        <h6 className="ml-5 mb-5 mt-10">Plan Status</h6>
        <div className="border p-5">
          <div className="">
            <p className="m-0 my-1">Subscriber: {cardData.name}</p>
            <p className="m-0 my-1">Stripe ID: {cardData.stripe_sub_id}</p>
            <p className="mb-5">
              Current subscription status:{" "}
              <span className={getStatusColor(cardData.status)}>
                {isValidStatus(cardData.status)
                  ? cardData.status.toUpperCase()
                  : "Active until: " + cardData.status.toUpperCase()}
              </span>
            </p>
          </div>
          <div className="d-flex justify-content-start align-items-center">
            <ListButton
              label="Resume"
              size="small"
              onClick={() => {
                setConfirmOpen(true);
                setConfirmAction("resume");
              }}
              disabled={cardData.status !== "paused"}
              boxShadow={false}
              className="mx-2"
            />
            <ListButton
              label="Pause"
              size="small"
              onClick={() => {
                setConfirmOpen(true);
                setConfirmAction("pause");
              }}
              disabled={cardData.status !== "active"}
              boxShadow={false}
              className="mx-2"
            />
            <ListButton
              label="Cancel"
              size="small"
              onClick={() => {
                setConfirmOpen(true);
                setConfirmAction("cancel");
              }}
              disabled={!isActiveStatus(cardData.status)}
              boxShadow={false}
              className="mx-2"
            />
          </div>
        </div>
      </section>
      <section className="subscriber-settings">
        <h6 className="ml-5 mb-5 mt-10">Report Settings</h6>
        <div className="border p-5">
          <ReportSettings
            payload={reportSettings}
            setPayload={setReportSettings}
            wrapperClasses="col-12"
          />
        </div>
      </section>
      <section>
        <h6 className="ml-5 mb-5 mt-10">Plan Discounts</h6>
        <ReportDiscounts
          payload={reportSettings}
          setPayload={setReportSettings}
        />
      </section>
      <div className={`mt-10 d-flex justify-content-end align-items-center`}>
        <div>
          <ListButton
            label="Back"
            size="large"
            onClick={handleClose}
            boxShadow={false}
            className="mx-2"
          />
          <ListButton
            label="Save"
            size="large"
            onClick={handleSubmit}
            boxShadow={false}
          />
        </div>
      </div>
    </div>
  );
};
